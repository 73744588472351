import { atom } from 'recoil';
import * as Api from '../ApiV2';

type ReplaceParamWeb = Api.ReplaceParamWeb & {
  onlyReplaceAll?: boolean;
  isReplaceFromSearch?: boolean;
  cacheKey?: string | null;
};
interface IReplaceState {
  replacementParams: ReplaceParamWeb;
  replaceWords: Api.ReplaceObj[];
  RefreshReplaceWord: () => void;
  isOpenCreateEditReplacement: boolean;
  editReplacement: Api.ReplaceObj | null;
  sort: string;
  replaceBoxParams: any;
}
const replaceState = atom<IReplaceState>({
  key: 'replacementState',
  default: {
    replacementParams: {
      fontColor: '#e74c3c',
      extractColor: '#ffffff',
      fontName: '',
      fontSize: 0,
      isReplaceFromSearch: false,
      replaceBy: '',
    } as ReplaceParamWeb,
    replaceBoxParams: {
      replaceBy: '',
      fontName: '',
      fontSize: 0,
      foreColor: '#e74c3c',
      backColor: '#ffffff',
    },
    isOpenCreateEditReplacement: false,
    editReplacement: null,
    sort: '',
    replaceWords: [] as Api.ReplaceObj[],
  } as IReplaceState,
});
export type { IReplaceState, ReplaceParamWeb };
export { replaceState };
