import { atom } from "recoil";
import * as Api from "../ApiV2";
import { CustomSearchTemplate } from "./searchState";
import { SaveFileModalProps } from "../modal/SaveFileModal";
interface IModalContainerState {
  changeLineColor: boolean;
  showEditHeaderToc: boolean;
  EditTocHeaderParam: Api.EditTocHeaderParam;
  replaceBoxModalState: any;
  isShowFrontPageModal: boolean;
  displaySettings: boolean;
  showxtractSettings: boolean;
  showTocSettings: boolean;
  findDuplicates: boolean;
  previewFindDuplicates: boolean;
  displayPageNumbering: boolean;
  displayStamp: "edit" | "add" | "";
  displayStampIndex: boolean;
  openAddCustomTemplate: boolean;
  editCustomTemplate: CustomSearchTemplate | null;
  saveModal: SaveFileModalProps | null;
  showSplitDocument: boolean;
  showApprovalStampModal: boolean;
  CheckDuplicateResponse?: Api.CheckDuplicateResponse;
}

const modalContainerState = atom<IModalContainerState>({
  key: "modalContainerState",
  default: {
    changeLineColor: false,
    showEditHeaderToc: false,
    previewFindDuplicates: false,
    EditTocHeaderParam: {},
    replaceBoxModalState: {
      openReplaceBoxModal: "",
      replaceBoxAnnotationId: "",
      boxId: "",
    },
    isShowFrontPageModal: false,
    displaySettings: false,
    showTocSettings: false,
    showxtractSettings: false,
    displayPageNumbering: false,
    findDuplicates: false,
    displayStamp: "",
    displayStampIndex: false,
    openAddCustomTemplate: false,
    editCustomTemplate: null,
    saveModal: null,
    showSplitDocument: false,
    showApprovalStampModal: false,
  },
});
export { modalContainerState };
