import { atom } from 'recoil';
import * as ApiV2 from '../ApiV2';

interface ITabState {
  TabFocus: ApiV2.TabDetail | null;
  selectedTab: ApiV2.TabDetail | null;
  showContextualMenu: boolean;
  showRenameModal: boolean;
}

const tabState = atom({
  key: 'tabState',
  default: { TabFocus: null, selectedTab: null, showContextualMenu: false, showRenameModal: false } as ITabState,
});

export { tabState };
export type { ITabState };
