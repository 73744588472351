import * as React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useLang } from "../../i18n/useLang";
import { Icon } from "@fluentui/react";
import { DialogContent } from "../../assets/style/modal/dialog";

interface Props {
  title: string;
  subText?: string;
  okText?: string;
  cancelText?: string;
  onClose: (Yes: boolean, no?: boolean, cancel?: boolean) => void;
}
export default (props: Props) => {
  const { tr } = useLang();
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: "Close",
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 600 } },
      }}
      onDismiss={() => props.onClose(false, false, true)}
    >
      <DialogContent>
        <div>
          <Icon iconName="StatusCircleQuestionMark" />
        </div>
        <div className={"content"}>{props.subText}</div>
      </DialogContent>
      <DialogFooter>
        <PrimaryButton
          autoFocus={true}
          text={props.okText || tr("ok")}
          onClick={() => props.onClose(true)}
        />
        <DefaultButton
          text={props.cancelText || tr("cancel")}
          onClick={() => props.onClose(false, false, true)}
        />
      </DialogFooter>
    </Dialog>
  );
};
export const YesNoCancel = (props: Props) => {
  const { tr } = useLang();
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: "Close",
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 600 } },
      }}
      onDismiss={() => props.onClose(false, true)}
    >
      <DialogContent>
        <div>
          <Icon iconName="StatusCircleQuestionMark" />
        </div>
        <div className={"content"}>{props.subText}</div>
      </DialogContent>
      <DialogFooter>
        <PrimaryButton
          autoFocus={true}
          text={tr("yes")}
          onClick={() => props.onClose(true)}
        />
        <PrimaryButton
          autoFocus={true}
          text={tr("no")}
          onClick={() => props.onClose(false, true, false)}
        />
        <DefaultButton
          text={tr("cancel")}
          onClick={() => props.onClose(false, false, true)}
        />
      </DialogFooter>
    </Dialog>
  );
};
interface ImportConfirmProps {
  title: string;
  subText?: string;
  onClose: () => void;
  onReplicate: () => void;
  onReplace: () => void;
  onIgnore: () => void;
}
export const ImportConfirm = (props: ImportConfirmProps) => {
  const { tr } = useLang();
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: "Close",
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 600 } },
      }}
      onDismiss={() => props.onClose()}
    >
      <DialogContent>
        <div>
          <Icon iconName="StatusCircleQuestionMark" />
        </div>
        <div
          className={"content"}
          dangerouslySetInnerHTML={{ __html: props.subText || "" }}
        />
      </DialogContent>
      <DialogFooter>
        <PrimaryButton
          autoFocus={true}
          text={tr("ignore")}
          onClick={() => props.onIgnore()}
        />
        <PrimaryButton
          autoFocus={true}
          text={tr("replicate")}
          onClick={() => props.onReplicate()}
        />
        <PrimaryButton
          autoFocus={true}
          text={tr("replace")}
          onClick={() => props.onReplace()}
        />
        <DefaultButton text={tr("cancel")} onClick={() => props.onClose()} />
      </DialogFooter>
    </Dialog>
  );
};
