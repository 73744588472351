import styled from "styled-components";
export const StyledContainer = styled.div`
overflow:hidden;
height: 100%;
.note-content {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 12px;
  position: relative;
  span{
    align-items: center;
    flex-direction: row;
    width: 100%;
    min-height: 33px;
    display: flex;
    text-align: left;
  }
}
.ms-DetailsRow-fields{
  align-items: center;
  flex-direction: row;
}
.ms-List .ms-DetailsRow-cell div{
  align-items: center;
  display: flex;
}
}`;
