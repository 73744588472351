import * as React from "react";
import { useLang } from "../i18n/useLang";
import {
  Modal,
  DefaultButton,
  Stack,
  IStackTokens,
  TextField,
  IStackItemStyles,
  Dropdown,
  Checkbox,
  IColor,
  PrimaryButton,
  IDropdownOption,
} from "@fluentui/react";
import ModalHeader from "../control/modalHeader";
import * as Api from "../ApiV2";
import { ModalContent } from "../assets/style/modal/modal";
import { ColorPicker } from "../control/ColorPicker";
import { dialogState, modalContainerState, tagState } from "../recoil";
import { useRecoilState } from "recoil";
import { tagService } from "../service";
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/dreamweaver.css";
import "ace-builds/css/theme/ambiance.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";
import ReportDesigner, {
  RequestOptions,
  DxReportDesignerRef,
  Callbacks,
} from "devexpress-reporting-react/dx-report-designer";
import { fetchSetup } from "@devexpress/analytics-core/analytics-utils";
const stackTokens: IStackTokens = { childrenGap: 10 };
interface DesignReportModalProps {
  isShow: boolean;
  templateId: string;
  onClose: (xml: string) => void;
}
const ApprovalStampTemplateApi = new Api.ApprovalStampTemplateApi();
export const DesignReportModal = (props: DesignReportModalProps) => {
  const { tr } = useLang();
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const designerRef = React.useRef<DxReportDesignerRef>(null);
  fetchSetup.fetchSettings = {
    headers: { IdentifiedKey: localStorage.IdentifiedKey?.toString() },
  };
  function onBeforeRender(args: any): void {
    // You can adjust Report Designer settings here.
  }

  const onCloseModal = () => {
    const iframe = document.getElementById("designReport");
    (iframe as any).contentDocument?.getElementById("closeReport")?.click();
    if ((iframe as any).contentDocument["IsModified"]) {
      setDialogState({
        ...dialog,
        MsgDialog: tr("doYouWantToSaveChanges"),
        CallBackConfirmDialog: {
          action: () => {
            onSave();
            setTimeout(() => {
              closeReport();
            }, 300);
          },
          param: null,
          actionNo: () => {
            closeReport();
          },
        },
        Type: "YesNoCancel",
      });
    } else {
      closeReport();
    }
  };
  const closeReport = () => {
    ApprovalStampTemplateApi.apiLegalxtractCloseDesignApprovalStampTemplatePost(
      props.templateId
    ).then((res) => {
      props.onClose(res.data);
    });
  };
  const onSave = () => {
    const iframe = document.getElementById("designReport");
    (iframe as any).contentDocument?.getElementById("saveReport")?.click();
  };
  return (
    <Modal isOpen={props.isShow} onDismiss={onCloseModal} isBlocking={true}>
      <ModalContent
        style={{
          height: window.innerHeight - 80,
          width: window.innerWidth - 80,
        }}
      >
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-md12">
            <ModalHeader title={tr("Design")} onCloseModal={onCloseModal} />
          </div>
        </div>
        <div
          className="ms-Grid-row"
          style={{ height: "inherit", maxHeight: "calc(100% - 70px)" }}
        >
          <div className="ms-Grid-col ms-md12" style={{ height: "100%" }}>
            <iframe
              id="designReport"
              src={`/designreport?templateId=${props.templateId}`}
              height={"100%"}
              width={"100%"}
            ></iframe>
          </div>
        </div>
        <Stack
          style={{ marginTop: 8 }}
          horizontal
          horizontalAlign="end"
          tokens={stackTokens}
        >
          <DefaultButton
            text={tr("close")}
            onClick={onCloseModal}
            allowDisabledFocus
          />
          <PrimaryButton
            text={tr("save")}
            onClick={onSave}
            allowDisabledFocus
          />
        </Stack>
      </ModalContent>
    </Modal>
  );
};
