import { atom } from "recoil";
import * as ApiV2 from "../ApiV2";
import {
  CompanyCode,
  Constants,
  DataCache,
  LocalStorageKey,
} from "../config/constant";
import { userService } from "../service/userService";
interface LxSettingClient extends ApiV2.LxSetting {
  clientSettingVersion: number;
}
class DocuVieware {
  DocuviewareInit: ApiV2.DocuviewareInit | null = null;
  openPreview?: boolean;
  docuViewarePreviewID?: string | undefined | null;
  previewSessionID?: string | undefined | null;
  previewHtmlString?: string | undefined | null;
  isRefeshDocument?: boolean;
}
export const lxSettingDefault = {
  replaceFontName: "Arial",
  option_Color_selection: "yellow",
  highlightPublishColor: "black",
  extractCombineTab: true,
  replaceFontSize: 10,
  extractMaxFileSize: 0,
  autoHighlightMask: true,
  archiveFileFormat: 1,
  ocrWhenOpeningDocuments: false,
  compressExtractFiles: false,
  extractPdfFileType: ApiV2.PdfFileType.NUMBER_2,
  warningKeyword: false,
  warningTemplate: false,
  warningTag: false,
  tocIndexPattern: "1",
  drawIndexInTocPages: true,
  dictionaryName:
    ApiV2.Country.NUMBER_1 |
    ApiV2.Country.NUMBER_2 |
    ApiV2.Country.NUMBER_4 |
    ApiV2.Country.NUMBER_8 |
    ApiV2.Country.NUMBER_32768,
  namesWillNotBeginOrEndWith: `la, von, van, le, du, dos, di, á, van't, zum, við, del, ter, te, í, ten, el, los, das, des, a, av, d', han, en, de, kilde, den, det, dem, der, fam, vi, af, om, et, da, ip, no, ja, ny, id no, kan, er, man, in, the, til, dear, legal, ek, dir, nem, id, jeg, se, tak, raad, voor, about, us`,
  dontSendOCRMailPageCount: 100,
  hiddenPageImage: Constants.hiddenImg,
  hiddenPageTitle: "Content blocked",
  clientSettingVersion: 0,
} as LxSettingClient;
export type { DocuVieware };
const docuviewareMainState = atom<DocuVieware>({
  key: "docuviewareMain",
  default: {
    DocuviewareInit: null,
    openPreview: false,
    docuViewarePreviewID: null,
    previewSessionID: null,
    previewHtmlString: null,
    isRefeshDocument: false,
  } as DocuVieware,
});
const GetLxSetting = () => {
  const { getUser } = userService();
  const user = getUser();
  const lx = localStorage.getItem(LocalStorageKey.LxSetting);
  if (lx) {
    const obj = JSON.parse(lx) as LxSettingClient;
    if (!obj.dictionaryName) {
      obj.dictionaryName = (ApiV2.Country.NUMBER_1 |
        ApiV2.Country.NUMBER_2 |
        ApiV2.Country.NUMBER_4 |
        ApiV2.Country.NUMBER_8 |
        ApiV2.Country.NUMBER_32768) as ApiV2.Country;
    }
    if (
      obj.dontSendOCRMailPageCount == undefined ||
      obj.dontSendOCRMailPageCount == null
    ) {
      obj.dontSendOCRMailPageCount = 100;
    }
    obj.hiddenPageImage = obj.hiddenPageImage || null;
    obj.hiddenPageTitle = obj.hiddenPageTitle || "Content blocked";
    if (obj.clientSettingVersion !== 1) {
      obj.clientSettingVersion = 1;
      if (
        user?.userDataLogin?.result?.user_info?.email &&
        user?.userDataLogin?.result?.user_info?.email?.toLowerCase().includes("@kk.dk")
      ) {
        obj.namesWillNotBeginOrEndWith = `la, von, van, le, du, dos, di, á, van't, zum, við, del, ter, te, í, ten, el, los, das, des, a, av, d', han, en, de, kilde, den, det, dem, der, fam, vi, af, om, et, da, ip, no, ja, ny, id no, kan, er, man, in, the, til, dear, legal, ek, dir, nem, id, jeg, se, tak, raad, voor, about, us, Hej, Partner, Vejle, Som, Hun, Side, Demo, Wed, Ved, EAN, Med, Dette, Intet, Lang, Har, Vaske, Hele, Andre, Andres, Lave`;
      }
    }
    return obj;
  }
  if (
    user &&
    user?.userDataLogin?.result?.user_info?.email?.toLowerCase()?.includes("@kk.dk")
  ) {
    lxSettingDefault.namesWillNotBeginOrEndWith = `la, von, van, le, du, dos, di, á, van't, zum, við, del, ter, te, í, ten, el, los, das, des, a, av, d', han, en, de, kilde, den, det, dem, der, fam, vi, af, om, et, da, ip, no, ja, ny, id no, kan, er, man, in, the, til, dear, legal, ek, dir, nem, id, jeg, se, tak, raad, voor, about, us, Hej, Partner, Vejle, Som, Hun, Side, Demo, Wed, Ved, EAN, Med, Dette, Intet, Lang, Har, Vaske, Hele, Andre, Andres, Lave`;
    lxSettingDefault.clientSettingVersion = 1;
  }
  return lxSettingDefault;
};
const docuviewarePreviewState = atom<DocuVieware>({
  key: "docuviewarePreview",
  default: {} as DocuVieware,
});
const currentPagePreviewState = atom<number>({
  key: "currentPagePreview",
  default: 1,
});
const zoomModeState = atom<ApiV2.ViewerZoomMode | null>({
  key: "zoomMode",
  default: 3 as ApiV2.ViewerZoomMode | null,
});

export {
  docuviewareMainState,
  docuviewarePreviewState,
  currentPagePreviewState,
  zoomModeState,
  GetLxSetting,
};
