import { saveAs } from "file-saver";
import printJS from "print-js";
import * as Api from "../ApiV2";
import { DocuVieware } from "../recoil/docuviewareState";
import { generateSavingFileName } from "../utils/helpers";
import { fileSave } from "browser-fs-access";
import { LocalStorageKey } from "../config/constant";
const SavingApi = new Api.SavingApi();
const SaveToThirdPartyApi = new Api.SaveToThirdPartyApi();
const SaveNovaApi = new Api.SaveNovaApi();

const CommentApi = new Api.CommentApi();
export const saveService = {
  SaveXtract: async (fileName: string, docuviewareCtx: DocuVieware) => {
    if (!docuviewareCtx?.DocuviewareInit?.htmlString) return;
    localStorage.removeItem(LocalStorageKey.DocumentHasChanged);
    const response = (
      await SavingApi.apiLegalxtractSaveExtractFileGet(fileName, {
        responseType: "blob",
      })
    ).data;
    if (response) {
      try {
        await fileSave(response, {
          fileName: `${fileName}.xtract`,
          extensions: [".xtract"],
        });
      } catch (error) {
        console.log(error);
      }
    }
  },
  RedactPDF: async (
    param: Api.ExtractAndSplitParam,
    docuviewareCtx: DocuVieware
  ) => {
    if (!docuviewareCtx?.DocuviewareInit?.htmlString) return null;
    const response = (
      await SavingApi.apiLegalxtractExtractFilePost(param, {
        responseType: "blob",
      })
    ).data;
    let fileType = ".pdf";
    if (response.type != "application/pdf") {
      fileType = `.zip`;
    }
    try {
      await fileSave(response, {
        fileName: param.fileName || "",
        extensions: [fileType],
      });
    } catch (error) {
      console.log(error);
    }
  },
  savePdf: async (docuviewareCtx: DocuVieware) => {
    if (
      !docuviewareCtx ||
      (docuviewareCtx?.DocuviewareInit?.tabDetails || []).length === 0 ||
      !docuviewareCtx?.DocuviewareInit?.htmlString
    )
      return;
    const fileName = generateSavingFileName(docuviewareCtx, 2);
    const response = (
      await SavingApi.apiLegalxtractSaveAsPdfGet(
        fileName,
        Api.PdfFileType.NUMBER_1,
        {
          responseType: "blob",
        }
      )
    ).data;
    try {
      await fileSave(response, {
        fileName: fileName,
        extensions: [".pdf"],
      });
    } catch (error) {
      console.log(error);
    }
  },
  saveZipFileNew: async (fileName: string, docuviewareCtx: DocuVieware) => {
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      const response = (
        await SavingApi.apiLegalxtractSaveAsZipGet(fileName, {
          responseType: "blob",
        })
      ).data;
      if (response) {
        try {
          await fileSave(response, {
            fileName: `${fileName}`,
            extensions: [".zip"],
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
  combinePdfNew: async (fileName: string, docuviewareCtx: DocuVieware) => {
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      const response = (
        await SavingApi.apiLegalxtractCombinePdfPost(undefined, {
          responseType: "blob",
        })
      ).data;
      try {
        await fileSave(response, {
          fileName: `${fileName}`,
          extensions: [".pdf"],
        });
      } catch (error) {
        console.log(error);
      }
    }
  },

  onPrintExtract: async (docuviewareCtx: DocuVieware) => {
    if (
      !docuviewareCtx ||
      (docuviewareCtx?.DocuviewareInit?.tabDetails || []).length === 0 ||
      !docuviewareCtx?.DocuviewareInit?.htmlString
    )
      return;
    const fileName = generateSavingFileName(docuviewareCtx);
    const response = (
      await SavingApi.apiLegalxtractExtractFilePrintGet(
        fileName,
        Api.PdfFileType.NUMBER_1,
        {
          responseType: "blob",
        }
      )
    ).data;
    const objectURL = URL.createObjectURL(response);
    printJS({ printable: objectURL, showModal: true, type: "pdf" });
  },

  onPrintPdf: async (docuviewareCtx: DocuVieware) => {
    if (
      !docuviewareCtx ||
      (docuviewareCtx?.DocuviewareInit?.tabDetails || []).length === 0 ||
      !docuviewareCtx?.DocuviewareInit?.htmlString
    )
      return;
    const fileName = generateSavingFileName(docuviewareCtx);
    const response = (
      await SavingApi.apiLegalxtractSaveAsPdfGet(
        fileName,
        Api.PdfFileType.NUMBER_1,
        {
          responseType: "blob",
        }
      )
    ).data;
    const objectURL = URL.createObjectURL(response);
    printJS(objectURL, "pdf");
  },
  onPrintComment: async (
    docuviewareCtx: DocuVieware,
    orderBy: Api.CommentOrderByEnum
  ) => {
    if (
      !docuviewareCtx ||
      (docuviewareCtx?.DocuviewareInit?.tabDetails || []).length === 0 ||
      !docuviewareCtx?.DocuviewareInit?.htmlString
    )
      return;
    const fileName = "Comment";
    const response = (
      await CommentApi.apiLegalxtractPrintCommentGet(fileName, orderBy, {
        responseType: "blob",
      })
    ).data;
    const objectURL = URL.createObjectURL(response);
    printJS(objectURL, "pdf");
  },
  saveRedactedNova: async (
    fileName: string,
    docuviewareCtx: DocuVieware
  ): Promise<boolean> => {
    let response = false;
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      response =
        (
          await SaveNovaApi.apiLegalxtractSaveRedactedNovaPost(
            fileName,
            Api.PdfFileType.NUMBER_2
          )
        ).data.data || false;
    }
    return response;
  },
  saveXtractNova: async (
    fileName: string,
    docuviewareCtx: DocuVieware
  ): Promise<boolean> => {
    let response = false;
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      localStorage.removeItem(LocalStorageKey.DocumentHasChanged);
      response =
        (await SaveNovaApi.apiLegalxtractSaveXtractNovaPost(fileName)).data
          .data || false;
    }
    return response;
  },
  saveExtractFileToThirdParty: async (
    fileName: string,
    docuviewareCtx: DocuVieware
  ): Promise<Api.SaveXtractParamOperationResult | undefined> => {
    let response = undefined as Api.SaveXtractParamOperationResult | undefined;
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      response = (
        await SaveToThirdPartyApi.apiLegalxtractSaveExtractFileToLCPost(
          fileName
        )
      ).data;
    }
    return response;
  },
  saveRedactPdfThirdParty: async (
    param: Api.ExtractAndSplitParam,
    docuviewareCtx: DocuVieware
  ): Promise<Api.SaveRedactPdfHookParamOperationResult | undefined> => {
    let response = undefined as
      | Api.SaveRedactPdfHookParamOperationResult
      | undefined;
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      response = (
        await SaveToThirdPartyApi.apiLegalxtractSaveRedactPdfLCPost(param)
      ).data;
    }
    return response;
  },
  onSelectedSaveXtract: async (
    fileName: string,
    docuviewareCtx: DocuVieware
  ) => {
    if (!docuviewareCtx?.DocuviewareInit?.htmlString) return;
    localStorage.removeItem(LocalStorageKey.DocumentHasChanged);
    const response = (
      await SavingApi.apiLegalxtractSaveExtractFileGet(fileName, {
        responseType: "blob",
      })
    ).data;
    if (response) {
      saveAs(response, `${fileName}.xtract`);
    }
  },

  onExtractToPDF: async (
    param: Api.ExtractAndSplitParam,
    docuviewareCtx: DocuVieware
  ) => {
    if (!docuviewareCtx?.DocuviewareInit?.htmlString) return;
    const response = (
      await SavingApi.apiLegalxtractExtractFilePost(param, {
        responseType: "blob",
      })
    ).data;
    if (response.type != "application/pdf") {
      saveAs(response, `${param.fileName}.zip`);
    } else {
      saveAs(response, `${param.fileName}.pdf`);
    }
  },
  savePdfFile: async (fileName: string, docuviewareCtx: DocuVieware) => {
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      const response = (
        await SavingApi.apiLegalxtractSaveAsPdfGet(
          fileName,
          Api.PdfFileType.NUMBER_1,
          {
            responseType: "blob",
          }
        )
      ).data;
      saveAs(response, `${fileName}.pdf`);
    }
  },
  combinePdf: async (fileName: string, docuviewareCtx: DocuVieware) => {
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      const response = (
        await SavingApi.apiLegalxtractCombinePdfPost(undefined, {
          responseType: "blob",
        })
      ).data;
      saveAs(response, `${fileName}.pdf`);
    }
  },
  saveZipFile: async (fileName: string, docuviewareCtx: DocuVieware) => {
    if (docuviewareCtx?.DocuviewareInit?.htmlString) {
      const response = (
        await SavingApi.apiLegalxtractSaveAsZipGet(fileName, {
          responseType: "blob",
        })
      ).data;
      saveAs(response, `${fileName}.zip`);
    }
  },
};
