import * as Api from '../ApiV2';
import { LocalStorageKey } from '../config/constant';
import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
import { UndoHightLight } from './undoHightLight';
const WatchDogSelectALLRowCommand = (
  docuViewareID: string | null | undefined,
  selectAll: boolean,
  param: Api.UnSelectedAllSelectAllRowParam,
  callback: any,
) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  let ParamUndo = '';
  const WatchDogSelectAll = () => {
    const dataRequest = {
      Data: JSON.stringify(param),
      Type: 'WatchDogSelectAllRow',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      ParamUndo = res;
      if (callback) {
        callback();
      }
    });
  };
  const WatchDogDeSelectAll = () => {
    const dataRequest = {
      Data: JSON.stringify(param),
      Type: 'WatchDogDeSelectAllRow',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      ParamUndo = res;
      if (callback) {
        callback();
      }
    });
  };
  if (selectAll) {
    WatchDogSelectAll();
  } else {
    WatchDogDeSelectAll();
  }
  undoManager.add({
    undo: function () {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    },
    redo: function () {
      if (selectAll) {
        WatchDogSelectAll();
      } else {
        WatchDogDeSelectAll();
      }
    },
  });
};
export default WatchDogSelectALLRowCommand;
