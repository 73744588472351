import { atom } from "recoil";
export const dialogState = atom({
  key: "DialogState",
  default: {
    titleDialog: "",
    MsgDialog: "",
    okText: "",
    cancelText: "",
    Type: "" as
      | "AlertDialog"
      | "WarningDialog"
      | "ConfirmationDialog"
      | "YesNoCancel"
      | "",
    CallBackConfirmDialog: {
      action: null as any,
      param: null as any,
      actionNo: null as any,
      paramNo: null as any,
      actionCancel: null as any,
      paramCancel: null as any,
    },
  } as {
    okText?: string | null;
    cancelText?: string | null;
    titleDialog?: string;
    MsgDialog?: string;
    Type:
      | "AlertDialog"
      | "WarningDialog"
      | "ConfirmationDialog"
      | "YesNoCancel"
      | "";
    CallBackConfirmDialog: {
      action: any;
      param?: any;
      actionNo?: any;
      paramNo?: any;
      actionCancel?: any;
      paramCancel?: any;
    } | null;
  },
});
