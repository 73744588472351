import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import { useLang } from "../i18n/useLang";
import { tagState } from "../recoil";
const TagApiV2 = new Api.TagApi();
const tagService = () => {
  const [tag, setTagState] = useRecoilState(tagState);
  const { tr, i18n } = useLang();
  const getTags = async () => {
    const data = (await TagApiV2.apiLegalxtractLoadTagDictionaryForClientGet())
      .data;
      if (data) {
        data.tagCategories?.forEach((item) => {
          item.keyword = i18n.language == "da" ? item.keywordDa || item.keyword : item.keyword;
        });
        data.tagNames?.forEach((item) => {
          item.keyword = i18n.language == "da" ? item.keywordDa || item.keyword   : item.keyword;
        });
        setTagState({ ...tag, tagData: data });
      } else {
        setTagState({ ...tag, tagData: null });
      }
  };
  return { getTags };
};
export { tagService };
