import * as React from "react";
import { useRecoilState } from "recoil";
import * as Api from "../../../ApiV2";
import { StyledTOCContainer } from "../../../assets/style/left-sidebar/searchTab";
import { useLang } from "../../../i18n/useLang";
import { TOCState, docuviewareMainState, trackingState } from "../../../recoil";
import { TOCService } from "../../../service";
import TreeToc from "./tree-Toc";
import { documentService } from "../../../service/document";
import { DataCache } from "../../../config/constant";
const TocApi = new Api.TocApi();
const TOC = () => {
  const { tr } = useLang();
  const { getTOCInfo } = TOCService();
  const [tocCurrentState, setTOCState] = useRecoilState(TOCState);
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  React.useEffect(() => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0) {
      getTOCInfo();
    }
  }, []);
  const GetAllId = (item: string, treeData: any): string[] => {
    let allIds: string[] = [];
    (treeData[item].children as string[]).forEach((element) => {
      allIds.push(element);
      allIds = [...allIds, ...GetAllId(element.toString(), treeData)];
    });
    return allIds;
  };
  const getOrderIDs = (treeData: any) => {
    const arrId  = GetAllId("root", treeData);
    return arrId;
  };
  const reOrderToc = async (
    items: any[],
    parentId: string | null,
    treeData: any
  ) => {
    const arrId: string[] = getOrderIDs(treeData);
    // console.log(arrId);
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });

    TocApi.apiLegalxtractReOrderTOCPost({
      tocIds: items.map((o) => o.index),
      parentId: parentId || "00000000-0000-0000-0000-000000000000",
      newOrderIds: arrId,
    }).then((res) => {
      const data = res.data;
      if (!data) return;
      setTOCState((cur) => {
        return { ...cur, tocData: data.tocRows || [] };
      });
      setDocuviewareMain((cur) => {
        return {
          ...cur,
          DocuviewareInit: data.docuviewareInit || null,
        };
      });
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
    });
  };

  return (
    <>
      <div className="header-left-panel">{tr("toc")}</div>
      <StyledTOCContainer onContextMenu={(e) => {}}>
        {tocCurrentState.tocData.length > 0 && (
          <TreeToc
            datas={tocCurrentState.tocData}
            onItemsChanged={(
              dataDoc: Api.DocuviewareInit,
              tocRow: Api.TocRow
            ) => {
              setTOCState((cur) => {
                const newarrItem = cur.tocData.filter(
                  (o) => o.uniqueId != tocRow.uniqueId
                );
                const curItem = cur.tocData.find(
                  (o) => o.uniqueId == tocRow.uniqueId
                );
                newarrItem.splice(
                  cur.tocData.indexOf(curItem || {}),
                  0,
                  tocRow
                );
                return { ...cur, tocData: newarrItem };
              });
              setDocuviewareMain((current) => {
                return {
                  ...current,
                  DocuviewareInit: dataDoc,
                };
              });
              documentService.RefreshPage(null, DataCache.docuViewareID());
            }}
            onDrop={(items, target, treeData) => {
              let parentid = (target as any).parentItem;
              if (parentid === "root") {
                parentid = "00000000-0000-0000-0000-000000000000";
              }
              if (target.targetType === "item") {
                parentid = (target as any).targetItem;
              }
              reOrderToc(items, parentid, treeData);
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          ></TreeToc>
        )}
      </StyledTOCContainer>
    </>
  );
};

export default TOC;
