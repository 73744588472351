import { atom, selector } from 'recoil';
import * as Api from '../ApiV2';
interface IPageState {
  rectangleFSelected: any | null | undefined;
  currentPage: number ;
  oldCurrentPage: number;
  oldCurrentPageId: string;
  PageObj: Api.Page | null;
}
const pageSelectedState = atom({
  key: 'pageSelectedState',
  default: {
    rectangleFSelected: null,
    currentPage: 0,
    oldCurrentPage: 0,
    oldCurrentPageId: "",
    PageObj: null,
  } as IPageState,
});
export { pageSelectedState };
export type { IPageState };
