import {
  CompanyCode,
  Constants,
  DataCache,
  LocalStorageKey,
} from "../config/constant";
import * as Api from "../ApiV2";
import { GetLxSetting, lxSettingDefault } from "../recoil/docuviewareState";
import { userService } from "../service/userService";
export const AppHelper = {
  ConfigSettings: () => {
    const { getUser } = userService();
    const user = getUser();
    let lxSetting = GetLxSetting();
    if (DataCache.REACT_APP_CompanyCode == CompanyCode.Nova) {
      lxSetting = {
        ...lxSetting,
        dictionaryName: Api.Country.NUMBER_1,
        namesWillNotBeginOrEndWith: "",
      };
    }
    if (user?.userDataLogin?.result?.user_info?.countryCode == "DNK") {
      lxSetting = {
        ...lxSetting,
        dictionaryName: Api.Country.NUMBER_1,
      };
    }
    localStorage.setItem(LocalStorageKey.LxSetting, JSON.stringify(lxSetting));
    return lxSetting;
  },
};
