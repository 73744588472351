import { Checkbox, Stack } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import * as React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { useRecoilState } from 'recoil';
import * as ApiV2 from '../../../ApiV2';
import { StyledCheckboxTreeContainer } from '../../../assets/style/left-sidebar/searchTab';
import { LocalStorageKey } from '../../../config/constant';
import { useLang } from '../../../i18n/useLang';
import { pageSelectedState, searchState, tagState } from '../../../recoil';
import { tagService } from '../../../service';

export default () => {
  const [tag, setTagState] = useRecoilState(tagState);
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const { tr } = useLang();
  const { getTags } = tagService();
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [pageState, setPageState] = useRecoilState(pageSelectedState);
  const getNodes = (): any[] => {
    const treeNodes: { value: string; color: string; label: string; children: any }[] = [];
    if (tag.tagData) {
      tag.tagData.tagCategories?.forEach((i: ApiV2.TagCategory) => {
        const index = tag.tagData?.tagNames?.findIndex((o) => o.tagCategoryId == i.id);
        if (index != undefined && index >= 0) {
          treeNodes.push({
            value: i.id || '',
            label: i.keyword || '',
            color: i.color || '',
            children: tag.tagData?.tagNames
              ?.filter((o) => o.tagCategoryId === i.id)
              .map((child: ApiV2.TagName) => ({
                value: child.id,
                label: child.keyword,
              })),
          });
        }
      });
    }
    return treeNodes;
  };
  const [nodes, setNode] = React.useState<any[]>([]);
  React.useEffect(() => {
    setNode(getNodes());
  }, [tag.tagData]);
  React.useEffect(() => {
    getTags();
  }, []);
  const handleToggleCheckAll = (e: any, checkAll: boolean | undefined) => {
    const keys: string[] = [];
    if (checkAll) {
      nodes.forEach((node) => {
        node.children?.forEach((child: any) => {
          keys.push(child.value);
        });
      });
    }
    setSearchState({
      ...searchCurrentState,
      searchTagParams: {
        ...searchCurrentState.searchTagParams,
        tagChecked: keys,
        checkAll: checkAll,
      },
    });
  };

  React.useEffect(() => {
    localStorage.setItem(LocalStorageKey.TagSearch, JSON.stringify(searchCurrentState.searchTagParams));
  }, [searchCurrentState.searchTagParams]);

  return (
    <StyledCheckboxTreeContainer className="mg-top15">
      <CheckboxTree
        disabled={pageState.currentPage <= 0}
        nodes={nodes}
        checked={searchCurrentState.searchTagParams.tagChecked}
        expanded={expanded}
        onCheck={(checked) => {
          setSearchState({
            ...searchCurrentState,
            searchTagParams: {
              ...searchCurrentState.searchTagParams,
              tagChecked: checked,
              checkAll: tag.tagData?.tagNames?.length == checked.length,
            },
          });
        }}
        onExpand={(expanded) => setExpanded(expanded)}
        icons={{
          check: <Icon iconName="CheckboxComposite" />,
          uncheck: <Icon iconName="Checkbox" />,
          halfCheck: <Icon iconName="CheckboxFill" />,
          expandClose: <Icon iconName="ChevronRight" />,
          expandOpen: <Icon iconName="ChevronDown" />,
          expandAll: <Icon iconName="CaretLeftSolid8" />,
          collapseAll: <Icon iconName="CaretLeftSolid8" />,
          parentClose: null,
          parentOpen: null,
          leaf: null,
        }}
      />
      <Stack verticalAlign="center" className="mg-top15" tokens={{ childrenGap: 10 }}>
        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
          <Checkbox
            styles={{ root: { width: '50%' } }}
            disabled={pageState.currentPage <= 0}
            onChange={(ev, checked) => {
              setSearchState({
                ...searchCurrentState,
                searchTagParams: {
                  ...searchCurrentState.searchTagParams,
                  wholeWord: checked,
                },
              });
            }}
            checked={searchCurrentState.searchTagParams.wholeWord}
            label={tr('matchWholeWord')}
          />
          <Checkbox
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchTagParams.checkAll}
            label={tr('checkAll')}
            onChange={handleToggleCheckAll}
          />
        </Stack>

        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
          <Checkbox
            title={tr('matchCase')}
            styles={{
              root: {
                width: '50%', whiteSpace: 'nowrap',
                overflow: 'hidden'
              }
            }}
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchTagParams.matchCase}
            onChange={(ev, checked) => {
              setSearchState({
                ...searchCurrentState,
                searchTagParams: {
                  ...searchCurrentState.searchTagParams,
                  matchCase: checked,
                },
              });
            }}
            label={tr('matchCase')}
          />
          <Checkbox
            checked={searchCurrentState.searchTagParams.highlightWholeWord}
            disabled={pageState.currentPage <= 0}
            label={tr('selectEntireWord')}
            onChange={(ev, checked) => {
              setSearchState({
                ...searchCurrentState,
                searchTagParams: {
                  ...searchCurrentState.searchTagParams,
                  highlightWholeWord: checked,
                },
              });
            }}
          />
        </Stack>
      </Stack>
    </StyledCheckboxTreeContainer>
  );
};
