import { atom } from 'recoil';
import * as Api from '../ApiV2';
interface IThumbnailState {
  fromPage: number;
  toPage: number;
  selectPages: number[];
  showLoadding: boolean;
}
const thumbnailState = atom({
  key: 'thumbnailState',
  default: {
    fromPage: 1,
    toPage: 5,
    selectPages: [] as number[],
  },
});
export { thumbnailState };
