import * as Api from "../ApiV2";
import { DocuVieware } from "../recoil/docuviewareState";


export function generateSavingFileName(docuviewareCtx: DocuVieware, mode = 1) {
  let fileName = "ExtractFile";
  if (
    docuviewareCtx?.DocuviewareInit?.tabDetails &&
    (docuviewareCtx?.DocuviewareInit?.tabDetails || []).length > 0
  ) {
    const arrFile = docuviewareCtx?.DocuviewareInit?.tabDetails.filter(
      (tab) => tab.tabFileType == Api.TabFileType.NUMBER_0
    );
    if (arrFile && arrFile.length > 0) {
      if (mode == 1) {
        fileName = "X_" + arrFile[0].fileName?.trim();
      } else if (mode == 2) {
        fileName = "Document_" + arrFile[0].fileName?.trim();
      } else if (mode == 3) {
        fileName = "LegalXtract_" + arrFile[0].fileName?.trim();
      } else {
        fileName = arrFile[0].fileName?.trim() || "ExtractFile";
      }
    }
  }
  return fileName;
}

export function resizeImageToBase64(
  file: any,
  maxWidth: number,
  maxHeight: number
): Promise<string> {
  return new Promise((resolve) => {
    const img = new Image();
    const canvas: any = document.createElement("canvas");
    const ctx = canvas?.getContext("2d");
    img.onload = () => {
      const iw = img.width;
      const ih = img.height;
      const scale = Math.min(maxWidth / iw, maxHeight / ih);
      const iwScaled = iw * scale;
      const ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      resolve(canvas.toDataURL("image/png", 0.8));
    };
    img.src = URL.createObjectURL(file);
  });
}
export function generateBase64ImageFromText(text: string, height: number) {
  // Create a new canvas element
  const canvas = document.createElement('canvas');

  // Get the 2D context of the canvas
  const ctx = canvas.getContext('2d');
  if (ctx == null) return;
  // Set font and text alignment
  ctx.font = '20px Arial';

  // Measure the width of the text
  const textWidth = ctx.measureText(text).width;

  // Set canvas width based on text width
  canvas.width = textWidth;
  canvas.height = height;

  // Set text alignment after setting canvas width
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  // Fill the canvas with a white background
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Set text color
  ctx.fillStyle = '#000000';

  // Draw the text onto the canvas
  ctx.fillText(text, canvas.width / 2, canvas.height / 2);

  // Convert the canvas to a base64-encoded image data URL
  const base64ImageData = canvas.toDataURL('image/png');

  // Return the base64-encoded image data
  return base64ImageData;
}
export function GetSelectionBoxParameterFromWords(words: any[]): any[] {
  const selectionBox: any[] = [];
  words.forEach((word) => {
    if (word) {
      selectionBox.push({
        left: word.rects[0].left || 0,
        top: word.rects[0].top || 0,
        width: word.rects[0].width || 0,
        height: word.rects[0].height || 0,
        wordId: word.id,
      } as Api.RectangleSelection);
      if (word.rects.length > 1) {
        selectionBox.push({
          left: word.rects[1].left || 0,
          top: word.rects[1].top || 0,
          width: word.rects[1].width || 0,
          height: word.rects[1].height || 0,
          wordId: word.id,
        } as Api.RectangleSelection);
      }
    }
  });
  return selectionBox;
}
export function FocusedText(
  rectangleFCharacters: Api.RectangleF[] | null,
  viewerPageNo: number,
  docuViewareID: string,
  color = "rgb(84 181 254 / 50%)"
) {
  if (!rectangleFCharacters) return;
  const docuviewareRoot = DocuViewareAPI.Root(docuViewareID);
  if (!docuviewareRoot) return;
  if (rectangleFCharacters && rectangleFCharacters.length > 0) {
    docuviewareRoot.dom.SelectedTextRegions.clear();
    for (let i = 0; i < rectangleFCharacters.length; i++) {
      const word = rectangleFCharacters[i];
      if (word.left && word.top && word.width && word.height) {
        const data = {
          left: 96 * word.left,
          top: 96 * word.top,
          width: 96 * word.width,
          height: 96 * word.height,
        };
        if (!viewerPageNo || !data || !color) return;
        if (docuviewareRoot.dom && docuviewareRoot.dom.SelectedTextRegions) {
          docuviewareRoot.dom.SelectedTextRegions.addNew(
            viewerPageNo,
            data,
            color,
            null,
            2
          );
          const container = document.getElementById(`DocuVieware_Main`);
          if (
            !CheckVisibleElement(
              docuviewareRoot.dom.SelectedTextRegions._cache[0],
              container
            )
          ) {
            const viewerDocuVieware_Main = document.getElementById(
              "viewerDocuVieware_Main"
            );
            const el = docuviewareRoot.dom.SelectedTextRegions._cache[0];
            if (viewerDocuVieware_Main) {
              const el = docuviewareRoot.dom.SelectedTextRegions._cache[0];
              viewerDocuVieware_Main.scroll(
                viewerDocuVieware_Main.scrollLeft,
                el.offsetTop - viewerDocuVieware_Main.offsetHeight / 2
              );
            }
          }
        }
      }
    }
  }
}
export function GoToPositon(
  positon: { x: number; y: number } | null,
  viewerPageNo: number,
  docuViewareID: string,
  color = "transparent"
) {
  if (!positon) return;
  const docuviewareRoot = DocuViewareAPI.Root(docuViewareID);
  if (!docuviewareRoot) return;
  docuviewareRoot.dom.SelectedTextRegions.clear();
  const data = {
    left: positon.x * 96,
    top: positon.y * 96,
    width: 6,
    height: 6,
  };
  if (!viewerPageNo || !data || !color) return;
  if (docuviewareRoot.dom && docuviewareRoot.dom.SelectedTextRegions) {
    docuviewareRoot.dom.SelectedTextRegions.addNew(
      viewerPageNo,
      data,
      color,
      null,
      2
    );
    const container = document.getElementById(`DocuVieware_Main`);
    if (
      !CheckVisibleElement(
        docuviewareRoot.dom.SelectedTextRegions._cache[0],
        container
      )
    ) {
      const viewerDocuVieware_Main = document.getElementById(
        "viewerDocuVieware_Main"
      );
      if (viewerDocuVieware_Main) {
        viewerDocuVieware_Main.scrollLeft = 1000000000;
        const scrollWidth = viewerDocuVieware_Main.scrollWidth;
        const diff = (scrollWidth - viewerDocuVieware_Main.scrollLeft) / 2;
        const middle = scrollWidth / 2 - diff;
        const el = docuviewareRoot.dom.SelectedTextRegions._cache[0];
        viewerDocuVieware_Main.scroll(
          middle,
          el.offsetTop - viewerDocuVieware_Main.offsetHeight / 2
        );
      }
    }
  }
}
/**
 * Sorts an array of objects by column/property.
 * @param {Array} array - The array of objects.
 * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
 * @returns {Array} The sorted array.
 */
export function multiSort(array: any[], sortObject: any) {
  if (!sortObject) return array;
  const sortKeys = Object.keys(sortObject);

  // Return array if no sort object is supplied.
  if (!sortKeys.length) {
    return array;
  }

  // Change the values of the sortObject keys to -1, 0, or 1.
  for (const key in sortObject) {
    sortObject[key] =
      sortObject[key] === "desc" || sortObject[key] === -1
        ? -1
        : sortObject[key] === "skip" || sortObject[key] === 0
          ? 0
          : 1;
  }

  const keySort = (a: any, b: any, direction: any) => {
    direction = direction !== null ? direction : 1;

    if (a === b) {
      // If the values are the same, do not switch positions.
      return 0;
    }

    // If b > a, multiply by -1 to get the reverse direction.
    return a > b ? direction : -1 * direction;
  };

  return array.sort((a, b) => {
    let sorted = 0;
    let index = 0;

    // Loop until sorted (-1 or 1) or until the sort keys have been processed.
    while (sorted === 0 && index < sortKeys.length) {
      const key = sortKeys[index];

      if (key) {
        let direction = sortObject[key];
        if (typeof direction == "string" && sortObject[key]) {
          direction = sortObject[key].toString().toLowerCase();
        }
        let c = a[key];
        let d = b[key];
        if (typeof a[key] == "string") {
          c = c.toString().toLowerCase();
          d = d.toString().toLowerCase();
        }

        sorted = keySort(c, d, direction);
        index++;
      }
    }

    return sorted;
  });
}

export function getParameterByName(
  name: string,
  url = window.location.href.toLocaleLowerCase()
) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const CheckVisibleElement = (el: any, container: any): boolean => {
  const elementRect = el.getBoundingClientRect();
  if (!container) return false;
  const parentRect = container.getBoundingClientRect();

  return (
    (elementRect.top >= parentRect.top &&
      elementRect.top + elementRect.height <= parentRect.bottom &&
      elementRect.right >= parentRect.left &&
      elementRect.left + elementRect.width <= parentRect.right) ||
    (elementRect.top >= parentRect.top &&
      elementRect.right >= parentRect.left &&
      elementRect.left + elementRect.width <= parentRect.right && parentRect.bottom > elementRect.top)
  );
};

