import * as Api from '../ApiV2';
import { LocalStorageKey } from '../config/constant';
import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
import { UndoHightLight } from './undoHightLight';
import { CacheApp } from "../App";
const TrackingApi = new Api.TrackingApi();
const HighLightSectionCommand = (
  docuViewareID: string | null | undefined,
  param: Api.HighLightOrUnHighLightWords,
  callback: any,
) => {
  let ParamUndo = '';
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  const HighLightOrUnHighLightSection = (param: Api.HighLightOrUnHighLightWords) => {
    const dataRequest = {
      Data: JSON.stringify(param),
      Type: 'HighLightOrUnHighLightSection',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
      ParamUndo = res;
      CacheApp.pageDetails = [
        (
          await TrackingApi.apiLegalxtractGetWordByPageNoGet(
            CacheApp.PageState?.currentPage || 0
          )
        ).data,
      ];
      if (callback) {
        callback();
      }
    });
  };
  HighLightOrUnHighLightSection(param);
  undoManager.add({
    undo: function () {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    },
    redo: function () {
      HighLightOrUnHighLightSection(param);
    },
  });
};
export default HighLightSectionCommand;
