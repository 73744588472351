import styled from 'styled-components';
export const Commemt = styled.div`
  overflow: auto;
  height: calc(100%);
  .selected {
    background: #deecf9;
  }
  .comment {
    border: 1px solid #a29f9f;
    border-radius: 3px;
    box-shadow: 1px 1px 7px 0px #d2d1d1;
  }
  .comment-item {
    margin-bottom: 8px;
  }
  .comment-header {
    display: flex;
    padding: 8px 8px;
    .comment-user {
      flex: 1;
      padding: 0 8px;
    }
  }
  .comment-body {
    padding: 8px;
    white-space: break-spaces;
  }
  .comment-footer {
    display: flex;
    border-top: 1px solid #a29f9f;
    padding: 8px;
    .action {
      margin-left: 10px;
      cursor: pointer;
      font-size: 14px;
      color: #8d8b8a;
      display: flex;
      align-items: center;
      i {
        font-size: 18px;
        margin-right: 4px;
      }
      .ms-Dropdown-caretDown {
        font-size: 14px;
      }
    }
    .action:hover {
      color: #333;
    }
    .type {
      font-size: 18px;
      align-items: center;
      display: flex;
      label {
        font-size: 13px;
      }
    }
    [data-icon-name='SkypeCircleCheck'] {
      color: #05d458;
    }
    [data-icon-name='SkypeCircleMinus'] {
      color: #da0046;
    }
    .ms-Dropdown-title {
      border-color: transparent;
      background-color: rgb(247 247 247);
    }
  }
  .status-Finished {
    color: #333;
  }
  .status-Canceled {
    background-color: #353847;
    color: #fff;
  }

  .comment.comment-child {
    margin: 9px 0px 0px 14px;
  }
  .ms-Link {
    color: #333;
  }
`;
