import { axiosInstance } from '../config/axios-config';
import { Utility } from '../config/utility';
export const DocuviewareService = {
  GetDocuViewareControl: (fileList: File[]) => {
    const data = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      data.append('RequestFiles', fileList[i]);
    }
    return axiosInstance.post(`${Utility.baseUrl()}/api/legalxtract/GetDocuViewareControl`, data);
  },
  AppendFiles: (fileList: File[], checkexist: boolean) => {
    const data = new FormData();
    const fileNames: string[] = [];
    for (let i = 0; i < fileList.length; i++) {
      data.append('RequestFiles', fileList[i]);
      fileNames.push(fileList[i].name.replace(/\.[^/.]+$/, ''));
    }
    return axiosInstance.post(`${Utility.baseUrl()}/api/legalxtract/AppendFiles?checkexist=` + checkexist, data).then(async (body: any) => {
      return body;
    });
  },
  GetDocuViewareControl3: (fileList: File[]) => {
    const data = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      data.append('RequestFiles', fileList[i]);
      data.append('requestID', '0');
      data.append('saveWS', 'saveWS');
      data.append('saveRedacted', 'saveRedacted');
      data.append('returnPage', 'returnPage');
      data.append('user', 'user');
      data.append('org', 'org');
    }
    return axiosInstance.post(`${Utility.baseUrl()}/api/workspace/OpenFile`, data);
  },
};
