import {
  ActionButton,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IStackTokens,
  Icon,
  Label,
  Modal,
  Position,
  PrimaryButton,
  SelectionMode,
  SpinButton,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import ModalHeader from "../../control/modalHeader";
import { useLang } from "../../i18n/useLang";
import * as Api from "../../ApiV2";
import { ModalContent } from "../../assets/style/modal/modal";
import moment from "moment";
import { dialogState, trackingState } from "../../recoil";
import { userService } from "../../service/userService";
import { ImportConfirm } from "../../dialogs/confirmations/ConfirmationDialog";
import { saveAs } from "file-saver";
import { CheckboxVisibility, Selection } from "@fluentui/react/lib/DetailsList";
import { v4 } from "uuid";
import { DesignReportModal } from "../DesignReportModal";
const StyledPlaceholderLogo = styled.div`
  width: 100%;
  height: 151px;
  background-repeat: no-repeat;
  background-size: contain;
  border: solid 0.5px #818181;
  align-items: center;
  display: flex;
`;
const ApprovalStampApi = new Api.ApprovalStampApi();
const ApprovalStampTemplateApi = new Api.ApprovalStampTemplateApi();
const stackTokens: IStackTokens = { childrenGap: 10 };
interface ApprovalStampTemplateModalProps {
  show: boolean;
  onClose: () => void;
}
enum ImportType {
  Ignore = 1,
  Replicate = 2,
  Replace = 3,
}
export const ApprovalStampInfoDefault: Api.ApprovalStampInfo = {
  addFrame: false,
  approvalStampDesignRepx: "",
  date: moment().format("DD/MM/YYYY"),
  docNo: "Doc no.",
  location: { width: 2, height: 2 },
  transparent: 0,
};
const ApprovalStampTemplateModal = (props: ApprovalStampTemplateModalProps) => {
  const { tr } = useLang();
  const { getUser } = userService();
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const formChange = React.useRef<boolean>(false);
  const fileInputRef = React.useRef(null);
  const ImportData = React.useRef<Api.ApprovalStampTemplate[]>([]);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [, setmsgTitleEmpty] = React.useState<string>("");
  const [ShowImportMsg, SetShowImportMsg] = React.useState<{
    show: boolean;
    msg: string;
  }>({ show: false, msg: "" });
  const [ShowDesign, SetShowDesign] = React.useState<{
    show: boolean;
    msg: string;
  }>({ show: false, msg: "" });
  const [SelectedApprovalStampTemplate, setSelectedApprovalStampTemplateWeb] =
    React.useState<Api.ApprovalStampTemplateWeb>({
      addFrame: false,
      approvalStampDesignRepx: "",
      name: "",
      widthCm: 0,
      transparent: 0,
    });
  const [approvalStampTemplateWeb, setApprovalStampTemplateWeb] =
    React.useState<Api.ApprovalStampTemplateWeb[]>();
  const [StampImage, setStampImage] = React.useState<string>("");

  useEffect(() => {
    if (props.show) {
      GetApprovalStampTemplace().then((data) => {
        let template = null;
        if (!template) {
          0;
          template = data[0];
        }
        setSelectedApprovalStampTemplateWeb(template);
        GetApprovalStampImage(template);
      });
      formChange.current = false;
    }
  }, [props.show]);
  const GetApprovalStampTemplace = async () => {
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    const user = getUser();
    const res =
      await ApprovalStampTemplateApi.apiLegalxtractGetApprovalStampTemplatesPost(
        {
          data: 0,
          token: user?.userDataLogin?.result?.access_token,
        }
      );
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
    setApprovalStampTemplateWeb(res.data);
    return res.data;
  };
  const GetApprovalStampImage = async (
    template: Api.ApprovalStampTemplateWeb
  ) => {
    const res = await ApprovalStampApi.apiLegalxtractGetApprovalStampImagePost({
      approvalStampDesignRepx: template?.approvalStampDesignRepx || "",
      date: moment().format("DD/MM/YYYY"),
      docNo: "Doc no.",
      id: template.id,
    });
    setStampImage(res.data);
    return res.data;
  };
  useEffect(() => {
    if (!SelectedApprovalStampTemplate) return;
    setApprovalStampTemplateWeb(
      approvalStampTemplateWeb?.map((item) => {
        if (item.id == SelectedApprovalStampTemplate.id) {
          return SelectedApprovalStampTemplate;
        } else {
          return item;
        }
      })
    );
  }, [SelectedApprovalStampTemplate]);
  function getValue(value: string, max: number, min: number) {
    if (Number(value).toString() != "NaN") {
      if (Number(value) > max) {
        return max;
      }
      if (Number(value) < min) {
        return min;
      }
    } else {
      return min;
    }
    return Number(value);
  }

  const COLUMNS: IColumn[] = [
    {
      key: "name",
      name: tr("name"),
      fieldName: "name",
      minWidth: 70,
      isRowHeader: true,
      className: "note-content",
      isResizable: true,
      isPadded: true,
      onRender: (item: Api.ApprovalStampTemplateWeb) => {
        return <span title={item.name || ""}>{item.name}</span>;
      },
    },
    {
      key: "widthCm",
      name: tr("width"),
      fieldName: "widthCm",
      minWidth: 50,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      isPadded: true,
      onRender: (item: Api.ApprovalStampTemplateWeb) => {
        return item.widthCm;
      },
    },
    {
      key: "transparent",
      name: tr("transparant"),
      fieldName: "transparent",
      minWidth: 50,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      isPadded: true,
      onRender: (item: Api.ApprovalStampTemplateWeb) => {
        return item.transparent;
      },
    },
    {
      key: "delete",
      name: "",
      fieldName: "delete",
      minWidth: 30,
      maxWidth: 30,
      onRender: (item: Api.ApprovalStampTemplateWeb) => {
        return (
          <Icon
            onClick={() => {
              if (approvalStampTemplateWeb?.length == 1) {
                setDialogState({
                  ...dialog,
                  MsgDialog: tr("theLastApprovalStampCanNotBeDeleted"),
                  Type: "AlertDialog",
                });
                return;
              }
              setDialogState({
                ...dialog,
                MsgDialog: tr("doYouWantToDelete"),
                CallBackConfirmDialog: {
                  action: () => {
                    const newTemplates = approvalStampTemplateWeb?.filter(
                      (x) => x.id != item.id
                    );
                    setApprovalStampTemplateWeb(newTemplates);
                    formChange.current = true;
                    setSelectedApprovalStampTemplateWeb(newTemplates![0]);
                    GetApprovalStampImage(newTemplates![0]);
                  },
                  param: item,
                },
                Type: "ConfirmationDialog",
              });
            }}
            iconName="Delete"
            style={{ cursor: "pointer", fontSize: "17px", width: "17px" }}
          />
        );
      },
      isPadded: false,
    },
  ];
  const onSelectedImportFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e1) {
        const content = e1.target?.result;
        const data = JSON.parse(content as string) as any[];
        const newTemplates: Api.ApprovalStampTemplate[] = [];
        data.forEach((item) => {
          newTemplates.push({
            addFrame: item.AddFrame || false,
            approvalStampDesignRepx: item.ApprovalStampDesignRepx,
            name: item.Name,
            widthCm: item.WidthCm,
            id: item.Id,
            transparent: item.Transparent || 0,
          });
        });
        ImportData.current = newTemplates;
        const dumpIds = approvalStampTemplateWeb
          ?.filter((p) => newTemplates.findIndex((o) => o.id == p.id) > -1)
          .map((p) => p.id);
        if ((dumpIds?.length || 0) > 0) {
          SetShowImportMsg({
            show: true,
            msg:
              tr("importTemplateHas0ExistingItem").replaceAll(
                "{0}",
                dumpIds?.length.toString() || ""
              ) +
              "</br>" +
              tr("legalXtractWillHandleThose0Items").replaceAll(
                "{0}",
                dumpIds?.length.toString() || ""
              ),
          });
        } else {
          ImportTemplate(newTemplates, ImportType.Ignore);
        }
      };
      reader.readAsText(file);
    }
  };
  const GetDistinctName = (name: string, allNames: string[]) => {
    let i = 1;
    while (allNames.includes(`${name} (${i})`)) {
      i++;
    }
    return `${name} (${i})`;
  };

  const ImportTemplate = (
    datas: Api.ApprovalStampTemplate[],
    type: ImportType
  ) => {
    let newTemplates = approvalStampTemplateWeb;
    switch (type) {
      case ImportType.Ignore:
        datas.forEach((item) => {
          if (newTemplates?.findIndex((o) => o?.id === item?.id) == -1) {
            newTemplates = [...newTemplates!, item];
          }
        });
        break;
      case ImportType.Replicate:
        datas.forEach((item) => {
          if ((newTemplates || []).findIndex((o) => o?.id === item?.id) > -1) {
            item.name = GetDistinctName(
              item.name || "",
              newTemplates?.map((x) => x.name || "") || []
            );
            item.id = v4();
          }
          newTemplates = [...newTemplates!, item];
        });
        break;
      case ImportType.Replace:
        datas.forEach((item) => {
          const oldItem = newTemplates?.find((o) => o?.id === item?.id);
          if (oldItem) {
            oldItem.addFrame = item.addFrame;
            oldItem.approvalStampDesignRepx = item.approvalStampDesignRepx;
            oldItem.widthCm = item.widthCm;
            oldItem.transparent = item.transparent;
            oldItem.name = item.name;
            const newData = newTemplates?.map((o) => {
              return o.id == oldItem?.id ? oldItem : o;
            });
            newTemplates = newData;
          } else {
            newTemplates = [...newTemplates!, item];
          }
        });
        break;
      default:
        break;
    }
    setApprovalStampTemplateWeb(newTemplates || []);
    formChange.current = true;
    SetShowImportMsg({ show: false, msg: "" });
  };
  const handleImport = async () => {
    (fileInputRef.current as any).click();
  };
  const handleExportAll = async () => {
    const blob = new Blob(
      [
        JSON.stringify(
          approvalStampTemplateWeb?.map((o) => {
            return {
              AddFrame: o.addFrame,
              ApprovalStampDesignRepx: o.approvalStampDesignRepx,
              Name: o.name,
              WidthCm: o.widthCm,
              Id: o.id,
              Transparent: o.transparent,
            };
          })
        ),
      ],
      {
        type: "text/plain",
      }
    );
    saveAs(blob, "Approval stamp templates.dat");
  };
  const handleExportSelected = async () => {
    const blob = new Blob(
      [
        JSON.stringify([
          {
            AddFrame: SelectedApprovalStampTemplate?.addFrame,
            ApprovalStampDesignRepx:
              SelectedApprovalStampTemplate?.approvalStampDesignRepx,
            Name: SelectedApprovalStampTemplate?.name,
            WidthCm: SelectedApprovalStampTemplate?.widthCm,
            Id: SelectedApprovalStampTemplate?.id,
            Transparent: SelectedApprovalStampTemplate?.transparent,
          } as Api.ApprovalStampTemplate,
        ]),
      ],
      {
        type: "text/plain",
      }
    );
    saveAs(
      blob,
      `${SelectedApprovalStampTemplate.name} Approval stamp templates.dat`
    );
  };
  const showDesign = async () => {
    ApprovalStampTemplateApi.apiLegalxtractUploadTemplatePost({
      addFrame: SelectedApprovalStampTemplate.addFrame,
      approvalStampDesignRepx:
        SelectedApprovalStampTemplate.approvalStampDesignRepx,
      id: SelectedApprovalStampTemplate.id,
      transparent: SelectedApprovalStampTemplate.transparent,
      name: SelectedApprovalStampTemplate.name,
      widthCm: SelectedApprovalStampTemplate.widthCm,
    }).then((res) => {
      SetShowDesign({ show: true, msg: "" });
    });
  };
  const [selection] = useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        const items = selection.getSelection();
        if (!items) return;
        const selectedItems = items.map((o: any) => {
          return o as Api.ApprovalStampTemplateWeb;
        });
        if (!selectedItems || selectedItems.length == 0) {
          // setSelectedApprovalStampTemplateWeb({
          //   name: "",
          //   widthCm: 0,
          //   transparent: 0,
          // });
        } else {
          setSelectedApprovalStampTemplateWeb(selectedItems[0]);
          GetApprovalStampImage(selectedItems[0]);
        }
      },
    })
  );
  const SaveApprovalStampTemplate = async () => {
    ApprovalStampTemplateApi.apiLegalxtractSaveApprovalStampTemplatePost({
      data: approvalStampTemplateWeb,
      token: getUser()?.userDataLogin?.result?.access_token,
    });
    props.onClose();
  };
  const onClose = () => {
    if (formChange.current) {
      setDialogState({
        ...dialog,
        MsgDialog: tr("approvalStampTemplateHasChangedDoYouWantToSave"),
        CallBackConfirmDialog: {
          action: () => {
            SaveApprovalStampTemplate();
          },
          actionNo: () => {
            props.onClose();
          },
        },
        Type: "YesNoCancel",
      });
    } else {
      props.onClose();
    }
  };
  return (
    <Modal isOpen={props.show} onDismiss={onClose} isBlocking={true}>
      <ModalContent style={{ width: 850 }}>
        <div className="ms-Grid" dir="ltr">
          <Stack tokens={{ childrenGap: 5 }}>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-md12">
                <ModalHeader
                  title={tr("approvalStampTemplate")}
                  onCloseModal={onClose}
                />
              </div>
            </div>

            <div className="ms-Grid-row">
              <div
                className="ms-Grid-col ms-md7"
                style={{ textAlign: "center" }}
              >
                <DetailsList
                  styles={{ root: { height: 300, overflowX: "hidden" } }}
                  items={approvalStampTemplateWeb || []}
                  columns={COLUMNS}
                  selectionMode={SelectionMode.single}
                  getCellValueKey={(item, index) => item.id}
                  selection={selection}
                  setKey="none"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  isSelectedOnFocus={true}
                  selectionPreservedOnEmptyClick={true}
                />
                <div
                  className="ms-Grid-col ms-md12"
                  style={{ display: "flex", marginTop: 8 }}
                >
                  <DefaultButton
                    text={tr("import")}
                    style={{
                      minWidth: 80,
                    }}
                    onClick={() => {
                      handleImport();
                    }}
                    allowDisabledFocus
                  />
                  <div style={{ flex: 1 }}></div>
                  <DefaultButton
                    text={tr("exportAll")}
                    style={{
                      minWidth: 80,
                    }}
                    onClick={() => {
                      handleExportAll();
                    }}
                    allowDisabledFocus
                  />
                </div>
              </div>
              <div className="ms-Grid-col ms-md5">
                <Stack
                  tokens={{ childrenGap: 5 }}
                  className="ms-Grid-col ms-md6"
                >
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-md12">
                      <Label>{tr("name")}</Label>
                      <TextField
                        value={SelectedApprovalStampTemplate?.name || ""}
                        onChange={(e, value) => {
                          setSelectedApprovalStampTemplateWeb({
                            ...SelectedApprovalStampTemplate,
                            name: value ? value : "",
                          });
                          formChange.current = true;
                          setmsgTitleEmpty("");
                        }}
                      />
                    </div>
                    <div className={`ms-Grid-col ms-md12`}>
                      <Label>{tr("width")}</Label>
                      <SpinButton
                        value={`${SelectedApprovalStampTemplate?.widthCm}`}
                        labelPosition={Position.end}
                        min={1}
                        max={100}
                        onIncrement={(value) => {
                          setSelectedApprovalStampTemplateWeb({
                            ...SelectedApprovalStampTemplate,
                            widthCm: +value + 0.25,
                          });
                          formChange.current = true;
                        }}
                        onDecrement={(value) => {
                          if (
                            Number(SelectedApprovalStampTemplate?.widthCm) > 1
                          ) {
                            setSelectedApprovalStampTemplateWeb({
                              ...SelectedApprovalStampTemplate,
                              widthCm: +value - 0.25 < 1 ? 1 : +value - 0.25,
                            });
                            formChange.current = true;
                          }
                        }}
                        onBlur={(ev) => {
                          const value = getValue(
                            ev.target.value || "1",
                            100,
                            1
                          );
                          setSelectedApprovalStampTemplateWeb({
                            ...SelectedApprovalStampTemplate,
                            widthCm: value,
                          });
                          formChange.current = true;
                        }}
                        incrementButtonAriaLabel={"Increase value by 1"}
                        decrementButtonAriaLabel={"Decrease value by 1"}
                      />
                    </div>
                    <div
                      className="ms-Grid-col ms-md12"
                      style={{ marginRight: "16px" }}
                    >
                      <Label>{tr("transparant")}</Label>
                      <SpinButton
                        value={`${SelectedApprovalStampTemplate?.transparent}`}
                        labelPosition={Position.end}
                        min={1}
                        max={100}
                        onIncrement={(value) => {
                          setSelectedApprovalStampTemplateWeb({
                            ...SelectedApprovalStampTemplate,
                            transparent: +value + 1,
                          });
                          formChange.current = true;
                        }}
                        onDecrement={(value) => {
                          if (
                            Number(SelectedApprovalStampTemplate?.transparent) >
                            0
                          ) {
                            setSelectedApprovalStampTemplateWeb({
                              ...SelectedApprovalStampTemplate,
                              transparent: +value - 1 < 1 ? 1 : +value - 1,
                            });
                            formChange.current = true;
                          }
                        }}
                        onBlur={(ev) => {
                          const value = getValue(
                            ev.target.value || "1",
                            100,
                            1
                          );
                          setSelectedApprovalStampTemplateWeb({
                            ...SelectedApprovalStampTemplate,
                            transparent: +value,
                          });
                          formChange.current = true;
                        }}
                        incrementButtonAriaLabel={"Increase value by 1"}
                        decrementButtonAriaLabel={"Decrease value by 1"}
                      />
                    </div>
                  </div>
                </Stack>
                <div className="ms-Grid-col ms-md6">
                  <Label>{tr("preview")}</Label>
                  <StyledPlaceholderLogo>
                    <img
                      style={{
                        maxWidth: "100%",
                        margin: "auto",
                      }}
                      src={"data:image/png;base64, " + StampImage}
                      alt={""}
                    />
                  </StyledPlaceholderLogo>
                  <div
                    className="ms-Grid-row"
                    style={{
                      display: "flex",
                      marginTop: 8,
                    }}
                  >
                    <div
                      className="ms-Grid-col ms-md12"
                      style={{ textAlign: "right" }}
                    >
                      <DefaultButton
                        text={tr("export")}
                        style={{
                          minWidth: 60,
                        }}
                        onClick={() => {
                          handleExportSelected();
                        }}
                        allowDisabledFocus
                      />
                      <DefaultButton
                        text={tr("Design")}
                        style={{
                          minWidth: 60,
                          marginLeft: 8,
                        }}
                        onClick={() => {
                          showDesign();
                        }}
                        allowDisabledFocus
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="ms-Grid-row"
              style={{ textAlign: "end", marginTop: 16 }}
            >
              <div className="ms-Grid-col" style={{ float: "right" }}>
                <Stack horizontal tokens={stackTokens}>
                  <DefaultButton
                    text={tr("close")}
                    style={{ minWidth: 80 }}
                    onClick={onClose}
                    allowDisabledFocus
                  />
                  <PrimaryButton
                    text={tr("save")}
                    style={{
                      minWidth: 80,
                    }}
                    onClick={() => {
                      SaveApprovalStampTemplate();
                    }}
                    allowDisabledFocus
                  />
                  <input
                    style={{ display: "none" }}
                    multiple={false}
                    ref={fileInputRef}
                    type="file"
                    id="uploadtag"
                    accept={".dat"}
                    onChange={(e: any) => {
                      onSelectedImportFile(e);
                      e.target.value = "";
                    }}
                  />
                </Stack>
              </div>
            </div>
          </Stack>
        </div>
        {ShowImportMsg.show && (
          <ImportConfirm
            title={tr("question")}
            subText={ShowImportMsg.msg}
            onIgnore={() => {
              ImportTemplate(ImportData.current, ImportType.Ignore);
            }}
            onReplicate={() => {
              ImportTemplate(ImportData.current, ImportType.Replicate);
            }}
            onReplace={() => {
              ImportTemplate(ImportData.current, ImportType.Replace);
            }}
            onClose={() => {
              SetShowImportMsg({ show: false, msg: "" });
            }}
          ></ImportConfirm>
        )}
        {ShowDesign.show && (
          <DesignReportModal
            templateId={SelectedApprovalStampTemplate.id || ""}
            isShow={ShowDesign.show}
            onClose={(xml) => {
              SetShowDesign({ show: false, msg: "" });
              if (xml) {
                setSelectedApprovalStampTemplateWeb({
                  ...SelectedApprovalStampTemplate,
                  approvalStampDesignRepx: xml,
                });
                GetApprovalStampImage({
                  ...SelectedApprovalStampTemplate,
                  approvalStampDesignRepx: xml,
                });
              }
            }}
          ></DesignReportModal>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ApprovalStampTemplateModal;
