import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: calc(100% - 58px);
  overflow: auto;
  margin-top: 10px;
  .replaceList {
    display: inline-flex;
  }
  .ms-DetailsRow-cell--color {
    max-width: 64px;
  }
  .ms-DetailsRow-cell--count {
    text-align: center;
    padding-right: 0;
    min-width: 72px;
  }
  .ms-DetailsHeader-cell {
    min-width: 64px;
  }
  .ms-DetailsRow-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ms-TooltipHost {
      text-align: left;
      display: flex;
      justify-content: center;
    }
  }
`;
export const StyledPreviewOriginalBox = styled.div`
  height: 210px;
  background: #f1f1f1;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`;
