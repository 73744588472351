import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import { noteState, pageSelectedState } from "../recoil";
const NoteApi = new Api.NoteApi();
export const noteService = () => {
  const [noteCurrentState, setNoteState] = useRecoilState(noteState);
  const [pageState] = useRecoilState(pageSelectedState);
  const refreshNoteList = async () => {
    if (pageState.currentPage === 0) {
      setNoteState((cur) => {
        return { ...cur, allNotes: [] };
      });
      return;
    }
    const noteTextObjs = (await NoteApi.apiLegalxtractGetListNoteGet()).data;
    setNoteState((cur) => {
      return { ...cur, allNotes: noteTextObjs };
    });
  };
  return { refreshNoteList };
};
