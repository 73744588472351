import { atom } from "recoil";
import * as Api from "../ApiV2";

interface IApprovalStampState {
  ApprovalStampViewItems: Api.ApprovalStampViewItem[];
  isAdd: boolean;
  EditApprovalStampInfo: Api.ApprovalStampViewItem | null;
}
const approvalStampState = atom<IApprovalStampState>({
  key: "approvalStampState",
  default: {
    EditApprovalStampInfo: null,
    ApprovalStampViewItems: [] as Api.ApprovalStampViewItem[],
  } as IApprovalStampState,
});
export type { IApprovalStampState };
export { approvalStampState };