import styled from 'styled-components';
export const DialogContent = styled.div`
  display: flex;
  align-items: center;
  i {
    font-size: 36px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    background: #0078d4;
    text-align: center;
    color: #fff;
    display: inline-block;
    line-height: 39px;
    margin-right: 10px;
  }
  i[data-icon-name='Warning'] {
    color: #f3ae14;
    background: #fff;
  }
`;
