import styled from 'styled-components';
export const StyleDetailList = styled.div`
  overflow: auto;
  height: calc(100% - 90px);
  margin-top: 10px;
  .ms-DetailsList {
    overflow: unset;
    .is-row-header {
      flex: 1;
    }
    .ms-DetailsRow-fields {
      width: 100%;
    }
    .ms-GroupSpacer,
    .ms-DetailsHeader-cell[aria-expanded] {
      display: none !important;
    }
    [data-item-key='word'] {
      flex: 1;
    }
    .ms-DetailsHeader {
      display: flex;
    }
  }
  .loadding {
    display: flex;
    align-items: center;
  }
  .ms-TooltipHost {
    width: 100%;
    text-align: left;
  }
`;
