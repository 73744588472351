import * as Api from "../ApiV2";
import undoManager from "./undo-manager";
import { annotationService } from "../service/annotationService";
import { LocalStorageKey } from "../config/constant";
import { UndoHightLight } from "./undoHightLight";
import { CacheApp } from "../App";
const TrackingApi = new Api.TrackingApi();
const HighlightTextCommand = (
  docuViewareID: string | null | undefined,
  param: Api.HighLightOrUnHighLightWords,
  callback: any
) => {
  let ParamUndo = "";
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const HighLightOrUnHighLightWords = (pr: Api.HighLightOrUnHighLightWords) => {
    const dataRequest = {
      Data: JSON.stringify(pr),
      Type: "HighlightTextCommand",
    };
    annotationService.AddAnnotations(
      docuViewareID,
      dataRequest,
      async (res: any) => {
        ParamUndo = res;
        CacheApp.pageDetails = [
          (
            await TrackingApi.apiLegalxtractGetWordByPageNoGet(
              param.viewerPageNo
            )
          ).data,
        ];
        if (callback) {
          callback();
        }
      }
    );
  };
  HighLightOrUnHighLightWords(param);
  undoManager.add({
    undo: function () {
      UndoHightLight(docuViewareID, ParamUndo, callback);
    },
    redo: function () {
      HighLightOrUnHighLightWords(param);
    },
  });
};

export default HighlightTextCommand;
