import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import { approvalStampState } from "../recoil/approvalStampState";
import { pageSelectedState } from "../recoil";
const ApprovalStampApi = new Api.ApprovalStampApi();
export const approvalStampService = () => {
  const [, setApprovalStampState] = useRecoilState(approvalStampState);
  const [pageState] = useRecoilState(pageSelectedState);
  const GetListApprovalStamp = async () => {
    // if (pageState.currentPage === 0) {
    //   setApprovalStampState((cur) => {
    //     return { ...cur, ApprovalStampViewItems: [] };
    //   });
    //   return;
    // }
    const data = (
      await ApprovalStampApi.apiLegalxtractGetListApprovalStampGet()
    ).data;
    // data.forEach((item: Api.ApprovalStampViewItem) => {
    //   item.location = {
    //     ...item.location,
    //     centerX: (item.location?.centerX || 0) * 72,
    //     centerY: (item.location?.centerY || 0) * 72,
    //   };
    // });
    setApprovalStampState((cur) => {
      return { ...cur, ApprovalStampViewItems: data };
    }); 
  };
  return { GetListApprovalStamp };
};
