import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import { historyState } from "./../recoil/historyState";
import { pageSelectedState } from "../recoil";
const HistoryApi = new Api.HistoryApi();
export const historyService = () => {
  const [, setHistoryState] = useRecoilState(historyState);
  const [pageState] = useRecoilState(pageSelectedState);
  const getHistoryView = async (tabId: string) => {
    if (pageState.currentPage === 0) {
      setHistoryState((cur) => {
        return { ...cur, HistoryView: { groups: [], items: [] } };
      });
      return;
    }
    const history = (
      await HistoryApi.apiLegalxtractGetDataOfHistoryFileGet(tabId)
    ).data;
    setHistoryState((cur) => {
      return { ...cur, HistoryView: history };
    });
  };
  return { getHistoryView };
};
