import { atom } from 'recoil';
import * as Api from '../ApiV2';

interface IHistoryState {
  HistoryView: Api.HistoryView | null;
  tabId: string;
}

export const historyState = atom<IHistoryState>({
  key: 'historyState',
  default: {
    HistoryView: null,
    tabId: '00000000-0000-0000-0000-000000000000',
  },
});
