import React from 'react';
import { useRecoilState } from 'recoil';
import { StyledMainModal, StyledMainSpinner } from '../../assets/style/control/loadding';
import { trackingState } from '../../recoil';
const LoaderFullscreen = () => {
  const [tracking] = useRecoilState(trackingState);
  return (
    <StyledMainModal isOpen={tracking.openingLoadingState.isOpen} isBlocking isDarkOverlay>
      <StyledMainSpinner label={tracking.openingLoadingState.label} />
    </StyledMainModal>
  );
};

export default LoaderFullscreen;
