import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/dreamweaver.css";
import "ace-builds/css/theme/ambiance.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";
import ReportDesigner, {
  RequestOptions,
  DxReportDesignerRef,
  Callbacks,
} from "devexpress-reporting-react/dx-report-designer";
import { fetchSetup } from "@devexpress/analytics-core/analytics-utils";
import { IAction } from "@devexpress/analytics-core/widgets/utils";
import { useLang } from "../../i18n/useLang";
import { useSearchParams } from "react-router-dom";
import { Constants, DataCache, LocalStorageKey } from "../../config/constant";
import * as Api from "../../ApiV2";
import { axiosInstance } from "../../config/axios-config";
import { Utility } from "../../config/utility";
import Cookies from "js-cookie";
import { userService } from "../../service/userService";

const ApprovalStampTemplateApi = new Api.ApprovalStampTemplateApi();
export const DesignReport = () => {
  const { tr } = useLang();
  const { getUser } = userService();
  const [searchParams, setSearchParams] = useSearchParams();
  const templateId = searchParams.get("templateId");
  const designerRef = React.useRef<DxReportDesignerRef>(null);
  const TokenNovaLegalXtract = Cookies.get(Constants.TokenThirdParty);
  const user = getUser();
  fetchSetup.fetchSettings = {
    headers: {
      TimezoneOffset: new Date().getTimezoneOffset() / -1,
      IdentifiedKey: localStorage.IdentifiedKey?.toString(),
      TokenThirdParty: TokenNovaLegalXtract || "",
      Authorization: "Bearer " + user?.token,
      TokenLegalManager: user?.userDataLogin?.result?.access_token,
      UserInfo: encodeURI(
        JSON.stringify({
          email: user?.userDataLogin?.result?.user_info?.email || "",
          userName: user?.userDataLogin?.result?.user_info?.fullName || "",
        })
      ),
    },
  };
  function CustomizeMenuActions(args: any): void {
    const actions = args.args.Actions as IAction[];
    actions
      .filter((x) => x.container === "menu")
      .forEach((x) => {
        if (x.id !== "dxrd-newreport") {
          x.visible = false;
        }
      });
    args.args.Actions.push({
      text: "Open",
      container: "menu",
      id: "Open",
      hasSeparator: false,
      visible: true,
      clickAction: function () {
        (fileInputRef.current as any).click();
      },
    } as IAction);
  }
  const fileInputRef = React.useRef(null);
  const onSelectedFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const data = new FormData();
    const file1 = e.target.files[0];
    const blob = new Blob([file1], { type: file1.type });
    const myFile = new File(
      [blob],
      localStorage.getItem(LocalStorageKey.IdentifiedKey) +
        "_" +
        templateId +
        ".xml"
    );
    data.append("file", myFile);
    const response = await axiosInstance.post(
      `${Utility.baseUrl()}/api/legalxtract/OpenFileTemplate`,
      data
    );
    if (designerRef) {
      designerRef.current?.instance().CloseCurrentTab();
      designerRef.current
        ?.instance()
        .OpenReport(
          localStorage.getItem(LocalStorageKey.SessionID) + "_" + templateId
        );
    }
  };
  if (!templateId) {
    return <></>;
  }
  return (
    <>
      <ReportDesigner
        ref={designerRef}
        reportUrl={templateId || ""}
        height="calc(100% - 0px)"
      >
        <RequestOptions
          host={(DataCache.REACT_APP_API_ENDPOINT || "") + "/"}
          getDesignerModelAction="DXXRD/GetApprovalTemplateDesignerModel"
        />
        <Callbacks
          BeforeRender={(args) => {}}
          CustomizeMenuActions={CustomizeMenuActions}
        />
      </ReportDesigner>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}
      >
        <PrimaryButton
          id="saveReport"
          allowDisabledFocus
          style={{ marginLeft: 8, marginRight: 8, display: "none" }}
          onClick={() => {
            designerRef.current
              ?.instance()
              .SaveNewReport(
                localStorage.getItem(LocalStorageKey.SessionID) +
                  "_" +
                  templateId
              );
            designerRef.current?.instance().ResetIsModified();
          }}
        />
        <PrimaryButton
          id="closeReport"
          allowDisabledFocus
          style={{ marginLeft: 8, marginRight: 8, display: "none" }}
          onClick={() => {
            (document as any)["IsModified"] = designerRef.current
              ?.instance()
              .IsModified();
          }}
        />
        <input
          style={{ display: "none" }}
          multiple={false}
          ref={fileInputRef}
          type="file"
          id="uploadtag"
          accept={".repx"}
          onChange={(e: any) => {
            onSelectedFile(e);
            e.target.value = "";
          }}
        />
      </div>
    </>
  );
};
