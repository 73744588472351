import React, { useEffect, useRef, useState } from "react";
import * as Api from "../../../ApiV2";
import { StyledTreeTOC } from "../../../assets/style/components/treeToc";
import {
  Tree,
  TreeRef,
  TreeItem,
  TreeItemRenderContext,
  TreeInformation,
  TreeItemIndex,
  DraggingPosition,
  ControlledTreeEnvironment,
} from "react-complex-tree";
import "react-complex-tree/lib/style-modern.css";
import { useLang } from "../../../i18n/useLang";
import { TocContextMenu } from "./tocContextMenu";
import { useBoolean } from "@fluentui/react-hooks";
import { TOCState } from "../../../recoil";
import { useRecoilState } from "recoil";
import { AddBlankPageModal } from "../../../modal/AddBlankPageModal";
import { DataCache } from "../../../config/constant";
import { documentService } from "../../../service/document";
const TocApi = new Api.TocApi();
let timeOut: any = null;
export interface TreeBookmarkProps {
  datas: Api.TocRow[];
  onItemsChanged?: (dataDoc: Api.DocuviewareInit, tocRow: Api.TocRow) => void;
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDrop?: (
    items: TreeItem<Api.TocRow>[],
    target: DraggingPosition,
    treeData: any,
    selectedItems: TreeItemIndex[]
  ) => void;
}
const TreeToc = ({
  datas,
  onItemsChanged,
  onDrop,
  onContextMenu,
}: TreeBookmarkProps) => {
  const { tr } = useLang();
  const [tocCurrentState, setTOCState] = useRecoilState(TOCState);
  const [focusedItem, setFocusedItem] = useState<TreeItemIndex>();
  const [maxLevel, setMaxLevel] = useState<number>(0);
  const [selectedItems, setSelectedItems] = useState<TreeItemIndex[]>([]);
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[]>([]);
  const [targetPoint, SetTargetPoint] = useState<{ x: number; y: number }>();
  const [IsShowContextMenu, { toggle: SetIsShowContextMenu }] =
    useBoolean(false);
  const [IsShowAddBlankPageModal, { toggle: SetIsShowAddBlankPageModal }] =
    useBoolean(false);
  const [itemTrees, setItemTrees] = useState<any>({
    root: {
      index: "root",
      canMove: true,
      isFolder: false,
      children: [],
      canRename: true,
      data: { id: "00000000-0000-0000-0000-000000000000" },
    },
  });
  const tree = useRef<TreeRef>(null);
  useEffect(() => {
    const timout = setTimeout(() => {
      if (tree.current) {
        tree.current.expandAll();
      }
    }, 500);
    return () => clearTimeout(timout);
  }, []);
  useEffect(() => {
    const items = [];
    for (const field in itemTrees) {
      if (field != "root" && itemTrees[field].children.length > 0) {
        items.push(field);
      }
      setExpandedItems(items);
    }
  }, [itemTrees]);
  useEffect(() => {
    setItemTrees(BuildDataTree(datas));
    const allLevel = datas.map(
      (o) => (o.indexView || "").split(".").length || 0
    );
    setMaxLevel(Math.max(...allLevel));
  }, [datas]);
  const dropNodes = (items: TreeItem<any>[], target: DraggingPosition): any => {
    let ids = items.map((o) => o.index);
    if (target.targetType == "between-items") {
      const idViTriLonHons: string[] = [];
      const idViTriNhoHons: string[] = [];
      let linearIndex = target.childIndex;
      if (target.parentItem != "root") {
        linearIndex = target.childIndex;
      }
      ((itemTrees[target.parentItem].children as string[]) || []).forEach(
        (o, index) => {
          if (index < linearIndex) {
            if (!ids.includes(o)) {
              idViTriNhoHons.push(o);
            }
          } else {
            if (!ids.includes(o)) {
              idViTriLonHons.push(o);
            }
          }
        }
      );
      itemTrees[target.parentItem].children = [
        ...idViTriNhoHons,
        ...ids,
        ...idViTriLonHons,
      ];
      // }
      for (const field in itemTrees) {
        if (field != target.parentItem)
          itemTrees[field].children = (
            (itemTrees[field].children as string[]) || []
          ).filter((o) => !ids.includes(o));
      }
    } else if (target.targetType == "item") {
      // var idSelects = (Items.root.children || []).filter((o: string) =>
      //   ids.includes(o)
      // );
      const item = itemTrees[target.targetItem];

      ids = ids.filter(
        (o) => !(item.children as string[]).includes(o as string)
      );
      if (ids.length == 0) return itemTrees;
      const idUnSelects = (itemTrees.root.children || []).filter(
        (o: string) => !ids.includes(o)
      );
      itemTrees.root.children = idUnSelects;
      item.children = [...item.children, ...ids];
      for (const field in itemTrees) {
        if (field != "root" && field != target.targetItem)
          itemTrees[field].children = (
            (itemTrees[field].children as string[]) || []
          ).filter((o) => !ids.includes(o));
      }
    }
    setItemTrees({ ...itemTrees });
    return itemTrees;
  };
  function BuildDataTree(tocs: Api.TocRow[]) {
    const dataResult: any = {
      root: {
        index: "root",
        canMove: true,
        isFolder: false,
        children: tocs
          .filter((o) => o.parentId == "00000000-0000-0000-0000-000000000000")
          .map((o) => o.id),
        canRename: true,
        data: { id: "00000000-0000-0000-0000-000000000000" },
      },
    };
    tocs.forEach((element) => {
      const childs = tocs
        .filter((o) => o.parentId == element.id)
        .map((o) => o.id);
      const a = {
        index: element.id,
        name: element.fileName,
        canMove: true,
        isFolder: true,
        children: childs || [],
        data: element,
        canRename: true,
      };
      dataResult[element.id || ""] = a;
    });
    return dataResult;
  }
  const UpdateTOC = async (tocRow: Api.TocRow) => {
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
      timeOut = null;
      const res = await TocApi.apiLegalxtractEditTocRowPost(tocRow);
      onItemsChanged && onItemsChanged(res.data, tocRow);
    }, 300);
  };
  const GetAllSelected = (item: string): string[] => {
    let allitemSelecteds: string[] = [];
    allitemSelecteds = [...(itemTrees[item].children as string[])];
    allitemSelecteds.forEach((element) => {
      allitemSelecteds = [
        ...allitemSelecteds,
        ...GetAllSelected(element.toString()),
      ];
    });
    return allitemSelecteds;
  };
  return (
    <StyledTreeTOC
      style={{ position: "relative" }}
      onContextMenu={(e) => {
        // onContextMenu && onContextMenu(e);
        e.preventDefault();
        e.stopPropagation();
        SetTargetPoint({ x: e.clientX, y: e.clientY });
        SetIsShowContextMenu();
      }}
      className="treeToc "
    >
      <div
        className={`toc-header-row border-bottom`}
        style={{
          height: 44,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className="toccol border-left "
          style={{
            minWidth: (maxLevel - 1) * 10 + 59,
            display: "flex",
            alignItems: "center",
            padding: "0 10px 0 10px",
            boxSizing: "border-box",
          }}
        >
          {tr("index")}
        </div>
        <div className="toccol" style={{ flex: 1 }}>
          <div className="border-bottom" style={{ height: 21 }}>
            {tr("filename")}
          </div>
          <div style={{ display: "flex" }}>
            <div className="border-right" style={{ flex: 1 }}>
              {tr("date")}
            </div>
            <div className="border-right" style={{ flex: 1 }}>
              {tr("appendix")}
            </div>
            <div style={{ flex: 1, height: 22 }}>{tr("text")}</div>
          </div>
        </div>
        <div
          style={{
            width: 55,
            display: "flex",
            alignItems: "center",
            padding: "0 10px 0 10px",
          }}
          className="border-right"
        >
          <span>{tr("page")}</span>
        </div>
      </div>

      <ControlledTreeEnvironment<Api.TocRow>
        canDropOnFolder
        canSearch={false}
        canDragAndDrop={true}
        canReorderItems={true}
        onDrop={(e, ta) => {
          const treeData = dropNodes(e, ta);
          onDrop && onDrop(e, ta, treeData, selectedItems);
        }}
        items={itemTrees}
        getItemTitle={(item) => item.data.id || ""}
        onSelectItems={(items) => {
          setSelectedItems(items);
          const tocSelecteds = tocCurrentState.tocData.filter((o) =>
            items.includes(o.id || "")
          );

          setTOCState((cur) => {
            return {
              ...cur,
              tocSelectedItem: [...tocSelecteds],
            };
          });
        }}
        onFocusItem={(item) => setFocusedItem(item.index)}
        onExpandItem={(item) =>
          setExpandedItems([...expandedItems, item.index])
        }
        onCollapseItem={(item) =>
          setExpandedItems(expandedItems.filter((o) => o !== item.index))
        }
        viewState={{
          "tree-1": { selectedItems, expandedItems, focusedItem },
        }}
        renderItem={(props: {
          item: TreeItem<Api.TocRow>;
          depth: number;
          children: React.ReactNode;
          title: React.ReactNode;
          arrow: React.ReactNode;
          context: TreeItemRenderContext<never>;
          info: TreeInformation;
        }) => {
          // props.context.interactiveElementProps.onClick = (ev) => {
          //   if (ev.ctrlKey) {
          //     const id = (ev.target as any).parentNode.getAttribute(
          //       "data-rct-item-id"
          //     );
          //     if (selectedItems.find((o) => o == id)) {
          //       props.context.unselectItem();
          //     } else {
          //       props.context.addToSelectedItems();
          //     }
          //   }   else {
          //     props.context.selectItem();
          //   }
          // };
          props.context.interactiveElementProps.onContextMenu = () => {
            const tocItem = tocCurrentState.tocData.find(
              (o) => (o.id || "") === props.item.index
            );
            if (selectedItems.length == 1 || selectedItems.length == 0) {
              props.context.selectItem();
            }
            setTOCState((cur) => {
              return {
                ...cur,
                tocFocusItem: tocItem || null,
              };
            });
          };
          return (
            <li {...props.context.itemContainerWithChildrenProps}>
              <div
                {...props.context.itemContainerWithoutChildrenProps}
                {...(props.context.interactiveElementProps as any)}
                style={{
                  display: "flex",
                  borderTop: "1px solid #3c3c3c",
                  borderBottom: "1px solid #3c3c3c",
                  borderLeft: "1px solid #3c3c3c",
                  height: 43,
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {props.item && (props.item.children?.length || 0) > 0
                    ? props.arrow
                    : ""}
                </div>
                <div
                  className="toccol"
                  style={{
                    display: "flex",
                    padding: "0 10px",
                    minWidth:
                      (maxLevel -
                        (props.item.data.indexView?.split(".")?.length || 1)) *
                        10 +
                      50 +
                      ((props.item.children?.length || 0) > 0 ? 2 : 8),
                    boxSizing: "border-box",
                  }}
                  onDoubleClick={() => {
                    const tocItem = tocCurrentState.tocData.find(
                      (o) => (o.id || "") === props.item.index
                    );
                    documentService.GotoPage(
                      DataCache.docuViewareID(),
                      tocItem?.startPage || 0
                    );
                  }}
                >
                  {props.item.data.indexView}
                </div>
                <div className="toccol" style={{ flex: "1" }}>
                  <div className="" style={{ width: "100%" }}>
                    <input
                      style={{ width: "100%" }}
                      value={props.item.data.fileName || ""}
                      onChange={(e) => {
                        let item = datas.find((o) => o.id == props.item.index);
                        if (item && item.fileName != e.target.value) {
                          item = {
                            ...item,
                            fileName: e.target.value,
                          };
                          setTOCState((cur) => {
                            const newarrItem = cur.tocData.filter(
                              (o) => o.uniqueId != item?.uniqueId
                            );
                            const curItem = cur.tocData.find(
                              (o) => o.uniqueId == item?.uniqueId
                            );
                            newarrItem.splice(
                              cur.tocData.indexOf(curItem || {}),
                              0,
                              item || {}
                            );
                            return { ...cur, tocData: newarrItem };
                          });
                          UpdateTOC({
                            ...item,
                            fileName: e.target.value,
                          });
                        }
                      }}
                      onBlur={(e) => {
                        // const item = datas.find(
                        //   (o) => o.id == props.item.index
                        // );
                      }}
                      type="text"
                    ></input>
                  </div>
                  <div style={{ width: "100%", display: "flex" }}>
                    <div
                      className="border-right border-top"
                      style={{ width: "100%" }}
                    >
                      <input
                        type="text"
                        defaultValue={props.item.data.date || ""}
                        onBlur={(e) => {
                          const item = datas.find(
                            (o) => o.id == props.item.index
                          );
                          if (item && item.date != e.target.value) {
                            UpdateTOC({
                              ...item,
                              date: e.target.value,
                            });
                          }
                        }}
                        style={{ width: "100%" }}
                      ></input>
                    </div>
                    <div
                      className="border-right border-top"
                      style={{ width: "100%" }}
                    >
                      <input
                        style={{ width: "100%" }}
                        defaultValue={props.item.data.appendix || ""}
                        onBlur={(e) => {
                          const item = datas.find(
                            (o) => o.id == props.item.index
                          );
                          if (item && item.appendix != e.target.value) {
                            UpdateTOC({
                              ...item,
                              appendix: e.target.value,
                            });
                          }
                        }}
                        type="text"
                      ></input>
                    </div>
                    <div className="border-top" style={{ width: "100%" }}>
                      <input
                        type="text"
                        defaultValue={props.item.data.text || ""}
                        onBlur={(e) => {
                          const item = datas.find(
                            (o) => o.id == props.item.index
                          );
                          if (item && item.text != e.target.value) {
                            UpdateTOC({
                              ...item,
                              text: e.target.value,
                            });
                          }
                        }}
                        style={{ width: "100%" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  className="toccol"
                  style={{ display: "flex", padding: "0 10px", width: 55 }}
                  onDoubleClick={() => {
                    const tocItem = tocCurrentState.tocData.find(
                      (o) => (o.id || "") === props.item.index
                    );
                    documentService.GotoPage(
                      DataCache.docuViewareID(),
                      tocItem?.startPage || 0
                    );
                  }}
                >
                  {props.item.data.page}
                </div>
              </div>
              {props.children}
            </li>
          );
        }}
      >
        <Tree treeId="tree-1" rootItem="root" ref={tree} />
      </ControlledTreeEnvironment>
      {IsShowContextMenu && (
        <TocContextMenu
          hideMenu={() => {
            SetIsShowContextMenu();
          }}
          targetPoint={targetPoint}
          OnAddBlankPage={SetIsShowAddBlankPageModal}
        />
      )}
      {IsShowAddBlankPageModal && (
        <AddBlankPageModal
          onClose={SetIsShowAddBlankPageModal}
          selectedTabIds={selectedItems as string[]}
          isShow={IsShowAddBlankPageModal}
        ></AddBlankPageModal>
      )}
    </StyledTreeTOC>
  );
};
export default TreeToc;
