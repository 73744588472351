import styled from 'styled-components';
export const StyleList = styled.div`
  height: 100%;
  .ms-DetailsList {
    overflow: hidden;
  }
  .min-width {
    min-width: 470px;
  }
  .bookmark-list {
    overflow: auto;
    height: calc(100% - 48px);
    margin-top: 10px;
  }
`;
export const TreeView = styled.div`
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .treeview {
    margin-right: 8px;
  }
  li {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAA3gCAYAAABrtoe4AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuNWWFMmUAAASPSURBVHhe7dFBCsMwDADBPN0/V1noNc0hmKQwC0K+eUDH1tZa830+FMGbBFd7X/t/uIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAgIioCgCAiKgKAICIqAoAj+RjAzt+ZnrkBQBARFQFAEBEVAUAQERUBQBARFQFAEBEVAUAQERUBQBARFQFAEBEVAUAQERUBQBAT1HsH5XvMBvaeV2CWcZKwAAAAASUVORK5CYII=)
      0 0 no-repeat;
  }
  .treeview li {
    margin: 0;
    padding: 0px 0px 2px 16px;
    cursor: pointer;
  }
  .treeview li:last-child {
    background-position: 0 -3542px;
  }
  .selected {
    div {
      background: #a8d3f3;
    }
    i {
      color: #ffa824;
    }
  }
  i {
    font-size: 16px;
  }
  .lable {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
  }
  .lable:hover {
    background: #a8d3f3;
  }
  .collapse {
    display: none;
  }
  .icon-expand-collapse {
    float: left;
  }
  .text {
    padding: 0 4px 0 2px;
  }
`;
