import { atom } from 'recoil';
import * as ApiV2 from '../ApiV2';
interface ITagNode {
  value: string;
  color: string;
  label: string;
  children: any;
  categoryId?: string;
}

interface ITagState {
  tagData: ApiV2.TagData | null;
  selectedGlobalTagName: ITagNode | null;
  displayList: boolean;
  idTagSelected: string;
}
const tagState = atom({
  key: 'tagState',
  default: {
    tagData: null,
    selectedGlobalTagName: null,
    displayList: false,
    idTagSelected: '',
  } as ITagState,
});

export { tagState };
export type { ITagNode, ITagState };
