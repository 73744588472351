import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import { commentState, dialogState, pageSelectedState } from "../recoil";
import { useLang } from "../i18n/useLang";
const CommentApi = new Api.CommentApi();
export const commentService = () => {
  const { tr } = useLang();
  const [, setDialogState] = useRecoilState(dialogState);
  const [commentCurrentState, SetCommentState] = useRecoilState(commentState);
  const [pageState] = useRecoilState(pageSelectedState);
  const getCommentList = async (callBack?: any) => {
    if (pageState.currentPage === 0) {
      SetCommentState((cur) => {
        return { ...cur, allComments: [] };
      });
      return;
    }
    const CommentTextObjs = (
      await CommentApi.apiLegalxtractGetListCommentGet(
        commentCurrentState.OrderBy as Api.CommentOrderByEnum
      )
    ).data;
    if (CommentTextObjs) {
      SetCommentState((cur) => {
        return { ...cur, allComments: CommentTextObjs, ShowCommentModal: "" };
      });
    } else {
      SetCommentState((cur) => {
        return { ...cur, allComments: [], ShowCommentModal: "" };
      });
    }
    if (callBack) {
      callBack();
    }
  };
  const deleteComment = (comment: Api.CommentObjDisplay | null): void => {
    CommentApi.apiLegalxtractDeleteCommentDelete(
      comment?.id,
      comment?.pageNo
    ).then(() => {
      const comments = commentCurrentState.allComments.filter(
        (d) => d.commentObjDisplay?.id != comment?.id
      );
      SetCommentState({
        ...commentCurrentState,
        ShowCommentModal: "",
        allComments: comments,
      });
    });
  };
  const editComment = (comment: Api.CommentObjDisplay | null): void => {
    const newComment: Api.CommentParam = {
      id: comment?.id,
      author: comment?.author,
      comment: comment?.comment,
      parentId: comment?.parentId,
      status: comment?.status,
      type: comment?.commentTypeEnum,
    };
    if (!(comment?.comment || "").trim()) {
      setDialogState({
        titleDialog: "",
        MsgDialog: tr("pleaseSetComment"),
        CallBackConfirmDialog: null,
        Type: "AlertDialog",
      });
      return;
    }
    CommentApi.apiLegalxtractEditCommentPost(comment?.pageNo, newComment).then(
      (res) => {
        const o = res.data;
        if (o) {
          const stateComent = JSON.parse(JSON.stringify(commentCurrentState));
          const cm = stateComent.allComments.find(
            (d: any) => d.commentObjDisplay?.id == o.commentObjDisplay?.id
          );
          if (cm) {
            cm.commentObjDisplay = o.commentObjDisplay;
            cm.rectangleFs = o.rectangleFs;
            SetCommentState({ ...stateComent, ShowCommentModal: "" });
          }
        }
      }
    );
  };
  return { editComment, deleteComment, getCommentList };
};
