import { useRecoilState } from "recoil";
import * as ApiV2 from "../ApiV2";
import { DocuviewareService } from "../ApiV2/docuvieware.api";
import undoManager from "../command/undo-manager";
import { TabIndex } from "../components/left-sidebar/left-sidebar-container";
import { DataCache, LocalStorageKey } from "../config/constant";
import { useLang } from "../i18n/useLang";
import { SaveMode, SaveType } from "../modal/SaveFileModal";
import {
  docuviewareMainState,
  leftSidebarState,
  modalContainerState,
  mouseState,
  pageSelectedState,
  replaceState,
  searchState,
  tabState,
  thumbnailState,
  topmenuStripState,
  trackingState,
} from "./../recoil";
import { dialogState } from "./../recoil/dialogState";
import { DocuVieware, zoomModeState } from "./../recoil/docuviewareState";
import { TOCState } from "./../recoil/tocState";
import { ocrSocketSignalRSService } from "./OcrSocketSignalRSService";
import { documentService } from "./document";
import { useNavigate } from "react-router-dom";
import { CacheApp } from "../App";
const OpeningApi = new ApiV2.OpeningApi();
const DocuViewareApi = new ApiV2.DocuViewareApi();
const CheckDuplicateApi = new ApiV2.CheckDuplicateApi();
export const openFileService = () => {
  const [docuviewareMain, setDocuviewareMainState] =
    useRecoilState(docuviewareMainState);
  const [, setZoomModeState] = useRecoilState(zoomModeState);
  const [tabCurrentState, setTabState] = useRecoilState(tabState);
  const [, setThumbnailState] = useRecoilState(thumbnailState);
  const [, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const [topmenuStrip, setTopmenuStripState] =
    useRecoilState(topmenuStripState);
  const [, setSearchState] = useRecoilState(searchState);
  const [, setReplaceState] = useRecoilState(replaceState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const { ocrDocument } = ocrSocketSignalRSService();
  const [, setPageState] = useRecoilState(pageSelectedState);
  const [, setTOCState] = useRecoilState(TOCState);
  const { tr } = useLang();
  const [mouseModeState, setMouseMode] = useRecoilState(mouseState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const navigate = useNavigate();
  const AddFiles = async (
    fileList: File[],
    checkexist: boolean
  ): Promise<string> => {
    setThumbnailState((cur) => {
      return { ...cur, fromPage: 1, toPage: 5, selectPages: [] };
    });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    const { data }: { data: ApiV2.DocuviewareInitApiResult } =
      await DocuviewareService.AppendFiles(fileList, checkexist);
    if (data.message && data.message?.indexOf("FileExist") >= 0) {
      const fileExists = checkFileExists(fileList);
      if (fileExists.length > 0) {
        setDialogState({
          ...dialog,
          MsgDialog: tr("theFileIsAlreadyOpenDoYouWantToContinueOpeningTheFile")
            .toString()
            .replace("{0}", data.message.replaceAll("FileExist:", "") || ""),
          CallBackConfirmDialog: {
            action: async () => {
              const data = await AddFiles(fileExists, false);
              if (data) {
                setDialogState({
                  ...dialog,
                  MsgDialog: data,
                  Type: "AlertDialog",
                });
                return data;
              }
            },
            param: null,
          },
          Type: "ConfirmationDialog",
        });
      }
    }
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
    handleAppendFiles(data);
    if (data.message && data.message.indexOf("filesAlreadyOpens") >= 0) {
      const strMessage = [];
      const errmsg = JSON.parse(data.message);
      if (errmsg.filesAlreadyOpens.length > 0)
        strMessage.push(
          `${tr("theFileIsAlreadyOpen")}:<br>${errmsg.filesAlreadyOpens.join(
            "<br>"
          )}`
        );

      if (errmsg.invalidPasswordXtract.length > 0)
        strMessage.push(
          `${tr(
            "youCannotOpenThisFileDueToLicenseRestrictions"
          )}:<br>${errmsg.invalidPasswordXtract.join("<br>")}`
        );

      if (errmsg.tinyFiles.length > 0)
        strMessage.push(
          `${tr(
            "unableToImportAllImagesDueToMissingSizeInformation"
          )}<br>${errmsg.tinyFiles.join("<br>")}`
        );

      if (errmsg.errorFiles.length > 0)
        strMessage.push(
          `${tr("errorDuringFileLoading")}<br>${errmsg.errorFiles.join("<br>")}`
        );
      return strMessage.join("<br>");
    }
    return "";
  };
  const resetData = () => {
    setThumbnailState((cur) => {
      return { ...cur, fromPage: 1, toPage: 5, selectPages: [] };
    });
    setReplaceState((cur) => {
      return { ...cur, replaceWords: [] };
    });
    setSearchState((cur) => {
      return { ...cur, searchResult: {} };
    });
    setDocuviewareMainState({
      DocuviewareInit: null,
      openPreview: false,
      docuViewarePreviewID: null,
      previewSessionID: null,
      previewHtmlString: null,
      isRefeshDocument: false,
    } as DocuVieware);
  };
  const handleAppendFiles = async (
    res: ApiV2.DocuviewareInitApiResult | undefined
  ) => {
    const data = res?.data;
    const tabNeedOCR = data?.tabDetails?.filter(
      (o) => (o.numberPageNeedOCR || 0) > 0
    );
    if (
      CacheApp.lxSetting &&
      CacheApp.lxSetting.ocrWhenOpeningDocuments &&
      (tabNeedOCR || []).length > 0
    ) {
      setTimeout(() => {
        ocrDocument("OCRAllTabs", null, null, data?.tabDetails || [], true);
      }, 200);
    }
    if (
      !res?.message &&
      CacheApp.lxSetting &&
      CacheApp.lxSetting.checkDuplicate &&
      !CacheApp.lxSetting.ocrWhenOpeningDocuments
    ) {
      CheckDuplicate();
    }

    const tabs: ApiV2.TabDetail[] = data?.tabDetails || [];
    setDocuviewareMainState({
      ...docuviewareMain,
      DocuviewareInit: data || {},
      isRefeshDocument: true,
    });
    setLeftSidebarState((cur) => {
      return { ...cur, refreshTabActive: { IsRefresh: true, TabIndex: -1 } };
    });
    setMouseMode({ mouseMode: 2 });
    if (tabs) {
      DocuViewareAPI.SetMouseMode(DataCache.docuViewareID(), 0);
    }
  };
  const OpenFiles = async (fileList: File[], callBack: any) => {
    if (
      docuviewareMain?.DocuviewareInit?.tabDetails &&
      (docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0
    ) {
      setDialogState({
        ...dialog,
        MsgDialog: tr("openNewFilesWillClearCurrentFile"),
        CallBackConfirmDialog: {
          action: () => {
            const a = localStorage.getItem(LocalStorageKey.DocumentHasChanged);
            if ((a || "").toLocaleLowerCase() == "true") {
              setDialogState({
                ...dialog,
                MsgDialog: tr("doYouWantToSaveChanges"),
                CallBackConfirmDialog: {
                  action: () => {
                    setModalContainerState({
                      ...modalContainer,
                      saveModal: {
                        ...modalContainer.saveModal,
                        isShow: true,
                        saveMode: SaveMode.Save,
                        saveType: SaveType.SaveExtract,
                        afterSave: () => {
                          OpenFileServer(fileList, callBack);
                        },
                      },
                    });
                  },
                  param: null,
                  actionNo: () => {
                    OpenFileServer(fileList, callBack);
                  },
                },
                Type: "YesNoCancel",
              });
            } else {
              OpenFileServer(fileList, callBack);
            }
          },
          param: null,
        },
        Type: "ConfirmationDialog",
      });
      return;
    }
    OpenFileServer(fileList, callBack);
  };
  const onCloseAllTab = async () => {
    await OpeningApi.apiLegalxtractCloseAllTabFileGet();
    setDocuviewareMainState({
      DocuviewareInit: { htmlString: " " },
      openPreview: false,
      docuViewarePreviewID: null,
      previewSessionID: null,
      previewHtmlString: null,
      isRefeshDocument: false,
    });
    CacheApp.Thumbnails = [];
    setReplaceState((cur) => {
      return { ...cur, replaceWords: [] as ApiV2.ReplaceObj[] };
    });
    setSearchState((cur) => {
      return { ...cur, searchResult: {} };
    });
    setTOCState((cur) => {
      return { ...cur, tocData: [] };
    });
    undoManager.clear();
    setPageState((cur) => {
      return { ...cur, currentPage: 0 };
    });
  };
  const OpenFileServer = async (fileList: File[], callBack: any) => {
    localStorage.setItem(LocalStorageKey.DocumentHasChanged, "false");
    await onCloseAllTab();
    setTopmenuStripState({
      ...topmenuStrip,
      dropdownList: {
        ...topmenuStrip.dropdownList,
        IsUploadFrontPage: false,
        FrontPageChecked: false,
        StampChecked: false,
        PageNumberingChecked: false,
      },
    });
    resetData();
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    const { data }: { data: ApiV2.DocuviewareInitApiResult } =
      await DocuviewareService.GetDocuViewareControl(fileList);
    if (data.message && data.message.indexOf("filesAlreadyOpens") >= 0) {
      const strMessage = [];
      const errmsg = JSON.parse(data.message);
      if (errmsg.filesAlreadyOpens.length > 0)
        strMessage.push(
          `${tr("theFileIsAlreadyOpen")}:<br>${errmsg.filesAlreadyOpens.join(
            "<br>"
          )}`
        );

      if (errmsg.invalidPasswordXtract.length > 0)
        strMessage.push(
          `${tr(
            "youCannotOpenThisFileDueToLicenseRestrictions"
          )}:<br>${errmsg.invalidPasswordXtract.join("<br>")}`
        );

      if (errmsg.tinyFiles.length > 0)
        strMessage.push(
          `${tr(
            "unableToImportAllImagesDueToMissingSizeInformation"
          )}<br>${errmsg.tinyFiles.join("<br>")}`
        );

      if (errmsg.errorFiles.length > 0)
        strMessage.push(
          `${tr("errorDuringFileLoading")}<br>${errmsg.errorFiles.join("<br>")}`
        );
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
      setDialogState({
        ...dialog,
        MsgDialog: strMessage.join("<br>"),
        Type: "AlertDialog",
      });
    }
    if (data.data) {
      if (data.data?.tabDetails) {
        const TOCTab = data.data?.tabDetails.find(
          (tab) => tab.tabFileType === ApiV2.TabFileType.NUMBER_3
        );
        let stateTopMenu = topmenuStrip.dropdownList;
        if (TOCTab) {
          stateTopMenu = { ...stateTopMenu, TOCChecked: true };
        } else {
          stateTopMenu = { ...stateTopMenu, TOCChecked: false };
        }
        stateTopMenu = {
          ...stateTopMenu,
          BlankPageChecked: data.data?.isBlankPage || false,
          FrontPageChecked: data.data?.isCreateFrontPage || false,
          IsUploadFrontPage: data.data?.isUploadFrontPage || false,
          PageNumberingChecked: data.data?.isIncludedPageNumber || false,
          StampChecked: data.data?.showStamp || false,
        };
        setTopmenuStripState({ ...topmenuStrip, dropdownList: stateTopMenu });
      }
      if (data.data?.tabDetails) {
        setTabState({
          ...tabCurrentState,
          selectedTab: tabCurrentState.selectedTab,
        });
      }
      const tabNeedOCR = data.data.tabDetails?.filter(
        (o) => (o.numberPageNeedOCR || 0) > 0
      );
      if (
        CacheApp.lxSetting &&
        CacheApp.lxSetting.ocrWhenOpeningDocuments &&
        (tabNeedOCR || []).length > 0
      ) {
        setTimeout(() => {
          ocrDocument(
            "OCRAllTabs",
            null,
            null,
            data.data?.tabDetails || [],
            true
          );
        }, 200);
      }
      if (
        CacheApp.lxSetting &&
        CacheApp.lxSetting.checkDuplicate &&
        (!CacheApp.lxSetting.ocrWhenOpeningDocuments ||
          tabNeedOCR?.length === 0)
      ) {
        CheckDuplicate();
      }
      setDocuviewareMainState({
        ...docuviewareMain,
        DocuviewareInit: data.data,
      });
      DocuViewareAPI.SetMouseMode(DataCache.docuViewareID(), 0);
      setZoomModeState(3);
      if (callBack) {
        callBack();
      }
    }
    setMouseMode({ mouseMode: 2 });
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: false,
        label: ``,
      },
    });
    return "";
  };
  const CheckDuplicate = async () => {
    setTrackingState((cur) => {
      return { ...cur, openingLoadingState: { isOpen: true } };
    });
    CheckDuplicateApi.apiLegalxtractCheckDuplicatePost({
      isCurrentDocument: false,
      includeHideOnExtract: false,
    }).then((data) => {
      setTrackingState((cur) => {
        return { ...cur, openingLoadingState: { isOpen: false } };
      });
      if (data.data) {
        setModalContainerState({
          ...modalContainer,
          previewFindDuplicates: true,
          CheckDuplicateResponse: data.data,
        });
      }
    });
  };
  //mở file xtract trong qua trình ocr
  const OpenXtractFileOcr = async (ocrid: string, callBack: any) => {
    localStorage.setItem(LocalStorageKey.DocumentHasChanged, "false");
    setTrackingState({
      ...tracking,
      openingLoadingState: {
        ...tracking.openingLoadingState,
        isOpen: true,
        label: ``,
      },
    });
    const res = await OpeningApi.apiLegalxtractOpenXtractOcrGet(ocrid);
    const data = res.data;
    if ((data.message || "").indexOf("password_invalid") > 0) {
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
      setDialogState({
        ...dialog,
        MsgDialog:
          tr(
            "youAreNotAllowedToOpenThisFileMayBeYouAreNotInTheSameCompanyOfThisFile"
          ) + ` [${(data.message || "").split("*")[0].trim().slice(0, -1)}]`,
        Type: "AlertDialog",
      });
      return;
    }
    if (data.message == "FileNotExists") {
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
      setDialogState({
        ...dialog,
        MsgDialog: tr("File xtract ocr not exists "),
        Type: "AlertDialog",
      });
      navigate("/");
      return;
    }
    if (!data.message && data.data) {
      if (data.data?.tabDetails) {
        const TOCTab = data.data?.tabDetails.find(
          (tab) => tab.tabFileType === ApiV2.TabFileType.NUMBER_3
        );
        let stateTopMenu = topmenuStrip.dropdownList;
        if (TOCTab) {
          stateTopMenu = { ...stateTopMenu, TOCChecked: true };
        } else {
          stateTopMenu = { ...stateTopMenu, TOCChecked: false };
        }
        stateTopMenu = {
          ...stateTopMenu,
          BlankPageChecked: data.data?.isBlankPage || false,
          FrontPageChecked: data.data?.isCreateFrontPage || false,
          IsUploadFrontPage: data.data?.isUploadFrontPage || false,
          PageNumberingChecked: data.data?.isIncludedPageNumber || false,
          StampChecked: data.data?.showStamp || false,
        };
        setTopmenuStripState({ ...topmenuStrip, dropdownList: stateTopMenu });
      }
      if (data.data?.tabDetails) {
        setTabState({
          ...tabCurrentState,
          selectedTab: tabCurrentState.selectedTab,
        });
      }
      setDocuviewareMainState({
        ...docuviewareMain,
        DocuviewareInit: data.data,
      });
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
      DocuViewareAPI.SetMouseMode(DataCache.docuViewareID(), 0);
      setZoomModeState(3);
      if (callBack) {
        callBack();
      }
    }
    return "";
  };
  const showLoadingOpenfile = async () => {
    const status = await OpeningApi.apiLegalxtractGetStatusProcessOpenPost(
      localStorage[LocalStorageKey.IdentifiedKey]
    );
    if (status.data == ApiV2.StatusOpenFile.NUMBER_1 || (status.data as any).ErrorMessage) {
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: false,
          label: ``,
        },
      });
    }
    return status;
  };
  const checkFileExists = (fileList: File[]) => {
    const listFiles: File[] = [];
    fileList.forEach((el) => {
      const item = docuviewareMain.DocuviewareInit?.tabDetails?.find(
        (o) => o.fileName == el.name.substring(0, el.name.lastIndexOf("."))
      );
      if (item) listFiles.push(el);
    });
    return listFiles;
  };
  return {
    OpenFiles,
    AddFiles,
    checkFileExists,
    CheckDuplicate,
    showLoadingOpenfile,
    OpenXtractFileOcr,
  };
};
export const DocuViewareControlService = () => {
  const [topmenuStrip, setTopmenuStripState] =
    useRecoilState(topmenuStripState);
  const [, setDocuviewareMainState] = useRecoilState(docuviewareMainState);
  const [pageState] = useRecoilState(pageSelectedState);
  const initDocuViewareControl = async () => {
    await DocuViewareApi.apiLegalxtractGetDocuviewareControlGet().then(
      (res) => {
        const data = res.data;
        if (
          data &&
          data.tabDetails != undefined &&
          data.tabDetails?.length > 0
        ) {
          setDocuviewareMainState((cur) => {
            return {
              ...cur,
              DocuviewareInit: data,
              isRefeshDocument: true,
            };
          });
          if (data.tabDetails) {
            const TOCTab = data.tabDetails.find(
              (tab) => tab.tabFileType === ApiV2.TabFileType.NUMBER_3
            );
            let stateTopMenu = topmenuStrip.dropdownList;
            if (TOCTab) {
              stateTopMenu = { ...stateTopMenu, TOCChecked: true };
            } else {
              stateTopMenu = { ...stateTopMenu, TOCChecked: false };
            }
            if (data.isCreateFrontPage) {
              stateTopMenu = {
                ...stateTopMenu,
                FrontPageChecked: true,
                IsUploadFrontPage: false,
              };
            }
            if (data.isUploadFrontPage) {
              stateTopMenu = {
                ...stateTopMenu,
                FrontPageChecked: false,
                IsUploadFrontPage: true,
              };
            }
            stateTopMenu = {
              ...stateTopMenu,
              BlankPageChecked: data?.isBlankPage || false,
            };
            setTopmenuStripState({
              ...topmenuStrip,
              dropdownList: stateTopMenu,
            });
          }
          setTimeout(() => {
            documentService.RefreshPage(async (res: any) => {
              if (res) {
                const pageDetail = JSON.parse(res) as ApiV2.WordInPage;
                if (CacheApp.DocuviewareMain) {
                  CacheApp.pageDetails = [pageDetail];
                }
              }
              if (data.tabDetails) {
                DocuViewareAPI.SetMouseMode(DataCache.docuViewareID(), 0);
              }
              DocuViewareAPI.UpdateLayout(DataCache.docuViewareID());
            }, DataCache.docuViewareID());
          }, 1000);
        } else {
          setDocuviewareMainState((cur) => {
            return {
              ...cur,
              DocuviewareInit: data,
            };
          });
        }
      }
    );
  };

  return { initDocuViewareControl };
};
