import axios from "axios";
import Cookies from "js-cookie";
import { Constants } from "./constant";
import { Utility } from "./utility";
import { userService } from "../service/userService";
export const axiosInstance = axios.create({
  baseURL: `${Utility.baseUrl()}`,
});
const { getUser } = userService();
const responseHandler = (response: any) => {
  return response;
};
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => responseHandler(response),
  (err) => {
    console.log(err);
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
    return err;
  }
);
const requestHandler = (request: any) => {
  // @ts-ignore
  request.headers["TimezoneOffset"] = new Date().getTimezoneOffset() / -1;
  request.headers["IdentifiedKey"] = localStorage.IdentifiedKey?.toString();
  const TokenNovaLegalXtract = Cookies.get(Constants.TokenThirdParty);
  // @ts-ignore
  request.headers["TokenThirdParty"] = TokenNovaLegalXtract || "";
  // @ts-ignore
  const user = getUser();
  if (user) {
    request.headers["Authorization"] = "Bearer " + user.token;
    request.headers["TokenLegalManager"] =
      user.userDataLogin?.result?.access_token;
    request.headers["LicenseKey"] =
      user.userDataLogin?.result?.user_info?.licenseKey;
    // request.headers["UserInfo"] = encodeURI(
    //   user.userDataLogin?.result?.user_info?.fullName || ""
    // );
    request.headers["UserInfo"] = encodeURI(
      JSON.stringify({
        email: user.userDataLogin?.result?.user_info?.email || "",
        userName: user.userDataLogin?.result?.user_info?.fullName || "",
      })
    );
  }
  return request;
};
