import styled from "styled-components";
export const StyleList = styled.div`
    height:100%;
        .ms-DetailsList{
            overflow: unset;
        }
    .min-width{
        min-width:470px;
    }
    .ms-Dropdown-container{
        flex:1;
        min-width: 140px;
    }
    .log-list{
        overflow: auto;
        height: calc(100% - 104px);
        .ms-List .ms-DetailsRow-cell{
            padding-right:0;
        }
        .ms-List .ms-DetailsRow-fields .ms-DetailsRow-cell:last-child {
            padding-right: 8px !important;
          }
    }
    .ms-GroupSpacer{
        display:none!important;
    }
    }
`;
