import React, { useState } from 'react';
import { atom } from 'recoil';

interface LoadingState {
  isOpen: boolean;
  label?: string;
}
interface TrackingState {
  openingLoadingState: LoadingState;
  searchLoadingState: LoadingState;
  showMSGExpireCookie: boolean;
}
const trackingState = atom({
  key: 'trackingState',
  default: {
    openingLoadingState: { isOpen: false, label: '' } as LoadingState,
    searchLoadingState: { isOpen: false, label: '' } as LoadingState,
    showMSGExpireCookie: false,
  },
});
export { trackingState };
