import {
  Checkbox,
  CheckboxVisibility,
  ContextualMenu,
  DetailsList,
  DirectionalHint,
  IColumn,
  IContextualMenuItem,
  MarqueeSelection,
  PrimaryButton,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { Icon } from "@fluentui/react/lib/Icon";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import * as Api from "../../../ApiV2";
import { StyledActionIconContainer } from "../../../assets/style/left-sidebar/leftLayout";
import { StyleDetailList } from "../../../assets/style/left-sidebar/watchdog-panel";
import LoaderSearch from "../../../control/loading/loading-search";
import { useLang } from "../../../i18n/useLang";
import {
  WatchDogState,
  dialogState,
  leftSidebarState,
  searchState,
} from "../../../recoil";
import { WatchDogService } from "../../../service";
import { TabIndex } from "../left-sidebar-container";

export default () => {
  const { tr, t } = useLang();
  const [, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const [, setSearchState] = useRecoilState(searchState);
  const [WatchDogCurrentState, setWatchdogState] =
    useRecoilState(WatchDogState);
  const {
    WatchDogSelectAllRow,
    WatchDogDeSelectAllRow,
    WatchDogDeSelect,
    WatchDogSelect,
  } = WatchDogService();
  const [diaog, setDialogState] = useRecoilState(dialogState);
  const [IsShowContextMenu, { toggle: SetIsShowContextMenu }] =
    useBoolean(false);
  const [targetPoint, SetTargetPoint] = useState<any>();
  const [selection] = useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        const items = selection.getSelection();
        if (!items) return;
        const selectedItems = items.map((o: any) => {
          return o as Api.WatchDogObj;
        });
        setWatchdogState((curr) => {
          return { ...curr, watchdogItems: selectedItems };
        });
      },
    })
  );
  let cols = [
    {
      key: "word",
      name: tr("word"),
      fieldName: "Word",
      minWidth: 40,
      isRowHeader: true,
      isResizable: true,
      isPadded: true,
      className: "colword",
      styles: {
        root: {
          margin: 0,
          textAlign: "left",
        },
      },
      onRenderHeader: () => {
        return <span title={tr("word")}>{tr("word")}</span>;
      },
      onRender: (item: Api.WatchDogObj) => {
        return (
          <div
            style={{
              backgroundColor:
                (item?.selectedCount || 0) < (item?.totalCount || 0)
                  ? "#ffddd1"
                  : "transparent",
              width: "100%",
              height: "100%",
              alignItems: "center",
              display: "flex",
              padding: "0 10px",
            }}
            title={item.word || ""}
          >
            {item.word}
          </div>
        );
      },
    },
    {
      key: "selectedCount",
      name: tr("selected"),
      fieldName: "Selected",
      className: "colword",
      minWidth: 45,
      data: "string",
      onRenderHeader: () => {
        return <span title={tr("selected")}>{tr("selected")}</span>;
      },
      onRender: (item: Api.WatchDogObj) => {
        return (
          <>
            <div
              style={{
                height: "100%",
                width: `${item.replacePercent}%`,
                background: "rgb(153 199 255)",
                position: "absolute",
                textAlign: "right",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                right: 6,
              }}
            >
              {item.selectedCount}
            </div>
          </>
        );
      },
    },
    {
      key: "totalCount",
      name: tr("count"),
      fieldName: "Count",
      minWidth: 35,
      data: "number",
      onRenderHeader: () => {
        return <span title={tr("count")}>{tr("count")}</span>;
      },
      onRender: (item: Api.WatchDogObj) => {
        return (
          <div
            style={{
              textAlign: "right",
              width: "100%",
            }}
          >
            {item.totalCount || 0}
          </div>
        );
      },
    },
    {
      key: "Search",
      name: "",
      fieldName: "Search",
      minWidth: 20,
      onRender: (item: Api.WatchDogObj) => {
        return (
          <TooltipHost
            content={tr("find")}
            directionalHint={DirectionalHint.topCenter}
          >
            <StyledActionIconContainer onClick={() => onSearch(item)}>
              <Icon
                iconName="Search"
                style={{ cursor: "pointer", fontSize: "14px" }}
              />
            </StyledActionIconContainer>
          </TooltipHost>
        );
      },
    },
  ] as IColumn[];
  const [columns, setColumns] = useState<IColumn[]>(cols);
  useEffect(() => {
    setColumns([...cols]);
  }, [t]);
  useEffect(() => {
    setColumns([...cols]);
  }, [
    WatchDogCurrentState.currentStates.matchCase,
    WatchDogCurrentState.currentStates.wholeWord,
  ]);

  const _onColumnClick = (
    listItem: any,
    event: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined
  ): void => {
    if (!column) return;
    let isSortedDescending = column.isSortedDescending;
    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    // Sort the items.
    const key1 = `${column.key} ${isSortedDescending ? "desc" : "asc"}`;
    setWatchdogState({
      ...WatchDogCurrentState,
      currentStates: { ...WatchDogCurrentState.currentStates, orderBy: key1 },
    });
    const arrcolumn = cols.map((item) => {
      if (item) {
        item.isSorted = item.key === column.key;
        if (item.isSorted) {
          item.isSortedDescending = isSortedDescending;
        }
      }
      return item;
    });
    cols = arrcolumn;
    // Reset the items and columns to match the state.
    setColumns(arrcolumn);
  };

  const onSearch = (item: Api.WatchDogObj) => {
    let paramwholeWord = WatchDogCurrentState.currentStates.wholeWord;
    let matchCase = WatchDogCurrentState.currentStates.matchCase;
    if (item.searchTypePath?.indexOf("2") == 0) {
      paramwholeWord = true;
      matchCase = true;
    }
    setSearchState((cur) => {
      return {
        ...cur,
        searchingKeywordParams: {
          ...cur.searchingKeywordParams,
          keyword: item.word,
          wholeWord: paramwholeWord,
          matchCase: matchCase,
          searchList: false,
          wildCard: false,
          highlightWholeWord: false,
          ForceSearchWhenInitialized: true,
        },
        searchResult: {},
        searchTabVm: { ...cur.searchTabVm, allPages: true, accuracy: false },
        searchType: "SearchKeyword",
      };
    });

    setTimeout(() => {
      setLeftSidebarState((cur) => {
        return { ...cur, lastPanelSelected: TabIndex.SearchTab };
      });
    }, 250);
  };
  const getGroups = (): any[] => {
    if (WatchDogCurrentState.watchdogData?.groupWatchDogs == undefined)
      return [];
    return WatchDogCurrentState.watchdogData?.groupWatchDogs
      ? WatchDogCurrentState.watchdogData?.groupWatchDogs.map((o) => {
          let text = o.name ?? "";
          if ((o.name || "").indexOf(".Template|") > -1) {
            text = o.name?.replaceAll("Template", tr("template")) || "";
            if (o.searchTypeKey) {
              text = text?.replaceAll(
                o.searchTypeKey,
                tr(o.searchTypeKey[0].toLowerCase() + o.searchTypeKey.slice(1))
              );
            }
          }
          return {
            key: o.key,
            name: text?.replaceAll("|", " | "),
            startIndex: o.startIndex,
            count: o.count,
            level: o.level,
          };
        })
      : [];
  };
  const copyData = () => {
    let table = document.getElementById("CopyTable");
    table?.remove();
    let htmlitem = ``;
    WatchDogCurrentState.watchdogItems.forEach((element: Api.WatchDogObj) => {
      htmlitem += `<tr>
      <td>${element.word}</td>
      <td>${element.selectedCount}</td>
      <td>${element.totalCount}</td>
    </tr>`;
    });
    const html = `<table id='CopyTable' style="position: absolute;width: 1px;height: 1px;font-size: 15px;">
    <tbody>
    ${htmlitem}
    </tbody>
  </table>`;
    const temp = document.createElement("div");
    temp.innerHTML = html;
    while (temp.firstChild) {
      document.body.appendChild(temp.firstChild);
    }
    table = document.getElementById("CopyTable");
    const selection = window.getSelection();
    if (table && selection) {
      const range = document.createRange();
      range.selectNode(table);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
    }
  };
  return (
    <>
      <Stack
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
        style={{ position: "relative", height: "100%" }}
      >
        <div className="header-left-panel">{tr("watchdog")}</div>

        <Stack horizontalAlign="start" horizontal tokens={{ childrenGap: 10 }}>
          <Checkbox
            label={tr("matchWholeWord")}
            checked={WatchDogCurrentState.currentStates.wholeWord}
            styles={{ text: { whiteSpace: "break-spaces!important" } }}
            onChange={(e, value: any) => {
              setWatchdogState({
                ...WatchDogCurrentState,
                currentStates: {
                  ...WatchDogCurrentState.currentStates,
                  wholeWord: value,
                },
              });
            }}
          />
          <Checkbox
            label={tr("matchCase")}
            checked={WatchDogCurrentState.currentStates.matchCase}
            styles={{ text: { whiteSpace: "break-spaces!important" } }}
            onChange={(e, value: any) => {
              setWatchdogState({
                ...WatchDogCurrentState,
                currentStates: {
                  ...WatchDogCurrentState.currentStates,
                  matchCase: value,
                },
              });
            }}
          />
          <Checkbox
            label={tr("combine")}
            checked={WatchDogCurrentState.currentStates.combine}
            styles={{ text: { whiteSpace: "break-spaces!important" } }}
            onChange={(e, value: any) => {
              setWatchdogState({
                ...WatchDogCurrentState,
                currentStates: {
                  ...WatchDogCurrentState.currentStates,
                  combine: value,
                },
              });
            }}
          />
        </Stack>
        <StyleDetailList
          // ref={ref}
          id="parent-watchdog"
          onContextMenu={(ev) => {
            ev.preventDefault();
            SetIsShowContextMenu();
            SetTargetPoint({ x: ev.clientX, y: ev.clientY });
          }}
          onKeyDown={(event) => {
            if (event.key.toLowerCase() == "c" && event.ctrlKey) {
              copyData();
            }
          }}
        >
          <LoaderSearch
            showCancelLoader={false}
            showLoader={WatchDogCurrentState.showLoader}
            onClickCancel={() => {}}
          ></LoaderSearch>
          <MarqueeSelection selection={selection}>
            <DetailsList
              selectionMode={SelectionMode.multiple}
              selection={selection}
              items={WatchDogCurrentState.watchdogData?.watchDogs || []}
              groups={getGroups()}
              columns={columns}
              groupProps={{
                showEmptyGroups: true,
              }}
              onColumnHeaderClick={(ev, col) => {
                _onColumnClick(WatchDogCurrentState.watchdogData, ev, col);
              }}
              checkboxVisibility={CheckboxVisibility.hidden}
              compact={true}
            />
          </MarqueeSelection>
        </StyleDetailList>
        <Stack
          horizontal
          horizontalAlign="end"
          tokens={{ childrenGap: 5 }}
          style={{ marginBottom: 10 }}
        >
          <PrimaryButton
            style={{ width: "50%" }}
            text={tr("selectAll")}
            type={"button"}
            onClick={() => {
              if (
                WatchDogCurrentState.watchdogData?.watchDogs &&
                WatchDogCurrentState.watchdogData?.watchDogs.length > 0
              )
                setDialogState({
                  titleDialog: "",
                  MsgDialog: tr("youAreAboutToSelectAllSelections"),
                  CallBackConfirmDialog: {
                    action: () => {
                      WatchDogSelectAllRow(
                        WatchDogCurrentState.watchdogData?.watchDogs || []
                      );
                    },
                    param: null,
                  },

                  Type: "ConfirmationDialog",
                });
            }}
          />
          <PrimaryButton
            text={tr("deselectAll")}
            style={{ width: "50%" }}
            type={"button"}
            onClick={() => {
              if (
                WatchDogCurrentState.watchdogData?.watchDogs &&
                WatchDogCurrentState.watchdogData?.watchDogs.length > 0
              )
                setDialogState({
                  titleDialog: "",
                  MsgDialog: tr("youAreAboutToDeselectAllSelections"),
                  CallBackConfirmDialog: {
                    action: () => {
                      WatchDogDeSelectAllRow(
                        WatchDogCurrentState.watchdogData?.watchDogs || []
                      );
                    },
                    param: null,
                  },
                  Type: "ConfirmationDialog",
                });
            }}
          />
        </Stack>
      </Stack>
      {IsShowContextMenu && (
        <MenuContext
          hideMenu={() => {
            SetIsShowContextMenu();
          }}
          WatchDogSelectAllRowCommand={WatchDogSelectAllRow}
          WatchDogUnSelectAllRowCommand={WatchDogDeSelectAllRow}
          targetPoint={targetPoint}
        />
      )}
    </>
  );
};
const MenuContext = ({
  targetPoint,
  hideMenu,
  WatchDogSelectAllRowCommand,
  WatchDogUnSelectAllRowCommand,
}: {
  targetPoint: any;
  hideMenu: () => void;
  WatchDogSelectAllRowCommand: (item: Api.WatchDogObj[]) => void;
  WatchDogUnSelectAllRowCommand: (item: Api.WatchDogObj[]) => void;
}) => {
  const [WatchDogCurrentState, setWatchdogState] =
    useRecoilState(WatchDogState);
  const { tr } = useLang();
  useEffect(() => {
    document.addEventListener("mousedown", hideMenu);
    return () => {
      document.removeEventListener("mousedown", hideMenu);
    };
  }, []);
  const items = [
    {
      key: "selectAll",
      name: tr("selectAll"),
      onClick: (ev) => {
        WatchDogSelectAllRowCommand(WatchDogCurrentState.watchdogItems);
        hideMenu();
      },
    },
    {
      key: "deselectAll",
      name: tr("deselectAll"),
      onClick: (ev) => {
        WatchDogUnSelectAllRowCommand(WatchDogCurrentState.watchdogItems);
        hideMenu();
      },
    },
  ] as IContextualMenuItem[];
  return <ContextualMenu target={targetPoint} items={items} />;
};
