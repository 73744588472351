import { atom } from "recoil";
export enum MouseMode {
  Select = 2,
  Mask = 3,
  Tag = 4,
  Line = -1,
}
interface IMouseState {
  mouseMode: number;
}
const mouseState = atom<IMouseState>({
  key: "mouseState",
  default: { mouseMode: 2 },
});
export { mouseState };
export type { IMouseState };
