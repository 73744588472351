import { CommandBarButton } from '@fluentui/react';
import styled from 'styled-components';
export const ModalContent = styled.div`
  padding: 24px;
`;
export const StyledPreviewOriginalBox = styled.div`
  height: 210px;
  background: #f1f1f1;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`;
//modal tag
export const TagSettingButton = styled(CommandBarButton)`
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 200;
`;
export const TreeStyle = styled.div`
  i {
    font-size: 11px;
    margin-right: 10px;
  }
`;
// custom search
export const StyledExample = styled.div`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;
