import { atom } from "recoil";
interface IOcrSocketSignalRState {
  OCRTotalPages: number;
  OCRingPages: number;
}
export interface IOcrState {
  showModalProgress: boolean;
  ocrProgress: number;
  cancelOCR: boolean;
  isOpenFile?: boolean;
  OcrProessing: boolean;
  type: "OCRing" | "PreparingPage" | "" | "ReadingFile" | "DownloadFile";
  PreparingPage: IOcrSocketSignalRState;
  OCRing: IOcrSocketSignalRState;
  ReadingFile: IOcrSocketSignalRState;
  DownloadFile: {
    downloadedBytes: string,
    totalBytes: string,
  };
}
export const ocrSocketSignalRState = atom<IOcrState>({
  key: "ocrSocketSignalRState",
  default: {
    ocrProgress: 0,
    showModalProgress: false,
    type: "",
    cancelOCR: false,
    isOpenFile: false,
    OcrProessing: false,
    PreparingPage: {
      OCRTotalPages: 0,
      OCRingPages: 0,
    },
    OCRing: {
      OCRTotalPages: 0,
      OCRingPages: 0,
    },
    ReadingFile: {
      OCRTotalPages: 0,
      OCRingPages: 0,
    },
    DownloadFile: {
      downloadedBytes: "0",
      totalBytes: "0",
    },
  } as IOcrState,
});
