import React, { useEffect, useContext, CSSProperties } from "react";
import { PrimaryButton } from "@fluentui/react";
import { useLang } from "../../i18n/useLang";
import {
  StyledSearchSpinner,
  StyledSearchLoader,
} from "../../assets/style/control/loadding";

const LoaderSearch = ({
  showCancelLoader,
  showLoader,
  onClickCancel,
  styte,
}: {
  showCancelLoader: boolean;
  showLoader: boolean;
  styte?: CSSProperties;
  onClickCancel: () => void;
}) => {
  const { tr } = useLang();
  return (
    <>
      {showLoader && (
        <>
          <StyledSearchLoader className="loadding">
            <StyledSearchSpinner style={styte || {}} className="spinner" />
            {showCancelLoader && (
              <PrimaryButton
                onClick={() => {
                  onClickCancel();
                }}
              >
                {tr("cancel")}
              </PrimaryButton>
            )}
          </StyledSearchLoader>
        </>
      )}
    </>
  );
};

export default LoaderSearch;
