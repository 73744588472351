import { AddSquare20Regular, Bookmark16Regular, SubtractSquare20Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import * as Api from '../../../ApiV2';
import { TreeView } from '../../../assets/style/left-sidebar/bookmark-panel';

export interface TreeNode {
  id?: string;
  parentId?: string | null;
  position?: Api.PointF;
  title?: string | null;
  isExpand: boolean;
  selected: boolean;
  childNodes: TreeNode[];
}
export interface TreeBookmarkProps {
  Datas: TreeNode[];
  onSelected: (item: TreeNode) => void;
  onContextMenu: (e: any) => void;
  selectedItem: TreeNode | null;
}

const TreeBookmark = ({ Datas, onSelected, onContextMenu, selectedItem }: TreeBookmarkProps) => {
  const [treeNodes, setTreeNodes] = useState<TreeNode[]>([]);
  const [ItemSelected, setSelectedItem] = useState<TreeNode | null>(null);
  useEffect(() => {
    setSelectedItem(selectedItem);
  }, []);
  useEffect(() => {
    setTreeNodes(JSON.parse(JSON.stringify(Datas)));
  }, [Datas]);
  const BuildTree = (nodes: TreeNode[], IsExpand: boolean): JSX.Element => {
    const arrTreeView: JSX.Element[] = [];
    if (nodes && nodes.length > 0) {
      nodes.forEach((element) => {
        const a = (
          <li key={element.id}>
            {element.childNodes && element.childNodes.length > 0 && (
              <div
                className="icon-expand-collapse"
                onClick={() => {
                  element.isExpand = !element.isExpand;
                  setSelectedItem(element);
                  setTreeNodes(JSON.parse(JSON.stringify(treeNodes)));
                  onSelected(element);
                }}
              >
                {(element.isExpand && <AddSquare20Regular style={{ width: 16 }}></AddSquare20Regular>) || (
                  <SubtractSquare20Regular style={{ width: 16 }}></SubtractSquare20Regular>
                )}
              </div>
            )}
            <div
              onClick={() => {
                setSelectedItem(element);
                onSelected(element);
              }}
              onContextMenu={() => {
                setSelectedItem(element);
                onSelected(element);
              }}
              className={`lable ${element.id == selectedItem?.id ? 'selected' : ''}`}
            >
              <Bookmark16Regular style={{ width: 18 }}></Bookmark16Regular>
              <div className="text">{element.title}</div>
            </div>
            {BuildTree(element.childNodes, element.isExpand)}
          </li>
        );
        arrTreeView.push(a);
      });
    }
    return (
      <ul className={`treeview ${IsExpand ? '' : 'collapse'}`}>
        {arrTreeView.map((item) => {
          return item;
        })}
      </ul>
    );
  };
  return (
    <TreeView
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onContextMenu(e);
      }}
    >
      {BuildTree(treeNodes, true)}
    </TreeView>
  );
};
export default TreeBookmark;
