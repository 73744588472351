import * as Api from "../ApiV2";
import { LocalStorageKey } from "../config/constant";
import { annotationService } from "../service/annotationService";
import undoManager from "./undo-manager";
import { UndoHightLight } from "./undoHightLight";
import { CacheApp } from "../App";
const TrackingApi = new Api.TrackingApi();
const HighlightSearchCommand = (
  docuViewareID: string | null | undefined,
  highlightSearchResultsParam: Api.HighlightSearchResultsParam,
  callback: any
) => {
  let ParamUndo = "";
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const HighLight = () => {
    if (highlightSearchResultsParam) {
      const dataRequest = {
        Data: JSON.stringify(highlightSearchResultsParam),
        Type: "HighLightSearchResultsCommand",
      };
      annotationService.AddAnnotations(
        docuViewareID,
        dataRequest,
        async (res: any) => {
          const data = JSON.parse(res);
          ParamUndo = JSON.stringify(data.item2);
          CacheApp.pageDetails = [
            (
              await TrackingApi.apiLegalxtractGetWordByPageNoGet(
                CacheApp.PageState?.currentPage || 0
              )
            ).data,
          ];
          if (callback) {
            callback(data.item1);
          }
        }
      );
    }
  };
  const UnHighLight = () => {
    if (highlightSearchResultsParam) {
      const dataRequest = {
        Data: JSON.stringify(highlightSearchResultsParam),
        Type: "UnHighLightSearchResultsCommand",
      };
    
      annotationService.AddAnnotations(
        docuViewareID,
        dataRequest,
        async (res: any) => {
          const data = JSON.parse(res);
          ParamUndo = JSON.stringify(data.item2);
          CacheApp.pageDetails = [
            (
              await TrackingApi.apiLegalxtractGetWordByPageNoGet(
                CacheApp.PageState?.currentPage || 0
              )
            ).data,
          ];
          if (callback) {
            callback(data.item1);
          }
        }
      );
    }
  };
  if (highlightSearchResultsParam.isHighlight) {
    HighLight();
    undoManager.add({
      undo: function () {
        UndoHightLight(docuViewareID, ParamUndo, callback);
      },
      redo: function () {
        HighLight();
      },
    });
  } else {
    UnHighLight();
    undoManager.add({
      undo: function () {
        UndoHightLight(docuViewareID, ParamUndo, callback);
      },
      redo: function () {
        UnHighLight();
      },
    });
  }
};

export default HighlightSearchCommand;
