import { useRecoilState } from "recoil";
import * as Api from "../ApiV2";
import PageNumberingCommand from "../command/pageNumbering-command";
import { DataCache, LocalStorageKey } from "../config/constant";
import {
  docuviewareMainState,
  leftSidebarState,
  tabState,
  topmenuStripState,
} from "../recoil";
import { documentService } from "./document";
import { TabIndex } from "../components/left-sidebar/left-sidebar-container";
import { CacheApp } from "../App";
const StampApi = new Api.StampApi();
const TocApi = new Api.TocApi();
const TrackingApi = new Api.TrackingApi();

const topMenuStripService = () => {
  const [topmenuStrip, setTopmenuStripState] =
    useRecoilState(topmenuStripState);
  const [docuviewareMain, setDocuviewareMainState] =
    useRecoilState(docuviewareMainState);
  const [tab, setTabState] = useRecoilState(tabState);
  const [leftSidebar, setLeftSidebarState] = useRecoilState(leftSidebarState);
  const SaveStamp = async (
    param: Api.TabStampWeb & { tocIndexPattern?: string }
  ) => {
    localStorage.setItem(LocalStorageKey.StampInfo, JSON.stringify(param));
    if (param.showStamp) {
      await StampApi.apiLegalxtractApplyStampAllPagePost(
        param.showFromTOC,
        param.automaticCount,
        param.tocIndexPattern,
        {
          ...param,
          location: param.location,
          logoBase64: param.logoBase64?.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          ),
        } as Api.TabStamp
      );
    }
    setTopmenuStripState({
      ...topmenuStrip,
      dropdownList: {
        ...topmenuStrip.dropdownList,
        StampChecked: param.showStamp || false,
      },
    });
    CacheApp.stampData = param;
    TrackingApi.apiLegalxtractGetTabDetailsGet().then(async (res) => {
      setDocuviewareMainState((current) => {
        return {
          ...current,
          DocuviewareInit: {
            ...current.DocuviewareInit,
            tabDetails: res.data,
            tabStampWeb: { ...param },
          },
        };
      });
      setTabState({
        ...tab,
        selectedTab:
          res.data.find((o) => o.uniqueId == tab.selectedTab?.uniqueId) || null,
      });

      if (docuviewareMain.DocuviewareInit?.isTOC) {
        await TocApi.apiLegalxtractDrawTocContentGet();
      }
      if (leftSidebar.lastPanelSelected == TabIndex.TOCTab) {
        setLeftSidebarState((cur) => {
          return {
            ...cur,
            refreshTabActive: { IsRefresh: true, TabIndex: -1 },
          };
        });
      }
      documentService.RefreshPage(() => {
        DocuViewareAPI.UpdateLayout(
          docuviewareMain.DocuviewareInit?.docuViewareID
        );
        DocuViewareAPI.PostCustomServerAction(
          DataCache.docuViewareID(),
          false,
          "RedrawPages",
          "",
          () => {}
        );
      }, docuviewareMain.DocuviewareInit?.docuViewareID);
    });
  };
  const SaveOneStamp = async (param: Api.TabStampWeb) => {
    await StampApi.apiLegalxtractApplyOneStampPost(tab.selectedTab?.uniqueId, {
      ...param,
      logoBase64: param.logoBase64?.replace(/^data:image\/[a-z]+;base64,/, ""),
    });
    TrackingApi.apiLegalxtractGetTabDetailsGet().then(async (res) => {
      setDocuviewareMainState((current) => {
        return {
          ...current,
          DocuviewareInit: { ...current.DocuviewareInit, tabDetails: res.data },
        };
      });
      setTabState({
        ...tab,
        selectedTab:
          res.data.find((o) => o.uniqueId == tab.selectedTab?.uniqueId) || null,
      });
      if (leftSidebar.lastPanelSelected == TabIndex.TOCTab) {
        setLeftSidebarState((cur) => {
          return {
            ...cur,
            refreshTabActive: { IsRefresh: true, TabIndex: -1 },
          };
        });
      }
      if (docuviewareMain.DocuviewareInit?.isTOC) {
        await TocApi.apiLegalxtractDrawTocContentGet();
      }
      documentService.RefreshPage(
        null,
        docuviewareMain.DocuviewareInit?.docuViewareID
      );
    });
  };
  const RemoveAllStamp = async () => {
    await StampApi.apiLegalxtractRemoveAllStampPost();
    TrackingApi.apiLegalxtractGetTabDetailsGet().then((res) => {
      setDocuviewareMainState((current) => {
        return {
          ...current,
          DocuviewareInit: { ...current.DocuviewareInit, tabDetails: res.data },
        };
      });
      setTabState({
        ...tab,
        selectedTab:
          res.data.find((o) => o.uniqueId == tab.selectedTab?.uniqueId) || null,
      });
      setTopmenuStripState({
        ...topmenuStrip,
        dropdownList: {
          ...topmenuStrip.dropdownList,
          StampChecked: false,
        },
      });
      (CacheApp.stampData = {
        ...CacheApp.stampData,
        showStamp: false,
      }),
        localStorage.setItem(
          LocalStorageKey.StampInfo,
          JSON.stringify(CacheApp.stampData)
        );
      documentService.RefreshPage(
        null,
        docuviewareMain.DocuviewareInit?.docuViewareID
      );
    });
  };
  const ProcessPageNumbering = async (param: Api.PageNumberingInfo) => {
    PageNumberingCommand(param, () => {
      setTopmenuStripState((cur) => {
        return {
          ...cur,
          dropdownList: {
            ...cur.dropdownList,
            PageNumberingChecked: param.included || false,
          },
          pageNumberingInfo: param,
        };
      });
      setLeftSidebarState((cur) => {
        return { ...cur, refreshTabActive: { IsRefresh: true, TabIndex: -1 } };
      });
      documentService.RefreshPage(
        null,
        docuviewareMain.DocuviewareInit?.docuViewareID
      );
    });
  };
  return { SaveStamp, ProcessPageNumbering, SaveOneStamp, RemoveAllStamp };
};
export { topMenuStripService };
