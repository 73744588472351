import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
import { UndoHightLight } from './undoHightLight';
import { CacheApp } from "../App";
import * as Api from '../ApiV2';
const TrackingApi = new Api.TrackingApi();
const HighlightPageCommand = (docuViewareID: string | null | undefined, param: any, callback: any) => {
  let ParamUndo = '';
  const HighlightPage = () => {
    const dataRequest = {
      Data: JSON.stringify(param),
      Type: 'HighlightPageCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
      ParamUndo = res;
      CacheApp.pageDetails = [
        (
          await TrackingApi.apiLegalxtractGetWordByPageNoGet(
            param.viewerPageNo || 0
          )
        ).data,
      ];
      if (callback) {
        callback();
      }
    });
  };
  const UnHighlightPage = () => {
    UndoHightLight(docuViewareID, ParamUndo, callback);
  };
  HighlightPage();
  undoManager.add({
    undo: function () {
      UnHighlightPage();
    },
    redo: function () {
      HighlightPage();
    },
  });
};

export default HighlightPageCommand;
