import * as Api from "../ApiV2";
import { LocalStorageKey } from "../config/constant";
export const userService = () => {
  const getUser = (): Api.LoginResponse | null => {
    const userInfoCache = localStorage.getItem(LocalStorageKey.UserInfo);
    let userinfo: Api.LoginResponse | null = null;
    if (userInfoCache) {
      userinfo = JSON.parse(userInfoCache) as Api.LoginResponse;
    }
    return userinfo;
  };
  return { getUser };
};
