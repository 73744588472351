import * as React from "react";
import { useLang } from "../i18n/useLang";
import { TextField } from "@fluentui/react/lib/TextField";
import {
  Modal,
  DefaultButton,
  PrimaryButton,
  Stack,
  IStackTokens,
} from "@fluentui/react";
import ModalHeader from "../control/modalHeader";
import * as ApiV2 from "../ApiV2";
import { saveService } from "../service/saveService";
import { generateSavingFileName } from "../utils/helpers";
import { ModalContent } from "../assets/style/modal/modal";
import { Constants, LocalStorageKey } from "../config/constant";
import {
  dialogState,
  docuviewareMainState,
  searchState,
  trackingState,
  WatchDogState,
} from "../recoil";
import { useRecoilState } from "recoil";
const ThumbnailApi = new ApiV2.ThumbnailApi();
const SavingApi = new ApiV2.SavingApi();
const stackTokens: IStackTokens = { childrenGap: 10 };
const verticalStackTokens: IStackTokens = { childrenGap: 5 };
const NovaApi = new ApiV2.NovaApi();

export enum SaveMode {
  Extract = 1,
  SavePDF = 2,
  SaveAsZip = 3,
  Save = 4,
  SaveCombine = 5,
  SaveXtractPdfLC = 6,
  SaveFileXtractLC = 7,
}
export class SaveType {
  static SaveNova = 10;
  static SaveExtract = 11;
  static PdfFileType: ApiV2.PdfFileType | undefined;
}
export interface SaveFileModalProps {
  saveType?: number;
  saveMode: SaveMode;
  isShow: boolean;
  OnClose?: () => void;
  afterSave?: () => void;
}
export const GoToNova = () => {
  NovaApi.apiLegalxtractGetReturnUrlGet(
    localStorage.getItem(LocalStorageKey.IdentifiedKey) || ""
  ).then((res) => {
    if (res) {
      window.location.href = res.data;
    } else {
      window.location.reload();
    }
  });
};
export default (props: SaveFileModalProps) => {
  const [docuviewareMain] = useRecoilState(docuviewareMainState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  const [searchCurrentState] = useRecoilState(searchState);
  const [WatchDogCurrentState] = useRecoilState(WatchDogState);
  const [, setDialogState] = useRecoilState(dialogState);
  const { tr } = useLang();
  const getFileName = (): string => {
    switch (props.saveMode) {
      case SaveMode.Extract:
        switch (props.saveType) {
          case SaveType.SaveNova:
            return generateSavingFileName(docuviewareMain, 4);
            break;
          default:
            return generateSavingFileName(docuviewareMain);
            break;
        }
        break;
      case SaveMode.SaveFileXtractLC:
      case SaveMode.Save:
        switch (props.saveType) {
          case SaveType.SaveNova:
            return generateSavingFileName(docuviewareMain, 4);
            break;
          default:
            return generateSavingFileName(docuviewareMain, 4);
            break;
        }
        break;
      case SaveMode.SaveAsZip:
        return generateSavingFileName(docuviewareMain, 3);
        break;
      case SaveMode.SavePDF:
        return generateSavingFileName(docuviewareMain, 2);
        break;
      case SaveMode.SaveCombine:
        return generateSavingFileName(docuviewareMain, 1);
      default:
        return generateSavingFileName(docuviewareMain, 1);
    }
  };
  const [name, setName] = React.useState(getFileName());
  const [show, setShow] = React.useState<boolean>();
  const onSubmit = async () => {
    if (props.saveMode != SaveMode.SaveCombine) {
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: true,
          label: ``,
        },
      });
    }

    switch (props.saveMode) {
      case SaveMode.Extract:
        switch (props.saveType) {
          case SaveType.SaveNova:
            saveService.saveRedactedNova(name, docuviewareMain).then((res) => {
              setTrackingState({
                ...tracking,
                openingLoadingState: {
                  ...tracking.openingLoadingState,
                  isOpen: false,
                  label: ``,
                },
              });
              if (res.toString() == "true") {
                GoToNova();
              } else {
                setDialogState({
                  titleDialog: "",
                  MsgDialog: tr("errorSavingFile"),
                  CallBackConfirmDialog: null,
                  Type: "AlertDialog",
                });
              }
            });
            break;
          default:
            saveService
              .onExtractToPDF(
                {
                  pdfFileType: props.saveType as ApiV2.PdfFileType,
                  fileName: name,
                  fixedSplitInPages: "",
                  highlightedSelections: false,
                },
                docuviewareMain
              )
              .then(() => {
                setTrackingState({
                  ...tracking,
                  openingLoadingState: {
                    ...tracking.openingLoadingState,
                    isOpen: false,
                    label: ``,
                  },
                });
              });
            break;
        }
        break;
      case SaveMode.Save:
        switch (props.saveType) {
          case SaveType.SaveNova:
            saveService.saveXtractNova(name, docuviewareMain).then((res) => {
              setTrackingState({
                ...tracking,
                openingLoadingState: {
                  ...tracking.openingLoadingState,
                  isOpen: false,
                  label: ``,
                },
              });
              if (res.toString() == "true") {
                GoToNova();
              } else {
                setDialogState({
                  titleDialog: "",
                  MsgDialog: tr("errorSavingFile"),
                  CallBackConfirmDialog: null,
                  Type: "AlertDialog",
                });
              }
            });
            break;
          default:
            saveService
              .onSelectedSaveXtract(name, docuviewareMain)
              .then((res) => {
                setTrackingState({
                  ...tracking,
                  openingLoadingState: {
                    ...tracking.openingLoadingState,
                    isOpen: false,
                    label: ``,
                  },
                });
                if (props.afterSave) props.afterSave();
              });
            break;
        }
        break;
      case SaveMode.SaveAsZip:
        saveService.saveZipFile(name, docuviewareMain).then(() => {
          setTrackingState({
            ...tracking,
            openingLoadingState: {
              ...tracking.openingLoadingState,
              isOpen: false,
              label: ``,
            },
          });
        });
        break;
      case SaveMode.SavePDF:
        saveService.savePdfFile(name, docuviewareMain).then(() => {
          setTrackingState({
            ...tracking,
            openingLoadingState: {
              ...tracking.openingLoadingState,
              isOpen: false,
              label: ``,
            },
          });
        });
        break;
      case SaveMode.SaveCombine:
        setDialogState({
          titleDialog: "",
          MsgDialog: tr(
            "ifYouCombineThePdfFilesPleaseBeAwareThatTheStampFrontPageTocAndBlankPageIsNotIncludedAreYouSureYouWantToCreateCombinedFile"
          ),
          CallBackConfirmDialog: {
            action: () => {
              setTrackingState({
                ...tracking,
                openingLoadingState: {
                  ...tracking.openingLoadingState,
                  isOpen: true,
                  label: ``,
                },
              });
              saveService.combinePdf(name, docuviewareMain).then(() => {
                setTrackingState({
                  ...tracking,
                  openingLoadingState: {
                    ...tracking.openingLoadingState,
                    isOpen: false,
                    label: ``,
                  },
                });
              });
            },
            param: null,
          },
          Type: "ConfirmationDialog",
        });

        break;
      case SaveMode.SaveFileXtractLC:
        {
          saveService.saveExtractFileToThirdParty(name, docuviewareMain).then((res) => {
            if (res && res.isSuccess) {
              setTrackingState({
                ...tracking,
                openingLoadingState: {
                  ...tracking.openingLoadingState,
                  isOpen: false,
                  label: ``,
                },
              });
              setDialogState({
                titleDialog: "",
                MsgDialog: tr("Save file success"),
                CallBackConfirmDialog: null,
                Type: "AlertDialog",
              });
              if (props.afterSave) props.afterSave();
            }
          });
        }
    }
    onCloseModal();
  };
  const onCloseModal = () => {
    if (props.OnClose) {
      props.OnClose();
    }
  };
  React.useEffect(() => {
    if (props.isShow) {
      setTrackingState({
        ...tracking,
        openingLoadingState: {
          ...tracking.openingLoadingState,
          isOpen: true,
          label: ``,
        },
      });
      ThumbnailApi.apiLegalxtractCheckAllPageHideGet().then((res) => {
        if (
          (props.saveMode == SaveMode.Extract ||
            props.saveMode == SaveMode.SaveAsZip ||
            props.saveMode == SaveMode.SavePDF) &&
          res.toString() == "true"
        ) {
          setDialogState({
            titleDialog: "",
            MsgDialog: tr("couldNotExtractBecauseYouHideAllThumbnailItems"),
            CallBackConfirmDialog: null,
            Type: "AlertDialog",
          });
          setTrackingState({
            ...tracking,
            openingLoadingState: {
              ...tracking.openingLoadingState,
              isOpen: false,
              label: ``,
            },
          });

          if (props.OnClose) props?.OnClose();
        } else {
          if (props.saveMode == SaveMode.Extract || props.saveMode == SaveMode.SaveXtractPdfLC) {
            SavingApi.apiLegalxtractCheckSettingWatchdogPost({
              param: WatchDogCurrentState.currentStates,
              searchTemplateParam: {
                customTemplateItems: searchCurrentState.searchingTemplateParams.customTemplateItems,
                customTemplate: true,
                templateItems: Object.values(searchCurrentState.searchingTemplateParams.templateItem)
              },
            }).then((res) => {
              setTrackingState({
                ...tracking,
                openingLoadingState: {
                  ...tracking.openingLoadingState,
                  isOpen: false,
                  label: ``,
                },
              });

              if (res.data && res.data.data && res.data.data?.length > 0) {
                const data = res.data.data;
                setDialogState({
                  titleDialog: "",
                  MsgDialog: data[0],
                  CallBackConfirmDialog: {
                    action: (param: any, confirmed: any) => {
                      if (confirmed) {
                        if (!data || !data[1]) {
                          setShow(props.isShow);
                        } else {
                          setDialogState({
                            titleDialog: "",
                            MsgDialog: data[1],
                            CallBackConfirmDialog: {
                              action: (param: any, confirmed: any) => {
                                if (confirmed) {
                                  if (!data || !data[2]) {
                                    setShow(props.isShow);
                                  } else {
                                    setDialogState({
                                      titleDialog: "",
                                      MsgDialog: data[2],
                                      CallBackConfirmDialog: {
                                        action: (
                                          param: any,
                                          confirmed: any
                                        ) => {
                                          if (confirmed) {
                                            setShow(props.isShow);
                                          } else {
                                            if (props.OnClose) props?.OnClose();
                                          }
                                        },
                                        param: { HasCancelAction: true },
                                      },
                                      Type: "ConfirmationDialog",
                                    });
                                  }
                                } else {
                                  if (props.OnClose) props?.OnClose();
                                }
                              },
                              param: { HasCancelAction: true },
                            },
                            Type: "ConfirmationDialog",
                          });
                        }
                      } else {
                        if (props.OnClose) props?.OnClose();
                      }
                    },
                    param: { HasCancelAction: true },
                  },
                  Type: "ConfirmationDialog",
                });
              } else {
                setShow(props.isShow);
              }
            });
          } else {
            setTrackingState({
              ...tracking,
              openingLoadingState: {
                ...tracking.openingLoadingState,
                isOpen: false,
                label: ``,
              },
            });

            setShow(props.isShow);
          }
        }
      });
    } else {
      setShow(props.isShow);
    }
  }, [props.isShow]);
  return (
    <Modal isOpen={show} onDismiss={onCloseModal} isBlocking={true}>
      <div className="ms-Grid" dir="ltr">
        <ModalContent style={{ width: 550 }}>
          <Stack tokens={verticalStackTokens}>
            <ModalHeader title={tr("filename")} onCloseModal={onCloseModal} />
            <TextField
              value={name as any}
              autoFocus={true}
              onChange={(e: any) => setName(e.target.value)}
              onKeyUp={(e) => {
                if (e.code.toLowerCase() == "enter") {
                  onSubmit();
                }
              }}
            />
            <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
              <DefaultButton
                text={tr("cancel")}
                onClick={onCloseModal}
                allowDisabledFocus
              />
              <PrimaryButton
                text={tr("save")}
                onClick={onSubmit}
                allowDisabledFocus
              />
            </Stack>
          </Stack>
        </ModalContent>
      </div>
    </Modal>
  );
};
