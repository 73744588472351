import { defaultPageNumberingInfo } from './../recoil/topmenuStripState';
import { LocalStorageKey } from './../config/constant';
import * as Api from '../ApiV2';
import undoManager from './undo-manager';
const PageNumberingApi = new Api.PageNumberingApi();
const PageNumberingCommand = (param: Api.PageNumberingInfo, callback: any) => {
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  let paramUndo = defaultPageNumberingInfo;
  const PageNumbering = async (param: Api.PageNumberingInfo) => {
    await PageNumberingApi.apiLegalxtractProcessPageNumberingPost(param);
    localStorage.setItem(LocalStorageKey.PageNumbering, JSON.stringify(param));
    const undoParam = localStorage.getItem(LocalStorageKey.PageNumbering);
    if (undoParam) {
      paramUndo = JSON.parse(undoParam);
      paramUndo.included = !param.included;
    }
    if (callback) {
      callback();
    }
  };
  PageNumbering(param);
  undoManager.add({
    undo: function () {
      PageNumbering(paramUndo);
    },
    redo: function () {
      PageNumbering(param);
    },
  });
};
export default PageNumberingCommand;
