import styled from "styled-components";
export const StyledCustomTemplateInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  .ms-TextField {
    width: 100%;
  }
  a {
    width: 32px;
    max-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${(props: any) => props.theme.palette.themeLighterGrey};
    }
  }
`;

export const StyledCustomTemplateItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  button {
    width: 100%;
    text-align: left;
    margin-left: 8px;
    &.active {
      border: 1px solid ${(props: any) => props.theme.palette.themePrimary};
      color: ${(props: any) => props.theme.palette.themePrimary};
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
    }
  }
  .ms-Checkbox-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
`;
export const StyledResultContainer = styled.div`
  .ms-DetailsRow-cell {
    display: flex;
    align-items: center;
    font-size: 11px !important;
    padding-right: 5px !important;
    padding-left: 0px !important;
  }
  .ms-List-page .ms-Checkbox {
    margin-bottom: 0px;
  }
  .ms-DetailsRow-fields .ms-TooltipHost {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: inherit;
    display: flex;
    width: 100%;
    text-align: left;
  }
  .SearchResultContainer .infinite-scroll-component .ms-Checkbox {
    margin-bottom: 0px;
  }
`;
// Tag Search
export const StyledCheckboxTreeContainer = styled.div`
  .react-checkbox-tree {
    background: #fafafa;
    border-radius: 2px;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
    margin-bottom: 10px;
    border: 1px#b2b2b2 solid;
  }
  .rct-text {
    label {
      width: 100%;
      padding: 7px 0 5px 3px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      .rct-node-icon {
        display: none;
      }
      .rct-checkbox {
        font-size: 16px;
      }
      .rct-title {
        margin-top: -4px;
      }
      transition: 0.3s;
      &:hover {
        background: ${(props: any) => props.theme.palette.themePrimary}19;
      }
    }
  }
  .react-checkbox-tree {
    i,
    .rct-icon {
      width: 17px;
      height: 17px;
    }
    i[data-icon-name="CheckboxComposite"] {
      background: rgb(0, 120, 212);
      border-color: rgb(0, 120, 212);
      color: #fff;
      font-size: 17px;
    }
    i[data-icon-name="CheckboxFill"] {
      color: rgb(0, 120, 212);
    }
  }
`;
// TOC Search
export const StyledTOCContainer = styled.div`
  overflow: auto;
  height: calc(100% - 48px);
  .ms-DetailsList {
    overflow: unset;
  }
  .is-row-header {
    border-left: 1px solid #edebe9;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    min-height: 0;
  }
  .ms-DetailsHeader-cellName {
    font-size: 12px;
  }
  .ms-List .ms-DetailsRow-cell {
    padding-left: 0px;
  }
`;
//Thumbnail
export const StyledThumbnailItem = styled.div<{
  active: boolean;
  selected: boolean;
}>`
  text-align: center;
  padding: 8px 0;
  border-radius: 3px;
  background-color: ${(props: any) => (props.selected ? "rgb(168, 211, 243)" : "#fff")};
  border: solid 1px
    ${(props: any) => (props.active ? "rgb(168, 211, 243)" : "transparent")};
  .placeholder {
    width: 270px;
    height: 215px;
    display: flex;
    align-items: center;
    border: solid 1px #979797;
    margin: 0 auto;
    &.vertical {
      width: 215px;
      height: 270px;
    }
  }
  p {
    margin: 2px;
  }
  &:hover {
    .placeholder {
      box-shadow: 0 0 13px 0 #87ceeb;
    }
  }
  .loading-img {
    width: 40px;
    height: 50px;
    margin: auto;
  }
`;
export const StyledThumbnailItemDuplicate = styled.div<{
  active: boolean;
  selected: boolean;
}>`
  text-align: center;
  padding: 8px 0;
  border-radius: 3px;
  float:left;
  background-color: ${(props: any) => (props.selected ? "rgb(168, 211, 243)" : "#fff")};
  border: solid 1px ${(props: any) => (props.active ? "rgb(168, 211, 243)" : "transparent")};
  width:150px;
  margin: 10px 0px 10px 10px;
  .placeholder {
    width: 130px;
    height: 160px;
    display: flex;
    align-items: center;
    border: solid 1px #979797;
    margin: 0 auto;
    &.vertical {
      width: 155px;
      height: 175px;
    }
  }
  p {
    margin: 2px;
  }
  &:hover {
    .placeholder {
      box-shadow: 0 0 13px 0 #87ceeb;
    }
  }
  .loading-img {
    width: 40px;
    height: 50px;
    margin: auto;
  }
`;