export const lineService = {
  onLineClick: (docuViewareID: string, currentPage: number, borderWidth: number, color: string) => {
    if (!DocuViewareAPI.Root(docuViewareID)) return;
    const annotation = {
      autoCreate: false,
      borderWidth: borderWidth || 0.02,
      dashCap: 0,
      dashStyle: 0,
      endCap: 0,
      opacity: 1,
      rotation: 0,
      startCap: 0,
      strokeColor: color || '#323130',
      type: 3,
    };
    DocuViewareAPI.Root(docuViewareID).Internal.Annotations.beginAddAnnotInteractive(annotation, currentPage);
  },
};
