import { atom } from 'recoil';
interface IRefreshTabActive {
  TabIndex: number;
  IsRefresh: boolean;
  ForceSearch?: boolean;
  ClearSearch?: boolean;
}
interface ILeftSidebarState {
  IshowLeftMenu: boolean;
  lastPanelSelected: number;
  isShowExpandPanel: boolean;
  SecondaryInitialSize: number;
  refreshTabActive: IRefreshTabActive | null;
}
const leftSidebarState = atom({
  key: 'leftSidebarState',
  default: {
    IshowLeftMenu: true,
    lastPanelSelected: 0,
    isShowExpandPanel: false,
    SecondaryInitialSize: 425,
    refreshTabActive: { IsRefresh: false, TabIndex: -1 },
  } as ILeftSidebarState,
});
export { leftSidebarState };

export type { IRefreshTabActive, ILeftSidebarState };
