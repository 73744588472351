import React, { useState, useContext, useEffect } from 'react';
import { useLang } from '../../../i18n/useLang';
import { DetailsList, DetailsListLayoutMode, IColumn, PrimaryButton, SelectionMode, Stack } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import {
  ContextualMenu,
  DirectionalHint,
  IContextualMenuItem,
  IContextualMenuStyles,
} from '@fluentui/react/lib/ContextualMenu';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import * as Api from '../../../ApiV2';
import { multiSort } from '../../../utils/helpers';
import { DeleteReplaceObjCommand } from '../../../command/delete-replace-command';
import { DataCache } from '../../../config/constant';
import { StyledContainer } from '../../../assets/style/left-sidebar/replace-panel';
import { StyledActionIconContainer } from '../../../assets/style/left-sidebar/leftLayout';
import { useRecoilState } from 'recoil';
import { dialogState, docuviewareMainState, replaceState } from '../../../recoil';
import { DocuVieware } from '../../../recoil/docuviewareState';
import { replaceService } from '../../../service';

const ReplaceItemActions = ({ onEdit, onDelete }: { onEdit: any; onDelete: any }) => {
  const linkRef = React.useRef(null);
  const { tr } = useLang();
  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const onShowContextualMenu = () => setShowContextualMenu(true);
  const onHideContextualMenu = () => setShowContextualMenu(false);
  const actionMenuItems: IContextualMenuItem[] = [
    {
      key: 'edit',
      text: tr('edit'),
      onClick: onEdit,
      className: 'aaa',
    },
    {
      key: 'delete',
      text: tr('delete'),
      onClick: onDelete,
    },
  ];

  return (
    <TooltipHost className="replace-menu" content={tr('')} directionalHint={DirectionalHint.leftCenter}>
      <StyledActionIconContainer ref={linkRef} onClick={onShowContextualMenu}>
        <Icon iconName="More" />
      </StyledActionIconContainer>
      <ContextualMenu
        items={actionMenuItems}
        hidden={!showContextualMenu}
        target={linkRef}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
        styles={{ container: { width: 90 } } as IContextualMenuStyles}
      />
    </TooltipHost>
  );
};

export default () => {
  const { tr } = useLang();
  const [replaceCurrentState, setReplaceState] = useRecoilState(replaceState);
  const [docuviewareMain, setDocuviewareMain] = useRecoilState<DocuVieware>(docuviewareMainState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const { RefreshReplaceWord } = replaceService();
  const COLUMNS: IColumn[] = [
    {
      key: 'originalWords',
      name: tr('wordsReplaceBy'),
      fieldName: 'replaceby',
      minWidth: 100,
      isResizable: true,
      onRender: (item: Api.ReplaceObj) => {
        return (
          <TooltipHost content={`${item.originalWords}/${item.replaceBy}`} directionalHint={DirectionalHint.topCenter}>
            <div>
              <div style={{ whiteSpace: 'break-spaces' }}>{item.originalWords}</div>
              --------------------------
              <div style={{ whiteSpace: 'break-spaces' }}>{item.replaceBy}</div>
            </div>
          </TooltipHost>
        );
      },
    },
    {
      key: 'color',
      name: tr('color'),
      fieldName: 'color',
      minWidth: 30,
      isResizable: true,
      data: 'string',
      onRender: (item: Api.ReplaceObj) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: item.extractColor as string,
            }}
          >
            <label
              style={{
                color: item.fontColor as string,
                width: '16px',
                fontWeight: 'bolder',
                textAlign: 'center',
              }}
            >
              A
            </label>
          </div>
        );
      },
    },
    {
      key: 'Count',
      name: tr('count'),
      fieldName: 'Count',
      minWidth: 64,
      maxWidth: 64,
      isResizable: true,
      onRender: (item: Api.ReplaceObjDisplay) => {
        return (
          <div style={{ paddingLeft: 12 }}>
            {item.replaceCount}/ {item.totalCount}
          </div>
        );
      },
    },
    {
      key: 'Delete',
      name: '',
      fieldName: 'Delete',
      minWidth: 40,
      onRender: (item: Api.ReplaceObj) => {
        return (
          <ReplaceItemActions
            onDelete={async () => {
              setDialogState({
                ...dialog,
                MsgDialog: tr('deleteSelectedReplace'),
                CallBackConfirmDialog: { action: deleteReplace, param: item },
                Type: 'ConfirmationDialog',
              });
              setItemDelete(item);
            }}
            onEdit={() => {
              setReplaceState((cur) => {
                return {
                  ...cur,
                  editReplacement: item,
                  replacementParams: {
                    ...cur.replaceBoxParams,
                    onlyReplaceAll: true,
                    isReplaceFromSearch: false,
                  },
                };
              });
            }}
          />
        );
      },
    },
  ];
  const [columns, setColumns] = useState<IColumn[]>(COLUMNS);
  const [itemDelete, setItemDelete] = React.useState<Api.ReplaceObj | null>(null);
  const onOpenReplaceModal = (): void => {
    setReplaceState((cur) => {
      return {
        ...cur,
        replacementParams: {
          ...cur.replacementParams,
          replaceBy: '',
          originalWords: '',
          isReplaceAll: true,
          onlyReplaceAll: true,
          isReplaceFromSearch: false,
        },
        isOpenCreateEditReplacement: true,
      };
    });
  };
  const deleteReplace = (item: Api.ReplaceObj) => {
    DeleteReplaceObjCommand(DataCache.docuViewareID(), { requestBody: [item?.id || ''] }, () => {
      setTimeout(() => {
        RefreshReplaceWord(replaceCurrentState);
      }, 300);
    });
  };
  const _onColumnClick = (
    listItem: any[],
    event: React.MouseEvent<HTMLElement> | undefined,
    column: IColumn | undefined,
  ): void => {
    if (!column) return;
    let isSortedDescending = column.isSortedDescending;
    // If we've sorted this column, flip it.
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    // Sort the items.
    const key1 = `{"${column.key}" : ${isSortedDescending ? '"desc"' : '"asc"'}}`;
    const arr = multiSort(listItem, JSON.parse(key1));
    setReplaceState((cur) => {
      return {
        ...cur,
        replaceWords: arr,
        isOpenCreateEditReplacement: false,
        sort: key1,
      };
    });
    const arrcolumn = columns.map((item) => {
      if (item) {
        item.isSorted = item.key === column.key;
        if (item.isSorted) {
          item.isSortedDescending = isSortedDescending;
        }
      }
      return item;
    });
    // Reset the items and columns to match the state.
    setColumns(arrcolumn);
  };
  useEffect(() => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0) {
      RefreshReplaceWord(replaceCurrentState);
    }
  }, []);
  return (
    <Stack verticalAlign="center" tokens={{ childrenGap: 15 }} style={{ height: '100%' }}>
      <div className="header-left-panel">{tr('replace')}</div>
      <PrimaryButton
        style={{ width: 160 }}
        text={tr('addReplaceWords')}
        onClick={onOpenReplaceModal}
        allowDisabledFocus
      />
      <StyledContainer>
        <DetailsList
          className="replaceList"
          items={replaceCurrentState.replaceWords}
          columns={columns}
          selectionMode={SelectionMode.none}
          onColumnHeaderClick={(ev, col) => {
            _onColumnClick(replaceCurrentState.replaceWords, ev, col);
          }}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </StyledContainer>
    </Stack>
  );
};
