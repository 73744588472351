import Cookies from "js-cookie";
import * as React from "react";
import { useRecoilState } from "recoil";
import {
  CompanyCode,
  Constants,
  DataCache,
  LocalStorageKey,
} from "../../config/constant";
import { trackingState } from "../../recoil";
import * as Api from "../../ApiV2";
let _showMSGExpireCookie = false;
const TestApi = new Api.TestApi();
export const JobCheckCookeis = () => {
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  React.useEffect(() => {
    _showMSGExpireCookie = tracking.showMSGExpireCookie;
  }, [tracking.showMSGExpireCookie]);

  const runJob = () => {
    setInterval(() => {
      if (
        !_showMSGExpireCookie &&
        DataCache.REACT_APP_CompanyCode?.toLowerCase() == CompanyCode.Nova
      ) {
        const TokenThirdParty = Cookies.get(Constants.TokenThirdParty);
        if (!TokenThirdParty) {
          setTrackingState({ ...tracking, showMSGExpireCookie: true });
          localStorage.removeItem(LocalStorageKey.Token);
          window.location.reload();
        }
      }
    }, 5 * 60 * 1000);
    setInterval(() => {
      TestApi.apiLegalxtractDocuViewareHealthCheckGet();
    }, 15 * 60 * 1000);
  };
  return { runJob };
};
