import { mergeStyles } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import React, { useEffect } from "react";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import { RecoilRoot } from "recoil";
import Theme from "./Theme";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
// After
import { createRoot } from "react-dom/client";
import HotKeys from "./components/hot-key/hotkey";
import AppRoute from "./AppRoute";
import { LocalStorageKey } from "./config/constant";
const {
  REACT_APP_IsDev,
  REACT_APP_ConfigAppUrl,
  REACT_APP_SENTRY_DNS,
  NODE_ENV,
} = process.env;
const RootApp = () => {
  // @ts-ignore
  window["IsDev"] = REACT_APP_IsDev;
  // @ts-ignore
  window["ConfigAppUrl"] = REACT_APP_ConfigAppUrl;
  // @ts-ignore
  window["REACT_APP_SENTRY_DNS"] = REACT_APP_SENTRY_DNS;
  // @ts-ignore
  window["UserInfo"] = LocalStorageKey.UserInfo;
  return (
    <Theme>
      <RecoilRoot>
        <AppRoute />
        <HotKeys></HotKeys>
      </RecoilRoot>
    </Theme>
  );
};

initializeIcons("/fonts-icon/");

// Inject some global styles
mergeStyles({
  selectors: {
    ":global(body), :global(html)": {
      margin: 0,
      padding: 0,
      minHeight: "500px",
    },
  },
});
const container = document.getElementById("root");
const root = createRoot(container as Element); // createRoot(container!) if you use TypeScript
root.render(<RootApp></RootApp>);
// ReactDOM.render(<RootApp></RootApp>, document.getElementById('root'));

if (NODE_ENV === "production") {
  // @ts-ignore
  //Sentry.init({ dsn: window['REACT_APP_SENTRY_DNS'] });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
