import { useRecoilState } from "recoil";
import { pageSelectedState, thumbnailState } from "../recoil";
import * as Api from "./../ApiV2";
import { DataCache } from "../config/constant";
import { CacheApp } from "../App";
import { useState } from "react";
const ThumbnailApi = new Api.ThumbnailApi();
export const thumbnailService = () => {
  const [loadding, SetLoadding] = useState(false);
  const [, setThumbnailState] = useRecoilState(thumbnailState);
  const [pageState] = useRecoilState(pageSelectedState);
  const refreshThumbnail = async (fromPage: number, toPage: number) => {
    if (pageState.currentPage === 0) {
      setThumbnailState((cur) => {
        return { ...cur, thumbnails: [] };
      });
      return;
    }
    DataCache.StatusLoadAllThumbnail = true;
    SetLoadding(true);
    const thumbnails = (
      await ThumbnailApi.apiLegalxtractGetThumbnailAllGet(
        fromPage < 0 ? 1 : fromPage,
        toPage
      )
    ).data;
    setThumbnailState((cur) => {
      return {
        ...cur,
        fromPage: fromPage || 1,
        toPage: toPage,
      };
    });
    CacheApp.Thumbnails = thumbnails;
    setTimeout(() => {
      DataCache.StatusLoadAllThumbnail = false;
      SetLoadding(false);
    }, 500);
  };
  return { refreshThumbnail, loadding };
};
