import { atom } from 'recoil';
import * as Api from '../ApiV2';
const defaultWatchdogStates = {
  combine: false,
  matchCase: false,
  wholeWord: false,
} as Api.WatchDogParamWeb;
interface WatchDogeState {
  watchdogData: Api.CalculateWatchDogResponse | null;
  watchdogItems: Api.WatchDogObj[];
  currentStates: Api.WatchDogParamWeb;
  showLoader: boolean;
}

export const WatchDogState = atom<WatchDogeState>({
  key: 'WatchDogState',
  default: {
    watchdogData: null,
    watchdogItems: [],
    currentStates: defaultWatchdogStates,
    showLoader: false,
  },
});
