import * as Api from '../ApiV2';
import { LocalStorageKey } from '../config/constant';
import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
import { UndoHightLight } from './undoHightLight';
import { CacheApp } from "../App";
const TrackingApi = new Api.TrackingApi();
const HighlightLineCommand = (
  docuViewareID: string | null | undefined,
  param: Api.HighLightUnHighLightLine,
  isHighlight: boolean,
  callback: any,
) => {
  let ParamUndo = '';
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  const HighLightLine = () => {
    const dataRequest = {
      Data: JSON.stringify(param),
      Type: 'HighLightLineCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, async (res: any) => {
      ParamUndo = res;
      CacheApp.pageDetails = [
        (
          await TrackingApi.apiLegalxtractGetWordByPageNoGet(
            param.pageNo || 0
          )
        ).data,
      ];
      if (callback) {
        callback();
      }
    });
  };
  const UnHighLightLine = () => {
    const dataRequest = {
      Data: JSON.stringify(param),
      Type: 'UnHighLightLineCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      ParamUndo = res;
      if (callback) {
        callback();
      }
    });
  };
  if (isHighlight) {
    HighLightLine();
    undoManager.add({
      undo: function () {
        UndoHightLight(docuViewareID, ParamUndo, callback);
      },
      redo: function () {
        HighLightLine();
      },
    });
  } else {
    UnHighLightLine();
    undoManager.add({
      undo: function () {
        UndoHightLight(docuViewareID, ParamUndo, callback);
      },
      redo: function () {
        UnHighLightLine();
      },
    });
  }
};

export default HighlightLineCommand;
