import * as Api from '../ApiV2';
import { LocalStorageKey } from '../config/constant';
import { annotationService } from '../service/annotationService';
import undoManager from './undo-manager';
interface RemoveLine {
  id: string;
  viewerPageNo: number;
}
interface UpdateLine {
  updateLineParam: Api.UpdateLineParam;
  viewerPageNo: number;
}
interface AddLine {
  addLineParam: Api.AddLineParam;
  viewerPageNo: number;
}
const LineCommand = (
  docuViewareID: string | null | undefined,
  paramAddLine: AddLine | null,
  paramRemoveLine: RemoveLine | null,
  paramUpdateLine: UpdateLine | null,
  lineAnnotation: any,
  callback: any,
) => {
  let ParamAddLine: AddLine | null = null;
  let ParamRemoveLine: RemoveLine | null = null;
  let ParamUpdateLine: UpdateLine | null = null;
  const Callback = callback;
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, 'true');
  const AddLine = (paramAddLine: AddLine | null) => {
    if (!paramAddLine) return;
    const dataRequest = {
      Data: JSON.stringify(paramAddLine),
      Type: 'AddLineCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      ParamRemoveLine = {
        id: res,
        viewerPageNo: paramAddLine.viewerPageNo,
      } as RemoveLine;
      if (Callback) {
        Callback();
      }
    });
  };
  const RemoveLine = (paramRemoveLine: RemoveLine | null) => {
    if (!paramRemoveLine) return;
    const dataRequest = {
      Data: JSON.stringify(paramRemoveLine),
      Type: 'RemoveLineCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      if (lineAnnotation) {
        const obj = JSON.parse(res);
        const addLineParam = {
          borderWidth: obj.borderWidth,
          color: obj.color,
          height: obj.height,
          top: lineAnnotation.annotApp.rect.Top,
          left: lineAnnotation.annotApp.rect.Left,
        } as Api.AddLineParam;
        ParamAddLine = {
          addLineParam: addLineParam,
          viewerPageNo: paramRemoveLine.viewerPageNo,
        } as AddLine;
      }
      if (Callback) {
        Callback();
      }
    });
  };
  const UpdateLine = (paramUpdateLine: UpdateLine | null) => {
    if (!paramUpdateLine) return;
    const dataRequest = {
      Data: JSON.stringify(paramUpdateLine),
      Type: 'UpdateLineCommand',
    };
    annotationService.AddAnnotations(docuViewareID, dataRequest, (res: any) => {
      const obj = JSON.parse(res);
      ParamUpdateLine = {
        updateLineParam: obj,
        viewerPageNo: paramUpdateLine.viewerPageNo,
      } as UpdateLine;
      if (Callback) {
        Callback();
      }
    });
  };
  // delete
  if (paramRemoveLine) {
    RemoveLine(paramRemoveLine);
    undoManager.add({
      undo: function () {
        AddLine(ParamAddLine);
      },
      redo: function () {
        RemoveLine(ParamRemoveLine);
      },
    });
  }
  if (paramAddLine) {
    AddLine(paramAddLine);
    undoManager.add({
      undo: function () {
        RemoveLine(ParamRemoveLine);
      },
      redo: function () {
        AddLine(paramAddLine);
      },
    });
  }
  if (paramUpdateLine) {
    UpdateLine(paramUpdateLine);
    undoManager.add({
      undo: function () {
        UpdateLine(ParamUpdateLine);
      },
      redo: function () {
        UpdateLine(paramUpdateLine);
      },
    });
  }
};

export default LineCommand;
