import { useLang } from "../../../i18n/useLang";
import { ContextualMenu, IContextualMenuItem } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import TreeBookmark from "./tree-bookmark";
import * as Api from "../../../ApiV2";
import { DataCache } from "../../../config/constant";
import { documentService } from "../../../service/document";
import { GoToPositon } from "../../../utils/helpers";
import { StyleList } from "../../../assets/style/left-sidebar/bookmark-panel";
import { useRecoilState } from "recoil";
import { bookMarkService } from "../../../service";
import {
  bookMarkState,
  dialogState,
  docuviewareMainState,
  IPageState,
  pageSelectedState,
} from "../../../recoil";
import { CacheApp } from "../../../App";
const BookMarkApi = new Api.BookMarkApi();
export default () => {
  const [pageState,] =
    useRecoilState<IPageState>(pageSelectedState);
  const [bookMark, setBookMarkState] = useRecoilState(bookMarkState);
  const { getBookMarkList } = bookMarkService();
  const [IsShowContextMenu, { toggle: SetIsShowContextMenu }] =
    useBoolean(false);
  const [targetPoint, SetTargetPoint] = useState<any>();
  const { tr } = useLang();
  const timeOutClickItem = React.useRef<any>();
  const ClickItem = React.useRef<boolean>();
  React.useEffect(() => {
    getBookMarkList();
  }, []);


  React.useEffect(() => {
    if (
      !bookMark.itemSelected?.position?.isEmpty &&
      ClickItem.current == true
    ) {
      const page =  CacheApp.DocuviewareMain?.DocuviewareInit?.pages?.find(o => (o.bookMarkIds || []).indexOf(bookMark.itemSelected?.id || "") >= 0);
      let pageNo = page?.viewerPageNo || 0;
      if (!pageNo) {
        pageNo =  CacheApp.DocuviewareMain?.DocuviewareInit?.tabDetails?.find(o => o.uniqueId == bookMark.itemSelected?.id)?.startPage || 1;
      }
      GoToPositon(
        {
          x: bookMark.itemSelected?.position?.x || 0,
          y: bookMark.itemSelected?.position?.y || 0,
        },
        pageNo,
        DataCache.docuViewareID() || ""
      );
    }
    ClickItem.current = false;
  }, [pageState.currentPage]);

  return (
    <StyleList>
      <div className="header-left-panel">{tr("bookMark")}</div>
      <div className="bookmark-list">
        <TreeBookmark
          onContextMenu={(e) => {
            SetIsShowContextMenu();
            SetTargetPoint({ x: e.clientX, y: e.clientY });
          }}
          Datas={bookMark.allBookMarks}
          selectedItem={bookMark.itemSelected}
          onSelected={(item) => {
            ClickItem.current = true;
            setBookMarkState((cur) => {
              return {
                ...cur, itemSelected: item
              }
            });
            if (timeOutClickItem.current) {
              clearTimeout(timeOutClickItem.current);
            }

            const page =  CacheApp.DocuviewareMain?.DocuviewareInit?.pages?.find(o => (o.bookMarkIds || []).indexOf(item?.id || "") >= 0);
            console.log(JSON.stringify( CacheApp.DocuviewareMain?.DocuviewareInit?.pages));
            let pageNo = page?.viewerPageNo || 0;
            if (!pageNo) {
              pageNo =  CacheApp.DocuviewareMain?.DocuviewareInit?.tabDetails?.find(o => o.uniqueId == item.id)?.startPage || 1;
            }
            timeOutClickItem.current = setTimeout(() => {
              if (pageNo == pageState.currentPage) {
                GoToPositon(
                  {
                    x: bookMark.itemSelected?.position?.x || 0,
                    y: bookMark.itemSelected?.position?.y || 0,
                  },
                  pageNo,
                  DataCache.docuViewareID() || ""
                );
              } else {
                documentService.GotoPage(
                  DataCache.docuViewareID(),
                  pageNo,
                );
              }
            }, 250);
          }}
        ></TreeBookmark>
        {IsShowContextMenu && (
          <MenuContextTree
            hideMenu={() => {
              SetIsShowContextMenu();
            }}
            targetPoint={targetPoint}
          />
        )}
      </div>
    </StyleList>
  );
};
const MenuContextTree = ({
  targetPoint,
  hideMenu,
}: {
  targetPoint: any;
  hideMenu: () => void;
}) => {
  const [bookMark, setBookMarkState] = useRecoilState(bookMarkState);
  const { getBookMarkList } = bookMarkService();
  const [, setDialogState] = useRecoilState(dialogState);
  const [, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  useEffect(() => {
    document.addEventListener("mousedown", hideMenu);
    return () => {
      document.removeEventListener("mousedown", hideMenu);
    };
  }, []);
  const { tr } = useLang();
  const deleteItem = () => {
    if (!bookMark.itemSelected?.parentId) {
      setDialogState({
        titleDialog: "",
        MsgDialog: tr(
          "cantDeleteSelectedBookmarkBecauseItIsLinkedToTheRootBookmark"
        ),
        CallBackConfirmDialog: null,
        Type: "AlertDialog",
      });
      return;
    }
    BookMarkApi.apiLegalxtractDeleteBookMarkDelete(
      bookMark.itemSelected?.id
    ).then((data) => {
      getBookMarkList();
      setBookMarkState((cur) => {
        return {
          ...cur, itemSelected: null
        }
      });
      setDocuviewareMain((cur) => {
        return {
          ...cur, DocuviewareInit:
          {
            ...cur.DocuviewareInit,
            pages: cur.DocuviewareInit?.pages?.map((o) => {
              if (data.data.id == o.id) {
                return data.data;
              }
              return o;
            })
          }
        }
      });
    });
  };
  const items = [
    {
      key: "Edit",
      name: tr("edit"),
      onClick: (ev) => {
        setBookMarkState((cur) => {
          return {
            ...cur, ShowBookMarkModal: "Edit"
          }
        });
        hideMenu();
      },
    },
    {
      key: "Delete",
      name: tr("delete"),
      onClick: (ev) => {
        setDialogState({
          titleDialog: "",
          MsgDialog:
            tr("doYouWantToDelete") +
            ": " +
            '"' +
            bookMark.itemSelected?.title +
            '"',
          CallBackConfirmDialog: { action: deleteItem, param: null },
          Type: "AlertDialog",
        });
        hideMenu();
      },
    },
  ] as IContextualMenuItem[];
  if (bookMark.itemSelected?.parentId == null) return <></>;
  return <ContextualMenu target={targetPoint} items={items} />;
};
