import { atom } from 'recoil';
import * as Api from '../ApiV2';
interface NoteState {
  allNotes: Api.NoteTextObj[];
  noteModalState: any;
}
export const noteState = atom({
  key: 'noteState',
  default: {
    allNotes: new Array<Api.NoteTextObj>(),
    noteModalState: {
      isShowAddNoteModal: '',
      noteBeingEdit: {},
      mousePos: '',
    },
  },
});
