import React, { useEffect } from "react";
import { useLang } from "../../../i18n/useLang";

import {
  Checkbox,
  ChoiceGroup,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack,
  StackItem,
} from "@fluentui/react";
import { useRecoilState } from "recoil";
import { LocalStorageKey } from "../../../config/constant";
import { pageSelectedState, replaceState, searchState } from "../../../recoil";
import { searchService } from "../../../service";
import { documentService } from "../../../service/document";
import TagSearchOptions from "./TagSearchOptions";
import CustomSearchComponent from "./templateSearch";
import MainSearchOptions from "./mainSearchOptions";
import SearchResultComponent from "./searchResult";
import { userService } from "../../../service/userService";

export default () => {
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const { onSearchWord } = searchService();
  const { tr } = useLang();
  const [pageState] = useRecoilState(pageSelectedState);

  useEffect(() => {
    let value = null;
    switch (searchCurrentState.searchType) {
      case "SearchTemplate":
        value = localStorage.getItem(LocalStorageKey.TemplateSearch);
        if (value) {
          setSearchState({
            ...searchCurrentState,
            searchingTemplateParams: JSON.parse(value),
          });
        }
        break;
      case "SearchTag": {
        value = localStorage.getItem(LocalStorageKey.TagSearch);
        if (value) {
          setSearchState({
            ...searchCurrentState,
            searchTagParams: JSON.parse(value),
          });
        }
        break;
      }

      default: {
        value = localStorage.getItem(LocalStorageKey.KeywordSearch);
        if (value && !searchCurrentState.searchingKeywordParams.keyword) {
          setSearchState({
            ...searchCurrentState,
            searchingKeywordParams: JSON.parse(value),
          });
        }
        break;
      }
    }
    if (
      searchCurrentState.searchTabVm.autoSearch &&
      (searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized ==
        undefined ||
        !searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized)
    ) {
      onSearchWord(pageState.currentPage);
    }
    setTimeout(() => {
      documentService.CalculateHeightSearchResult();
    }, 0);
  }, [searchCurrentState.searchType]);

  useEffect(() => {
    localStorage.setItem(
      LocalStorageKey.SearchTabVm,
      JSON.stringify(searchCurrentState.searchTabVm)
    );
  }, [searchCurrentState.searchTabVm]);
  useEffect(() => {
    onSearchWord(pageState.currentPage, null);
    window.addEventListener("resize", () => {
      documentService.CalculateHeightSearchResult();
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);
  return (
    <>
      <div className="header-left-panel">{tr("find")}</div>
      <Stack
        style={{
          height: "100%",
          overflowY: "auto",
          marginBottom: 15,
          display: "block",
        }}
        className="searchtab"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        <Pivot
          onLinkClick={(e) => {
            setSearchState({
              ...searchCurrentState,
              searchType: e?.props.itemKey || "SearchKeyword",
            });
          }}
          onClick={(e) => {
            // @ts-ignore
            if (e.target.parentElement.className.indexOf("ms-Pivot") > -1) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          selectedKey={searchCurrentState.searchType}
        >
          <PivotItem
            className={"param-search SearchKeyword"}
            headerText={tr("keywordSearch")}
            itemKey={"SearchKeyword"}
            headerButtonProps={{
              "data-order": 1,
            }}
          >
            <MainSearchOptions
              searchingKeywordParams={searchCurrentState.searchingKeywordParams}
              setSearchingKeywordParams={(obj) => {
                setSearchState({
                  ...searchCurrentState,
                  searchingKeywordParams: obj,
                });
              }}
              key={"main-search-options"}
            />
            {searchCurrentState.searchType === "SearchKeyword" && (
              <SearchResult></SearchResult>
            )}
          </PivotItem>
          <PivotItem
            className={"param-search SearchTemplate"}
            headerText={tr("templateSearch")}
            itemKey={"SearchTemplate"}
          >
            <CustomSearchComponent
              key={
                searchCurrentState.searchingKeywordParams
                  .ForceSearchWhenInitialized
                  ? new Date().getTime()
                  : "custom-search-component"
              }
            />
            {searchCurrentState.searchType === "SearchTemplate" && (
              <SearchResult></SearchResult>
            )}
          </PivotItem>
          <PivotItem
            className={"param-search SearchTag"}
            headerText={tr("tagSearch")}
            itemKey={"SearchTag"}
          >
            <TagSearchOptions />
            {searchCurrentState.searchType === "SearchTag" && (
              <SearchResult></SearchResult>
            )}
          </PivotItem>
        </Pivot>
      </Stack>
    </>
  );
};
export const SearchResult = () => {
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const [replaceCurrentState, setReplaceState] = useRecoilState(replaceState);
  const { onSearchWord } = searchService();
  const { tr } = useLang();
  const [pageState] = useRecoilState(pageSelectedState);
  const { getUser } = userService();
  const checkUserBelgiumCountry = () => {
    const user = getUser();
    return user?.userDataLogin?.result?.user_info?.countryCode == "BEL";
  };
  useEffect(() => {
    if (searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized) {
      onSearchWord(pageState.currentPage);
      setSearchState((cur) => {
        return {
          ...cur,
          searchingKeywordParams: {
            ...cur.searchingKeywordParams,
            ForceSearchWhenInitialized: false,
          },
        };
      });
    }
  }, [searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized]);
  useEffect(() => {
    // if (
    //   !searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized &&
    //   searchCurrentState.searchTabVm.autoSearch
    // )
    //   onSearchWord(pageState.currentPage, null);
  }, []);
  // useEffect(() => {
  //   let value = "";
  //   switch (searchCurrentState.searchType) {
  //     case "SearchTemplate":
  //       value = localStorage.getItem(LocalStorageKey.TemplateSearch) || "";
  //       if (value) {
  //         setSearchState((cur) => {
  //           return {
  //             ...cur,
  //             searchingTemplateParams: JSON.parse(value as string),
  //           };
  //         });
  //       }
  //       break;
  //     case "SearchTag": {
  //       value = localStorage.getItem(LocalStorageKey.TagSearch) || "";
  //       if (value) {
  //         setSearchState((cur) => {
  //           return {
  //             ...cur,
  //             searchTagParams: JSON.parse(value as string),
  //           };
  //         });
  //       }
  //       break;
  //     }

  //     default: {
  //       value = localStorage.getItem(LocalStorageKey.KeywordSearch) || "";
  //       if (value && !searchCurrentState.searchingKeywordParams.keyword) {
  //         setSearchState((cur) => {
  //           return {
  //             ...cur,
  //             searchingKeywordParams: JSON.parse(value as string),
  //           };
  //         });
  //       }
  //       break;
  //     }
  //   }
  //   if (
  //     searchCurrentState.searchTabVm.autoSearch &&
  //     (searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized ==
  //       undefined ||
  //       !searchCurrentState.searchingKeywordParams.ForceSearchWhenInitialized)
  //   ) {
  //     onSearchWord(pageState.currentPage);
  //   }
  // }, [searchCurrentState.searchType]);

  const onShowReplaceModal = (isReplaceAll: boolean) => {
    if (!isReplaceAll) {
      const itemSelecteds = searchCurrentState.searchResult?.searchData
        ?.filter((o) => o.checked == true)
        .map((o) => o.sortKey || 0);
      if ((itemSelecteds?.length || 0) == 0) return;
      setReplaceState((cur) => {
        return {
          ...cur,
          replacementParams: {
            ...cur.replacementParams,
            originalWords: "",
            replaceBy: "",
            rectangleWords: null,
            onlyReplaceAll: true,
            isReplaceAll: true,
            isReplaceFromSearch: true,
            sortKeys: itemSelecteds || [],
          },
          isOpenCreateEditReplacement: true,
        };
      });
    } else {
      if ((searchCurrentState.searchResult?.searchData?.length || 0) == 0)
        return;
      setReplaceState((cur) => {
        return {
          ...cur,
          replacementParams: {
            ...cur.replacementParams,
            originalWords: "",
            replaceBy: "",
            rectangleWords: null,
            onlyReplaceAll: true,
            isReplaceAll: true,
            isReplaceFromSearch: true,
            sortKeys: [],
          },
          isOpenCreateEditReplacement: true,
        };
      });
    }
  };
  useEffect(() => {
    localStorage.setItem(
      LocalStorageKey.SearchTabVm,
      JSON.stringify(searchCurrentState.searchTabVm)
    );
  }, [searchCurrentState.searchTabVm]);

  const checkSearchWildcard = () => {
    if (
      searchCurrentState.searchType == "SearchKeyword" &&
      searchCurrentState.searchingKeywordParams?.wildCard &&
      ((searchCurrentState.searchingKeywordParams.keyword || "").indexOf("*") >=
        0 ||
        (searchCurrentState.searchingKeywordParams.keyword || "").indexOf(
          "?"
        ) >= 0)
    ) {
      setSearchState({
        ...searchCurrentState,
        searchingKeywordParams: {
          ...searchCurrentState.searchingKeywordParams,
          wholeWord: true,
          highlightWholeWord: true,
        },
      });
    }
  };
  return (
    <Stack
      style={{ marginTop: 10 }}
      verticalAlign="center"
      tokens={{ childrenGap: 10 }}
    >
      <div className="ms-Grid" dir="ltr">
        <Stack verticalAlign="center" tokens={{ childrenGap: 5 }}>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ">
              <ChoiceGroup
                disabled={pageState.currentPage <= 0}
                onChange={(event, option) => {
                  setSearchState({
                    ...searchCurrentState,
                    searchTabVm: {
                      ...searchCurrentState.searchTabVm,
                      allPages: false,
                    },
                  });
                }}
                selectedKey={searchCurrentState.searchTabVm.allPages ? "" : "A"}
                options={[
                  {
                    key: "A",
                    text: tr("currentPage"),
                    onRenderField: (props, render) => {
                      return (
                        <div className={"option-left"}>{render!(props)}</div>
                      );
                    },
                  },
                ]}
                required={true}
              />
            </div>
            <div className="ms-Grid-col ms-sm6 ">
              <ChoiceGroup
                disabled={pageState.currentPage <= 0}
                onChange={(event, option) => {
                  setSearchState({
                    ...searchCurrentState,
                    searchTabVm: {
                      ...searchCurrentState.searchTabVm,
                      allPages: true,
                    },
                  });
                }}
                selectedKey={searchCurrentState.searchTabVm.allPages ? "B" : ""}
                options={[
                  {
                    key: "B",
                    text: tr("allPages"),
                  },
                ]}
                required={true}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div
              className="ms-Grid-col ms-sm6 "
              style={{ display: "flex", alignItems: "baseline" }}
            >
              {!checkUserBelgiumCountry() && (
                <Checkbox
                  label={tr("accuracy")}
                  disabled={pageState.currentPage <= 0}
                  checked={searchCurrentState.searchTabVm.accuracy}
                  onChange={(ev, checked) => {
                    setSearchState({
                      ...searchCurrentState,
                      searchTabVm: {
                        ...searchCurrentState.searchTabVm,
                        accuracy: checked,
                      },
                    });
                  }}
                />
              )}
            </div>
            <div className="ms-Grid-col ms-sm6 ">
              <Checkbox
                label={tr("autoSearch")}
                checked={searchCurrentState.searchTabVm.autoSearch}
                disabled={pageState.currentPage <= 0}
                onChange={(ev, checked) => {
                  setSearchState({
                    ...searchCurrentState,
                    searchTabVm: {
                      ...searchCurrentState.searchTabVm,
                      autoSearch: checked,
                    },
                  });
                }}
              />
            </div>
          </div>
        </Stack>
      </div>

      <StackItem>
        <PrimaryButton
          style={{ width: "100%" }}
          text={tr("search")}
          disabled={pageState.currentPage <= 0}
          onClick={() => {
            checkSearchWildcard();
            setTimeout(() => {
              onSearchWord(pageState.currentPage);
            }, 300);
          }}
          className="btn-searchCurrentState.searchResult"
        />
      </StackItem>

      <SearchResultComponent />
      <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          style={{ width: "50%" }}
          text={tr("replace") + " " + tr("selected")}
          type={"button"}
          disabled={pageState.currentPage <= 0}
          onClick={() => {
            onShowReplaceModal(false);
          }}
          className="btn-searchCurrentState.searchResult"
        />
        <PrimaryButton
          text={tr("replaceAll")}
          style={{ width: "50%" }}
          disabled={pageState.currentPage <= 0}
          type={"button"}
          onClick={() => {
            onShowReplaceModal(true);
          }}
          className="btn-searchCurrentState.searchResult"
        />
      </Stack>
    </Stack>
  );
};
