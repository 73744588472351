import { useRecoilState } from "recoil";
import * as ApiV2 from "../ApiV2";
import HighlightTextCommand from "../command/hightlight-Command";
import HighlightLineCommand from "../command/hightlight-line-command";
import HighlightPageCommand from "../command/hightlight-page-command";
import HighlightMaskCommand from "../command/mask-hightlight-command";
import { DataCache } from "../config/constant";
import { GetSelectionBoxParameterFromWords } from "../utils/helpers";
import { docuviewareMainState } from "../recoil";
import { CacheApp } from "../App";
export const documentService = {
  RefreshPage: (callBack: any, docuViewareID: any) => {
    const root = DocuViewareAPI.Root(docuViewareID);
    if (root) {
      root.AddAnnotCustom(
        {
          Type: "RefreshPage",
        },
        (res: any) => {
          if (callBack) {
            callBack(res);
          }
        },
        null,
        null
      );
    }
  },
  SelectedPage: (callBack: any, docuViewareID: any, param: any) => {
    const root = DocuViewareAPI.Root(docuViewareID);
    if (root) {
      root.AddAnnotCustom(
        param,
        (res: any) => {
          if (callBack) {
            callBack(res);
          }
        },
        null,
        null
      );
    }
  },
  pageWidth: () => {
    const root = DocuViewareAPI.Root(DataCache.docuViewareID());
    if (root) root.SetZoomMode(3);
  },
  wholePage: () => {
    const root = DocuViewareAPI.Root(DataCache.docuViewareID());
    if (root) root.setZoomMode(3);
  },
  HighLightOrUnHighLightMask: (
    mode: number,
    pageNo: number,
    regionsMask: any[],
    callBack: any
  ) => {
    if (!regionsMask || regionsMask.length == 0) return;
    const selectionBox: any[] = [];
    regionsMask.forEach((item) => {
      selectionBox.push({
        left: (item.Left || 0) / 72,
        top: (item.Top || 0) / 72,
        width: (item.Width || 0) / 72,
        height: (item.Height || 0) / 72,
        viewerPageNo: pageNo,
      });
    });
    const param: ApiV2.HighLightOrUnHighLightWords = {
      rectangleSelection: selectionBox,
      mode: mode,
      viewerPageNo: pageNo,
    };
    HighlightMaskCommand(DataCache.docuViewareID(), param, () => {
      if (callBack) {
        callBack();
      }
    });
  },

  highLightPage: (param:  ApiV2.HighLightUnHighLightPage, callBack: any) => {
    HighlightPageCommand(DataCache.docuViewareID(), param, () => {
      if (callBack) {
        callBack();
      }
    });
  },
  HighLightOrUnHighLightWords: (
    pageNo: number,
    mode: number,
    WordDetected: ApiV2.LxWordResponse | null,
    callBack: any
  ) => {
    if (!WordDetected) return;
    const selectionBox = GetSelectionBoxParameterFromWords([WordDetected]);
    const param: ApiV2.HighLightOrUnHighLightWords = {
      rectangleSelection: selectionBox,
      mode: mode,
      viewerPageNo: pageNo,
    };
    HighlightTextCommand(DataCache.docuViewareID(), param, () => {
      if (callBack) {
        callBack();
      }
    });
  },

  HighLightLine: (param: ApiV2.HighLightUnHighLightLine, callBack: any) => {
    HighlightLineCommand(DataCache.docuViewareID(), param, true, () => {
      if (callBack) {
        callBack();
      }
    });
  },
  GotoPage: (docuViewareID: any, page: number) => {
    DocuViewareAPI.SelectPage(docuViewareID, page);
  },
  centerScroll: () => {
    const main = document.getElementById("viewerDocuVieware_Main");
    if (main) {
      main.scrollLeft = 1000000000;
      const scrollWidth = main.scrollWidth;
      const diff = (scrollWidth - main.scrollLeft) / 2;
      const middle = scrollWidth / 2 - diff;
      main.scrollLeft = middle;
    }
  },
  CalculateHeightSearchResult: () => {
    const searchtab = document.getElementsByClassName("searchtab");
    const searchResult = document.getElementsByClassName(
      "infinite-scroll-component"
    );
    const SearchTemplate = document.getElementsByClassName("SearchTemplate");
    const SearchTag = document.getElementsByClassName("SearchTag");
    const SearchKeyword = document.getElementsByClassName("SearchKeyword");

    let height = 300;
    if (SearchTag.length > 0) {
      height = searchtab[0].clientHeight - 636;
    } else if (SearchTemplate.length > 0) {
      const paramSearch = document.getElementsByClassName("TemplateInputGroup");
      const hCustomSearchList =
        paramSearch && paramSearch.length > 0
          ? paramSearch[0].clientHeight + 1
          : -20;
      const templatesearchoption = document.getElementsByClassName(
        "templatesearchoption"
      )[0];
      let htemplatesearchoption = 0;
      Array.from(
        templatesearchoption.getElementsByClassName("ms-Grid-row")
      ).forEach((element) => {
        htemplatesearchoption += element.clientHeight + 10;
      });
      height =
        searchtab[0].clientHeight -
        hCustomSearchList -
        htemplatesearchoption -
        44 -
        300;
    } else {
      if (searchtab && searchtab.length > 0) {
        const paramSearch = document.getElementsByClassName(
          "param-search-detail"
        );
        height =
          searchtab[0].clientHeight -
          41 -
          44 -
          72 -
          paramSearch[0].clientHeight -
          244;
      }
    }
    if (searchResult[0])
      searchResult[0].setAttribute(
        "style",
        `height:  ${height}px;min-height: 300px;overflow: auto;border: 1px solid rgb(221, 221, 221);width: auto;position: relative;padding: 0px 5px;`
      );
  },
  SetCacheDocuviewareMain: (docuViewareData: any, callBack: () => void) => {
    CacheApp.DocuviewareMain = {
      ...CacheApp.DocuviewareMain,
      ...docuViewareData,
    };
    callBack && callBack();
  },
};
