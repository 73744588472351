import { Checkbox, DirectionalHint, Stack } from "@fluentui/react";
import {
  ITooltipHostStyles,
} from "@fluentui/react/lib/Tooltip";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useLang } from "../../../i18n/useLang";
import {
  pageSelectedState,
  searchState,
} from "../../../recoil";
import { documentService } from "../../../service/document";
export const TemplateSearchBelgium = () => {
  const [searchCurrentState, setSearchState] = useRecoilState(searchState);
  const [pageState] = useRecoilState(pageSelectedState);
  const { tr } = useLang();
  useEffect(() => {
    setTimeout(() => {
      documentService.CalculateHeightSearchResult();
    }, 300);
  }, [searchCurrentState.searchingTemplateParams]);
  return (
    <Stack verticalAlign="center" className="templatesearchoption" tokens={{ childrenGap: 10 }}>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <div style={{ display: "flex", flex: "50%" }}>
            <Checkbox
              label={tr("fullName")}
              disabled={pageState.currentPage <= 0}
              checked={
                searchCurrentState.searchingTemplateParams.templateItem.name.checked
              }
              onChange={(event, value: any) => {
                setSearchState((cur) => {
                  return {
                    ...cur,
                    searchingTemplateParams: {
                      ...cur.searchingTemplateParams,
                      templateItem: { ...cur.searchingTemplateParams?.templateItem, name: { ...cur.searchingTemplateParams.templateItem?.name, checked: (value || false) as boolean } },
                    },
                  }
                });
              }}
            />
            <div style={{ width: 21 }} />
            <Checkbox
              label={tr("firstName")}
              disabled={pageState.currentPage <= 0}
              checked={
                searchCurrentState.searchingTemplateParams.templateItem.name.isFull
              }
              onChange={(event, value: any) => {
                setSearchState((cur) => {
                  return {
                    ...cur,
                    searchingTemplateParams: {
                      ...cur.searchingTemplateParams,
                      templateItem: { ...cur.searchingTemplateParams?.templateItem, name: { ...cur.searchingTemplateParams.templateItem?.name, isFull: (value || false) as boolean } },
                    },
                  }
                });
              }}
            />
          </div>
        </div>
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("phoneNumber")}
            checked={searchCurrentState.searchingTemplateParams.templateItem.phoneNumber.checked}
            disabled={pageState.currentPage <= 0}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, phoneNumber: { ...cur.searchingTemplateParams.templateItem?.phoneNumber, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <div style={{ display: "flex", flex: "50%" }}>
            <div>
              <Checkbox
                disabled={pageState.currentPage <= 0}
                label={tr("BSN (BE-ID)")}
                checked={searchCurrentState.searchingTemplateParams.templateItem.bsn.checked}
                onChange={(event, value: any) => {
                  setSearchState((cur) => {
                    return {
                      ...cur,
                      searchingTemplateParams: {
                        ...cur.searchingTemplateParams,
                        templateItem: { ...cur.searchingTemplateParams?.templateItem, bsn: { ...cur.searchingTemplateParams.templateItem?.bsn, checked: (value || false) as boolean } },
                      },
                    }
                  });
                }}
              />
            </div>
            <div style={{ width: 21 }} />
            {searchCurrentState.searchingTemplateParams.templateItem.bsn.checked && (
              <div>
                <Checkbox
                  disabled={pageState.currentPage <= 0}
                  label={tr("module97")}
                  checked={
                    searchCurrentState.searchingTemplateParams.templateItem.bsn.isFull
                  }
                  onChange={(event, value: any) => {
                    setSearchState((cur) => {
                      return {
                        ...cur,
                        searchingTemplateParams: {
                          ...cur.searchingTemplateParams,
                          templateItem: { ...cur.searchingTemplateParams?.templateItem, bsn: { ...cur.searchingTemplateParams.templateItem?.bsn, isFull: (value || false) as boolean } },
                        },
                      }
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("email")}
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchingTemplateParams.templateItem.email.checked}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, email: { ...cur.searchingTemplateParams.templateItem?.email, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <div style={{ display: "flex", flex: "50%" }}>
            <div>
              <Checkbox
                disabled={pageState.currentPage <= 0}
                label={tr("NRN (NL-ID)")}
                checked={searchCurrentState.searchingTemplateParams.templateItem.nrn.checked}
                onChange={(event, value: any) => {
                  setSearchState((cur) => {
                    return {
                      ...cur,
                      searchingTemplateParams: {
                        ...cur.searchingTemplateParams,
                        templateItem: { ...cur.searchingTemplateParams?.templateItem, nrn: { ...cur.searchingTemplateParams.templateItem?.nrn, checked: (value || false) as boolean } },
                      },
                    }
                  });
                }}
              />
            </div>
            <div style={{ width: 21 }} />
            {searchCurrentState.searchingTemplateParams.templateItem.nrn.checked && (
              <div>
                <Checkbox
                  disabled={pageState.currentPage <= 0}
                  label={tr("module11")}
                  checked={
                    searchCurrentState.searchingTemplateParams.templateItem.nrn.isFull
                  }
                  onChange={(event, value: any) => {
                    setSearchState((cur) => {
                      return {
                        ...cur,
                        searchingTemplateParams: {
                          ...cur.searchingTemplateParams,
                          templateItem: { ...cur.searchingTemplateParams?.templateItem, nrn: { ...cur.searchingTemplateParams.templateItem?.nrn, isFull: (value || false) as boolean } },
                        },
                      }
                    });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("cvr")}
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchingTemplateParams.templateItem.cvr.checked}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, cvr: { ...cur.searchingTemplateParams.templateItem?.cvr, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("prices")}
            checked={searchCurrentState.searchingTemplateParams.templateItem.currency.checked}
            disabled={pageState.currentPage <= 0}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, currency: { ...cur.searchingTemplateParams.templateItem?.currency, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
        <div className="ms-Grid-col ms-sm6">
          <div style={{ display: "flex", flex: "50%" }}>
            <Checkbox
              disabled={pageState.currentPage <= 0}
              label={tr("address")}
              checked={searchCurrentState.searchingTemplateParams.templateItem.address.checked}
              onChange={(event, value: any) => {
                setSearchState((cur) => {
                  return {
                    ...cur,
                    searchingTemplateParams: {
                      ...cur.searchingTemplateParams,
                      templateItem: { ...cur.searchingTemplateParams?.templateItem, address: { ...cur.searchingTemplateParams.templateItem?.address, checked: (value || false) as boolean } },
                    },
                  }
                });
              }}
            />
            <div style={{ width: 10 }} />
            {searchCurrentState.searchingTemplateParams.templateItem.address.checked && (
              <Checkbox
                label={tr("fullAddress")}
                checked={searchCurrentState.searchingTemplateParams.templateItem.address.isFull}
                disabled={pageState.currentPage <= 0}
                onChange={(event, value: any) => {
                  setSearchState((cur) => {
                    return {
                      ...cur,
                      searchingTemplateParams: {
                        ...cur.searchingTemplateParams,
                        templateItem: { ...cur.searchingTemplateParams?.templateItem, address: { ...cur.searchingTemplateParams.templateItem?.address, isFull: (value || false) as boolean } },
                      },
                    }
                  });
                }}
              />
            )}
          </div>
        </div>

      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("date")}
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchingTemplateParams.templateItem.date.checked}
            onChange={(e, value) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, date: { ...cur.searchingTemplateParams.templateItem?.date, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            disabled={pageState.currentPage <= 0}
            label={tr("belgianMunicipalities")}
            checked={searchCurrentState.searchingTemplateParams.templateItem.belgianMunicipalities.checked}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, belgianMunicipalities: { ...cur.searchingTemplateParams.templateItem?.belgianMunicipalities, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
      </div>

      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("policeZones")}
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchingTemplateParams.templateItem.policeZones.checked}
            onChange={(e, value) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, policeZones: { ...cur.searchingTemplateParams.templateItem?.policeZones, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("BE - License plates")}
            checked={searchCurrentState.searchingTemplateParams.templateItem.beLicensePlates.checked}
            disabled={pageState.currentPage <= 0}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, beLicensePlates: { ...cur.searchingTemplateParams.templateItem?.beLicensePlates, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("BE - Police report (PV)")}
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchingTemplateParams.templateItem.bePoliceReport.checked}
            onChange={(e, value) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, bePoliceReport: { ...cur.searchingTemplateParams.templateItem?.bePoliceReport, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("BE - Company ID")}
            checked={searchCurrentState.searchingTemplateParams.templateItem.beCompanyID.checked}
            disabled={pageState.currentPage <= 0}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, beCompanyID: { ...cur.searchingTemplateParams.templateItem?.beCompanyID, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("BE - Handelsregisternummer")}
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchingTemplateParams.templateItem.beHandelsregisternummer.checked}
            onChange={(e, value) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, beHandelsregisternummer: { ...cur.searchingTemplateParams.templateItem?.beHandelsregisternummer, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("webAddress")}
            checked={searchCurrentState.searchingTemplateParams.templateItem.webAddress.checked}
            disabled={pageState.currentPage <= 0}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, webAddress: { ...cur.searchingTemplateParams.templateItem?.webAddress, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("diseases")}
            disabled={pageState.currentPage <= 0}
            checked={searchCurrentState.searchingTemplateParams.templateItem.diseases.checked}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, diseases: { ...cur.searchingTemplateParams.templateItem?.diseases, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("creditCard")}
            checked={searchCurrentState.searchingTemplateParams.templateItem.creditCard.checked}
            disabled={pageState.currentPage <= 0}
            onChange={(event, value: any) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, creditCard: { ...cur.searchingTemplateParams.templateItem?.creditCard, checked: (value || false) as boolean } },
                  },
                }
              });
            }}
          />
        </div>
      </div>
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm6">
          <Checkbox
            label={tr("customTemplate")}
            disabled={pageState.currentPage <= 0}
            checked={
              searchCurrentState.searchingTemplateParams.templateItem.customTemplate.checked
            }
            onChange={(e, value) => {
              setSearchState((cur) => {
                return {
                  ...cur,
                  searchingTemplateParams: {
                    ...cur.searchingTemplateParams,
                    templateItem: { ...cur.searchingTemplateParams?.templateItem, customTemplate: { ...cur.searchingTemplateParams.templateItem?.customTemplate, checked: (value || false) as boolean } },
                  },
                }
              });
            }}

          />
        </div>
        <div className="ms-Grid-col ms-sm6"></div>
      </div>
    </Stack>
  );
};
