import * as Api from "../ApiV2";
import { LocalStorageKey } from "../config/constant";
import undoManager from "./undo-manager";
const ApprovalStampApi = new Api.ApprovalStampApi();
const EditApprovalStampCommand = (
  viewerPageNo?: number,
  EditApprovalStampInfo?: Api.ApprovalStampInfo | null,
  OldApprovalStampInfo?: Api.ApprovalStampViewItem | null,
  callback?: any
) => {
  
  localStorage.setItem(LocalStorageKey.DocumentHasChanged, "true");
  const undoEdit = {
    viewerPageNo: viewerPageNo,
    oldApprovalStampInfo: OldApprovalStampInfo,
  };
  const Callback = callback;
  const EditApprovalStamp = (
    viewerPageNo?: number,
    editApprovalStampInfo?: Api.ApprovalStampInfo | null
  ) => {
    if (!editApprovalStampInfo) return;
    ApprovalStampApi.apiLegalxtractSaveEditedStampPost(
      viewerPageNo,
      editApprovalStampInfo
    ).then((res) => {
      if (Callback) {
        Callback(res.data);
      }
    });
  };
  // delete
  EditApprovalStamp(viewerPageNo, EditApprovalStampInfo);
  undoManager.add({
    undo: function () {
      EditApprovalStamp(undoEdit.viewerPageNo, undoEdit.oldApprovalStampInfo);
    },
    redo: function () {
      EditApprovalStamp(viewerPageNo, EditApprovalStampInfo);
    },
  });
};

export default EditApprovalStampCommand;
