import { atom } from 'recoil';
import * as Api from '../ApiV2';

import { TreeNode } from '../components/left-sidebar/bookmark/tree-bookmark';

interface BookMarkState {
  ShowBookMarkModal: string;
  allBookMarks: TreeNode[];
  itemSelected: TreeNode | null;
  newBookmark: Api.BookmarkObj | null;
  ListBookmark: Api.BookmarkObj[] | null;
}

export const bookMarkState = atom<BookMarkState>({
  key: 'bookMarkState',
  default: {
    allBookMarks: new Array<TreeNode>(),
    ShowBookMarkModal: '',
    itemSelected: null,
    newBookmark: null,
    ListBookmark: null,
  },
});
