import { Spinner, Modal } from "@fluentui/react";
import styled from "styled-components";
export const StyledMainModal = styled(Modal)`
  .ms-Dialog-main {
    box-shadow: none;
    border: none;
    background-color: transparent;
  }
  .ms-Modal-scrollableContent {
    height: 225px;
  }
`;

export const StyledMainSpinner = styled(Spinner)`
  .ms-Spinner-circle {
    width: 35px;
    height: 35px;
    border-width: 0px;
    // border-style: solid;
    // border-color: rgb(0, 120, 212) rgb(255 255 255) rgb(143 208 239);
  }
  .ms-Spinner-label {
    font-size: 15px;
    color: whitesmoke;
  }
`;

export const StyledSearchLoader = styled.div`
  padding: 30px;
  box-sizing: border-box;
  height: 100%;
  text-align: center;
  background: rgb(206 206 206 / 58%);
  position: absolute;
  width: 100%;
  top: 1px;
  z-index: 1000;

  button {
    margin-top: 15px;
  }
  .ms-Spinner {
    margin: auto;
  }
  .ms-Spinner-circle {
    border-width: 0px;
    // border-style: solid;
    // border-color: rgb(0, 120, 212) rgb(255 255 255) rgb(143 208 239);
  }
`;

export const StyledSearchSpinner = styled(Spinner)`
  .ms-Spinner-circle {
    width: 35px;
    height: 35px;
    border-width: 0px;
    // border-style: solid;
    // border-color: rgb(0, 120, 212) rgb(255 255 255) rgb(143 208 239);
  }
  .ms-Spinner-label {
    font-size: 15px;
    color: whitesmoke;
  }
`;
